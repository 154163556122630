import React from 'react';

import styles from './TutorActions.module.scss';
import Title from '../../../components/Title';
import Text from '../../../components/Text';
import { FaStar } from 'react-icons/fa';
import Button from '../../../components/Button';
import { FiSend } from 'react-icons/fi';
import ReactPlayer from 'react-player';
import { useTranslation } from 'react-i18next';
import BuyPackageModal from '../BuyPackageModal';
import { formatNumber } from '../../../utils';
import TutorFavoriteTogglerFromDetails from '../TutorFavoriteTogglerFromDetails';

const TutorActions = ({
  price,
  rating,
  reviewCount,
  video,
  onWriteToTutor,
  canBookTrialLesson,
  tutorId,
  onWriteToTutorLoading,
}) => {
  const [t] = useTranslation();

  return (
    <div>
      <div className={styles.actions}>
        <TutorFavoriteTogglerFromDetails />
        <div className={styles.actionsVideo}>
          <ReactPlayer
            controls
            url={video}
            width="100%"
            height="200px"
          />
        </div>
        <Title level={4} noMargin>{formatNumber(price)} {t('сум')}</Title>
        <Text level={3} color="secondary">{t('за 50 минут')}</Text>
        <div className={styles.reviewsCount}>
          <span className={styles.reviewsCountStar}><FaStar/></span>
          <span className={styles.reviewsCountPoints}>{rating}</span>
          <span className={styles.reviewsCountDivider}/>
          <span className={styles.reviewsCountCounts}>{reviewCount}</span>
          <span className={styles.reviewsCountLabel}>{t('оценок')}</span>
        </div>
        <div className={styles.actionsButtons}>
          {
            !canBookTrialLesson && (
              <BuyPackageModal tutorPrice={price} tutorId={tutorId}/>
            )
          }
          <Button
            size="small"
            fullWidth
            color="light"
            icon={<FiSend/>}
            iconPosition="start"
            onClick={onWriteToTutor}
            className="Button_WriteToTheTeacher"
            loading={onWriteToTutorLoading}
          >
            {t('Написать учителю')}
          </Button>
        </div>
      </div>

    </div>
  );
};

export default TutorActions;
