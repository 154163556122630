import React from 'react';
// import cn from 'classnames';

import CheckboxInput from './CheckboxInput';
import { Controller } from 'react-hook-form';

const CheckboxInputController = ({
  name,
  control,
  label,
  size,
}) => {

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={false}
      render={({ field }) => (
        <CheckboxInput
          {...field}
          label={label}
          size={size}
          ref={null}
        />
      )}
    />
  );
};

export default CheckboxInputController;
