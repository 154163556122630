import React, { useCallback } from 'react';

import styles from './FillProfile.module.scss';
import { useForm } from 'react-hook-form';
import Title from '../../../components/Title';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Text from '../../../components/Text';
import TextInputController from '../../../components/TextInput/TextInputController';
import DateInputController from '../../../components/DateInput/DateInputController';
import TextareaInputController from '../../../components/TextareaInput/TextareaInputController';
import PasswordInputController from '../../../components/PasswordInput/PassswordInputController';
import Button from '../../../components/Button';
import AvatarUploaderController from '../../../containers/profile/AvatarUploader/AvatarUploaderController';
import { usePopulateProfileMutation } from '../../../store/apis/profile';
import { createDataSchema } from '../../../yup';
import appRoutes from '../../../app/appRoutes';
import { date, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { anyPass, head, isEmpty, isNil, reject } from 'ramda';
import useQueryString from '../../../hooks/useQueryString';
import queryString from 'query-string';

const schema = createDataSchema({
  firstName: string().required(),
  lastName: string().required(),
  email: string().email(),
});

const FillProfile = () => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const query = useQueryString();

  const [populateProfile, { isLoading }] = usePopulateProfileMutation();

  const {
    control,
    handleSubmit: submit,
    setError,
  } = useForm({
    defaultValues: {},
    resolver: yupResolver(schema),
  });

  const handleSubmit = useCallback(async (values) => {
    const preparedBody = {
      ...reject(anyPass([isEmpty, isNil]))(values),
      photoPath: values.photo?.path || null,
    };
    try {
      await populateProfile(preparedBody).unwrap();
      window.fbq('trackCustom', 'Register');
      navigate({
        pathname: appRoutes.proxy(),
        search: queryString.stringify(query),
      });
    } catch (e) {
      if (e.data && e.data.type === 'form') {
        const { details } = e.data;
        details.forEach((err) => {
          setError(err.name, { type: 'custom', message: head(err.errors) });
        });
        return;
      }
      console.log(JSON.stringify(e.data ?? e, 0, 2));
    }
  }, [navigate, populateProfile])

  return (
    <div className={styles.profile}>
      <form onSubmit={submit(handleSubmit)}>
        <div className={styles.profileHeader}>
          <Title level={3}>{t('Дополнительная информация')}</Title>
          <Text level={2} color="secondary">{t('Мы хотим узнать вас чуть ближе')}</Text>
        </div>
        <div className={styles.profileBody}>
          <TextInputController
            name="lastName"
            control={control}
            label={`${t('Фамилия')}*`}
            id="lastName"
            placeholder={t('Введите фамилию')}
          />
          <TextInputController
            name="firstName"
            control={control}
            label={`${t('Имя')}*`}
            id="firstName"
            placeholder={t('Введите имя')}
          />
          <DateInputController
            control={control}
            name="birthdate"
            label={`${t('Дата рождения')}*`}
            id="birthDate"
          />
          <TextInputController
            name="email"
            control={control}
            label={t('Эл почта')}
            id="email"
            placeholder="example@mail.com"
          />
          <TextareaInputController
            name="introductionText"
            control={control}
            label={t('О себе')}
            id="introductionText"
            placeholder={t('Кратко расскажите о себе')}
          />
          <AvatarUploaderController
            control={control}
            name="photo"
            label={t('Фото профиля')}
            id="file"
            title={t('Выберите файл или перетащите его в эту зону')}
            descriptions={[
              t('Формат изображений JPEG, PNG'),
              t('Размер изображений до 2 MB'),
            ]}
          />
          <Button
            type="submit"
            fullWidth
            className="Button_button__JBBzO_FillingTheData"
            loading={isLoading}
          >
            {t('Продолжить')}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default FillProfile;
