import React from 'react';
import cn from 'classnames';

import styles from './BioItem.module.scss';

const BioItem = ({
  icon,
  title,
  additionalTexts,
}) => {

  return (
    <div className={styles.bio}>
      <div className={styles.bioHeader}>
        <div className={styles.bioIcon}>
          {icon}
        </div>
        <div className={styles.bioInstitution}>
          {title}
        </div>
      </div>
      <div className={styles.bioFooter}>
        {
          additionalTexts?.map((text, i) => (
            <span className={styles.bioLabel} key={i}>{text}</span>
          ))
        }
      </div>
    </div>
  );
};

export default BioItem;
