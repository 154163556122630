import React from 'react';
import cn from 'classnames';

import styles from './Popconfirm.module.scss';
import Modal from '../Modal';
import Text from '../Text';
import Button from '../Button';

const Popconfirm = ({
  trigger,
  closeOnDocumentClick = true,
  title,
  text,
  onApprove,
  rejectText,
  approveText,
  loading,
}) => {

  return (
    <Modal
      trigger={trigger}
      modal
      nested
      closeOnDocumentClick={closeOnDocumentClick}
      variant="primary"
      title={title}
    >
      {(close) => (
        <div className={styles.popconfirm}>
          <Text color="secondary">{text}</Text>
          <div className={styles.popconfirmFooter}>
            <Button
              type="button"
              size="small"
              onClick={close}
              color="secondary"
            >
              {rejectText}
            </Button>
            <Button
              type="button"
              size="small"
              onClick={onApprove}
              color="primary"
              loading={loading}
            >
              {approveText}
            </Button>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default Popconfirm;
