import { joinExisting } from '../utils';

export default {
  root: () => '/',
  proxy: () => '/check',
  entry: () => '/entry',
  login: () => '/entry/login',
  register: () => '/entry/register',
  registerPhoneConfirmation: () => '/entry/phone-confirmation',
  setPassword: () => '/entry/set-password',
  registerFillProfile: () => '/entry/fill-profile',
  recoverPassword: () => '/entry/recover-password',
  setRecoverPassword: () => '/entry/set-recover-password',

  app: () => '/app',
  profile: () => '/app/profile',
  help: () => '/app/help',
  schedule: () => '/app/schedule',
  lessons: () => '/app/lessons',
  chats: () => '/app/chats',
  balance: () => '/app/balance',
  tutors: (id) => joinExisting(['/app/tutors', id]),
  subjects: () => '/app/subjects',
  favorites: () => '/app/favorites',
  transferPackage: () => '/app/transfer-package',
  room: () => '/room',
};
