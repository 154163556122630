import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

const initialState = {
  mediaState: {
    loading: false,
    isVideoEnabled: false,
    isAudioEnabled: false,
    isScreenShareEnabled: false,
  },
  uiState: {
    isFullScreen: false,
    isChatVisible: false,
  },
  connectionState: {
    step: null,
  },
  unexpectedBehaviour: {
    show: false,
    cause: null,
    canReload: true,
    canClose: true,
  },
};

export const room = createSlice({
  name: 'room',
  initialState,
  reducers: {
    setMediaLoading: (state, { payload }) => {
      state.mediaState.loading = payload;
    },
    setVideoEnabled: (state, { payload }) => {
      state.mediaState.isVideoEnabled = payload;
    },
    setAudioEnabled: (state, { payload }) => {
      state.mediaState.isAudioEnabled = payload;
    },
    setScreenShareEnabled: (state, { payload }) => {
      state.mediaState.isScreenShareEnabled = payload;
    },
    setFullScreen: (state, { payload }) => {
      state.uiState.isFullScreen = payload;
    },
    setChatVisible: (state, { payload }) => {
      state.uiState.isChatVisible = payload;
    },

    setConnectionPreparing: (state, { payload }) => {
      state.connectionState.step = payload;
    },
    setUnexpectedBehaviour: (state, { payload }) => {
      state.unexpectedBehaviour = payload;
    }
  },
});

export const useMediaState = () => {
  return useSelector((state) => state.room.mediaState);
};

export const useUIRoomState = () => {
  return useSelector((state) => state.room.uiState);
};

export const useRoomConnectionState = () => {
  return useSelector((state) => state.room.connectionState);
};

export const useUnexpectedBehaviourState = () => {
  return useSelector((state) => state.room.unexpectedBehaviour);
}

export const {
  setMediaLoading,
  setVideoEnabled,
  setAudioEnabled,
  setFullScreen,
  setChatVisible,
  setScreenShareEnabled,
  setConnectionPreparing,
  setUnexpectedBehaviour,
} = room.actions;
