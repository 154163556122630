import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { rtkQueryErrorLogger } from './middlewares/handleError';
import { authApi } from './apis/auth';
import { user } from './slicers/user';
import { booking } from './slicers/booking';
import { chat } from './slicers/chat';
import { room } from './slicers/room';
import { notifications } from './slicers/notificationsSlice';
import { mainApi } from './apis';

const rootReducer = combineReducers({
  [authApi.reducerPath]: authApi.reducer,
  [mainApi.reducerPath]: mainApi.reducer,
  user: user.reducer,
  booking: booking.reducer,
  chat: chat.reducer,
  notifications: notifications.reducer,
  room: room.reducer,
});

export const store = configureStore({
  reducer: rootReducer,

  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
    immutableCheck: false,
  })
    .concat(rtkQueryErrorLogger)
    .concat(authApi.middleware)
    .concat(mainApi.middleware),

});
