import React, { useCallback } from 'react';
import cn from 'classnames';

import styles from './AvatarProfileChanger.module.scss';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { any, head, identity } from 'ramda';
import { getFileDropError, sleep } from '../../../utils';
import { FiEdit2 } from 'react-icons/fi';
import { usePatchProfileMutation } from '../../../store/apis/profile';
import { useUploadMutation } from '../../../store/apis/files';
import { toast } from 'react-toastify';
import { PulseLoader } from 'react-spinners';

const AvatarProfileChanger = ({
  src = '',
  placeholder,
}) => {
  const [t] = useTranslation();
  const [patchProfile, { isLoading: isPatchLoading, isFetching: isPatchFetching }] = usePatchProfileMutation();
  const [upload, { isLoading: isUploadLoading, isFetching: isUploadFetching }] = useUploadMutation();

  const handleSelectFileError = useCallback((files) => {
    const firstFile = head(files);
    if (!firstFile || !firstFile.errors) return;
    firstFile.errors.forEach(({ code }) => {
      toast.error(getFileDropError(code));
    });
  }, []);

  const handleOnDropAccepted = useCallback(async (files) => {
    const formData = new FormData();
    const file = head(files);
    formData.append('file', file);
    formData.append('group', 'student_photos');
    try {
      const data = await upload(formData).unwrap();
      await patchProfile({ photoPath: data.path }).unwrap();
      toast.success(t('Фотография профиля изменена'));
    } catch (e) {
      console.log(JSON.stringify(e.data ?? e, 0, 2));
    }
  }, [patchProfile, t, upload]);

  const removePhoto = useCallback(async () => {
    try {
      await patchProfile({ photoPath: null }).unwrap();
      toast.success(t('Фотография удалена'));
    } catch (e) {
      console.log(JSON.stringify(e.data ?? e, 0, 2));
    }
  }, [patchProfile, t]);

  const {
    getRootProps,
    getInputProps,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    maxSize: 2097152,
    maxFiles: 1,
    multiple: false,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpg', '.jpeg'],
    },
    onDropAccepted: handleOnDropAccepted,
    onDropRejected: handleSelectFileError,
  });
  return (
    <div className={styles.avatar}>
      <div className={styles.avatarWrapper}>
        {
          src
            ? (
              <img src={src} alt="" className={styles.avatarImage}/>
            ) : (
              <div className={styles.avatarPlaceholder}>
                <div className={styles.avatarPlaceholderTitle}>
                  {
                    (placeholder && placeholder.length > 0)
                      ? placeholder
                      : '🫠'
                  }
                </div>
              </div>
            )
        }
        {
          any(identity)([isPatchLoading, isPatchFetching, isUploadLoading, isUploadFetching])
            ? (
              <div className={styles.avatarLoading}>
                <PulseLoader size={16} color="#536DFE" className={styles.loader} />
              </div>
            ) : null
        }
        <div className={styles.avatarButton} {...getRootProps()}>
          <FiEdit2 />
          <input {...getInputProps()} />
        </div>
      </div>
      {
        src && (
          <button type="button" className={styles.avatarRemover} onClick={removePhoto}>
            {t('Удалить фото')}
          </button>
        )
      }
    </div>
  );
};

export default AvatarProfileChanger;
