import { invalidateOn } from '../utils';
import apiRoutes from '../../apiRoutes';
import { mainApi } from './index';

mainApi.injectEndpoints({
  endpoints: (build) => ({
    getNotificationList: build.query({
      query: (params) => ({
        url: apiRoutes.notificationList(),
        params,
      }),
    }),
    readAllNotifications: build.mutation({
      query: () => ({
        url: apiRoutes.readAllNotifications(),
        method: 'PUT',
      }),
    }),
  }),
})

export const {
  useLazyGetNotificationListQuery,
  useReadAllNotificationsMutation,
} = mainApi;
