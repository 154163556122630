import styles from './PopupMenu.module.scss';
import React from 'react';
import cn from 'classnames';

const PopupMenuItem = ({
  label,
  type = 'primary',
  onClick,
}) => {
  return (
    <button
      type="button"
      className={cn(styles.popupMenuItem, styles[type])}
      onClick={onClick}
    >
      {label}
    </button>
  );
};

export default PopupMenuItem;
