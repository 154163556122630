import { invalidateOn } from '../utils';
import apiRoutes from '../../apiRoutes';
import { mainApi } from './index';

mainApi.injectEndpoints({
  endpoints: (build) => ({
    populateProfile: build.mutation({
      query: (body) => ({
        url: apiRoutes.myProfile(),
        method: 'PUT',
        body,
      }),
    }),
    getUserProfile: build.query({
      query: (params) => ({
        url: apiRoutes.myProfile(),
        params,
      }),
      providesTags: ['USER_PROFILE'],
    }),
    patchProfile: build.mutation({
      query: (body) => ({
        url: apiRoutes.myProfile(),
        method: 'PATCH',
        body,
      }),
      invalidatesTags: invalidateOn({ success: ['USER_PROFILE'] })
    }),
    requestNewPhoneVerification: build.mutation({
      query: (body) => ({
        url: apiRoutes.newPhoneVerification(),
        method: 'POST',
        body,
      }),
    }),
    verifyNewPhone: build.mutation({
      query: (body) => ({
        url: apiRoutes.verifyNewPhone(),
        method: 'POST',
        body,
      }),
    }),
    changePassword: build.mutation({
      query: (body) => ({
        url: apiRoutes.changePassword(),
        method: 'POST',
        body,
      }),
    }),

    generateTelegramBotLink: build.mutation({
      query: () => ({
        url: apiRoutes.generateTelegramBotLink(),
        method: 'POST',
      }),
    }),
    getLinkedTelegramBotAccounts: build.query({
      query: (params) => ({
        url: apiRoutes.linkedTelegramBotAccounts(),
        params,
      }),
      providesTags: ['LINKED_TELEGRAM_ACCOUNTS'],
    }),
    unsubscribeTelegramBotAccount: build.mutation({
      query: (id) => ({
        url: apiRoutes.unsubscribeTelegramBotAccount(id),
        method: 'DELETE',
      }),
      invalidatesTags: invalidateOn({ success: ['LINKED_TELEGRAM_ACCOUNTS'] }),
    }),
  })
})

export const {
  usePopulateProfileMutation,
  useGetUserProfileQuery,
  useLazyGetUserProfileQuery,
  usePatchProfileMutation,
  useRequestNewPhoneVerificationMutation,
  useVerifyNewPhoneMutation,
  useChangePasswordMutation,
  useGenerateTelegramBotLinkMutation,
  useGetLinkedTelegramBotAccountsQuery,
  useUnsubscribeTelegramBotAccountMutation,
} = mainApi;
