import React from 'react';
import cn from 'classnames';

import styles from './Balance.module.scss';
import { useGetPackageListQuery } from '../../../store/apis/tariffs';
import { useTranslation } from 'react-i18next';
import { formatNumber } from '../../../utils';
import { FiArrowRight } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import appRoutes from '../../../app/appRoutes';

const Balance = () => {
  const [t] = useTranslation()
  const navigate = useNavigate();

  const {
    data: packages,
    isLoading,
    isFetching
  } = useGetPackageListQuery();

  const { lessonCount, balance } = packages && packages.results.length > 0
    ? packages.results.reduce((acc, pack) => {
      return {
        ...acc,
        lessonCount: acc.lessonCount + pack.lessonCount,
        balance: acc.balance + pack.balance,
      }
    }, { lessonCount: 0, balance: 0 })
    : { lessonCount: '-', balance: '-' }

  const goToBalance = () => {
    navigate(appRoutes.balance());
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <h3 className={styles.title}>{t('Ваш баланс')}</h3>
        <div className={styles.balance}>
          <div className={styles.balanceAmount}>
            {t('{{balance}} сум', { balance: formatNumber(balance) })}
          </div>
          <div className={styles.balanceCount}>
            {t('{{lessonCount}} занятий', { count: lessonCount })}
          </div>
        </div>
        <div className={styles.action}>
          <button
            onClick={goToBalance}
            type="button"
            className={styles.actionButton}
          >
            {t('Пополнить баланс')}
            <span>
              <FiArrowRight />
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Balance;
