import React, { useCallback, useMemo, useState } from 'react';

import styles from './TopupTutorPack.module.scss';
import Modal from '../../../components/Modal';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Button';
import Condition from '../../../components/Condition';
import TariffChoiceList from '../../tutor/TariffChoiceList';
import { clearBooking } from '../../../store/slicers/booking';
import { useDispatch } from 'react-redux';
import { useGetTutorDetailsQuery } from '../../../store/apis/tutors/tutorListApi';
import PaymentMethodCard from '../../tutor/PaymentMethodCard';
import { useBuyPackageMutation } from '../../../store/apis/tariffs';
import { FiChevronLeft } from 'react-icons/fi';
import Title from '../../../components/Title';

const TopupTutorPack = ({ tutorId }) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const {
    data: tutor,
    isLoading: isTutorLoading,
    isFetching: isTutorFetching,
    error: tutorError,
  } = useGetTutorDetailsQuery(tutorId);

  const [
    buyPackage,
    {
      isLoading: isBuyPackageLoading,
      isFetching: isBuyPackageFetching,
    }
  ] = useBuyPackageMutation();

  /**
   * steps - 'tariffs' | 'payment'
   */
  const [step, setStep] = useState('tariffs');
  const [payment, setPayment] = useState(null);
  const [chosenTariff, setChosenTariff] = useState(null);

  const backToSchedule = useCallback(() => {
    dispatch(clearBooking());
    setStep('schedule');
  }, [dispatch]);

  const chooseTariff = useCallback(async (tariff) => {
    setChosenTariff(tariff);

    const body = {
      tutorId,
      tariff: tariff.id,
    };

    try {
      const data = await buyPackage(body).unwrap();
      setPayment(data);
      setStep('payment');
    } catch (e) {
      console.log(JSON.stringify(e.data ?? e, 0, 2));
    }
  }, [buyPackage, tutorId]);

  const stepTitles = useMemo(() => ({
    tariffs: {
      title: t('Тарифный план'),
      subtitle: t('Выберите план, который соответствует Вашим потребностям'),
    },
    payment: {
      title: t('Способ оплаты'),
      subtitle: null,
    },
  }), [t]);

  return (
    <Modal
      title={stepTitles[step].title}
      subtitle={stepTitles[step].subtitle}
      variant="secondary"
      closeOnDocumentClick={false}
      onClose={() => {
        setStep('tariffs');
      }}
      trigger={
        <Button
          type="button"
          size="small"
          fullWidth
          loading={isTutorLoading || isTutorFetching}
        >
          {t('Пополнить баланс')}
        </Button>
      }
    >
      {(close) => (
        <div className={styles.container}>
          <Condition when={step} is="tariffs">
            <TariffChoiceList
              tutorPrice={tutor?.price}
              onChoose={chooseTariff}
            />
          </Condition>
          <Condition when={step} is="payment">
            {
              isBuyPackageLoading || isBuyPackageFetching
                ? (
                  <Title level={4}>
                    {t('Получаю данные оплаты')}. {t('Пожалуйста, подождите')}
                  </Title>
                ) : (
                  <div className={styles.paymentWrapper}>
                    <PaymentMethodCard
                      tariff={chosenTariff}
                      paymentOrder={payment?.orderId}
                      amount={payment?.amount}
                      methods={payment?.paymentWays}
                      onSuccess={() => {
                        setStep('tariffs');
                        setPayment(null);
                        setChosenTariff(null);
                        close();
                      }}
                    />
                  </div>
                )
            }
          </Condition>
        </div>
      )}
    </Modal>
  );
};

export default TopupTutorPack;
