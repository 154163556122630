// import cn from 'classnames';

import styles from './RoomLoading.module.scss';
import { PulseLoader } from 'react-spinners';
import React from 'react';
import { useRoomConnectionState } from '../../../store/slicers/room';
import { useTranslation } from 'react-i18next';

const RoomLoading = () => {
  const [t] = useTranslation();
  const roomConnectionState = useRoomConnectionState();

  const states = {
    connecting: {
      loading: true,
      message: t('Подключаюсь к комнате'),
    },
    tutorConnecting: {
      loading: true,
      message: t('Преподаватель подключается'),
    },
    waitTutor: {
      loading: false,
      message: t('Подождите пока подключится преподаватель'),
    },
    disconnected: {
      loading: false,
      message: t('Потеряна связь с преподавателем, подождите пока он подключится снова'),
    }
  };

  if (roomConnectionState.step) return (
    <div className={styles.container}>
      {
        states[roomConnectionState.step].loading && (
          <div>
            <PulseLoader color="#536DFE"/>
          </div>
        )
      }
      <div>
        {states[roomConnectionState.step].message}
      </div>
    </div>
  );

  return null;
};

export default RoomLoading;
