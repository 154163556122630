import React, { useCallback } from 'react';

import styles from './Tutors.module.scss';
import Title from '../../../components/Title';
import { useTranslation } from 'react-i18next';
import TutorSearchForm from '../../../containers/tutorList/TutorSearchForm';
import TeacherList from '../../../containers/tutorList/TeacherList';
import AppQRLinks from '../../../containers/shared/AppQRLinks';
import Container from '../../../components/Container';
import { useGetTutorListQuery } from '../../../store/apis/tutors/tutorListApi';
import useQueryString from '../../../hooks/useQueryString';
import ContentLoader from 'react-content-loader';
import { useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import Pagination from '../../../components/Pagination';

const Tutors = () => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { page = 1, pageSize = 20, ...restQuery } = useQueryString();

  const {
    data: teacherData,
    isLoading: isTeacherLoading,
    isFetching: isTeacherFetching,
  } = useGetTutorListQuery({
    page,
    pageSize,
    ...restQuery,
  });

  const handlePageChange = useCallback((page) => {
    navigate({
      pathname: '',
      search: queryString.stringify({
        page,
        pageSize,
        ...restQuery,
      })
    });
  }, [navigate, pageSize, restQuery]);

  return (
    <Container>
      <Title level={3}>{t('Индивидуальные уроки')}</Title>
      <TutorSearchForm />
      <h5 className={styles.bodyTitle}>
        {
          isTeacherLoading
            ? (
              <ContentLoader
                backgroundColor={'#c8c8c8'}
                foregroundColor={'#eceaea'}
                width="300"
                height="20"
                speed={2}
              >
                <rect x="0" y="0" rx="10" ry="10" width="100%" height="20" />
              </ContentLoader>
            ) : (
              <>
                <span className="bold">{teacherData?.count}</span>
                {' '}
                {teacherData?.count === 1 ? t('учитель с подходящими вам навыками') : t('учителей с подходящими вам навыками')}
              </>
            )
        }
      </h5>
      <div className={styles.bodyWrapper}>
        <section className={styles.bodyContent}>
          <TeacherList
            loading={isTeacherLoading || isTeacherFetching}
            teachers={teacherData?.results}
          />
          <div className={styles.bodyPagination}>
            <Pagination
              count={teacherData?.totalPages}
              currentPage={page}
              onPageChange={handlePageChange}
            />
          </div>
        </section>
        <aside className={styles.bodyAside}>
          <AppQRLinks />
        </aside>
      </div>
    </Container>
  );
};

export default Tutors;
