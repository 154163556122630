import React, { useCallback, useEffect } from 'react';
import cn from 'classnames';

import styles from './QRCodeGenerator.module.scss';
import QRCode from 'react-qr-code';
import { useTranslation } from 'react-i18next';
import { useGenerateTelegramBotLinkMutation } from '../../../store/apis/profile';
import { differenceInSeconds, parseISO } from 'date-fns';
import Countdown from 'react-countdown';
import { useDispatch } from 'react-redux';
import { mainApi } from '../../../store/apis';
import LoaderFullContent from '../../../components/LoaderFullContent';

const QRCodeGenerator = () => {
  const [t] = useTranslation();

  const [
    generateLink,
    {
      data: link,
      isLoading,
      isFetching,
      reset,
    },
  ] = useGenerateTelegramBotLinkMutation();

  const qrValueClasses = cn({
    [styles.qrValue]: true,
    [styles.qrValueHidden]: !link,
  });
  const qrTitleClasses = cn({
    [styles.qrTitle]: true,
    [styles.qrTitleHidden]: link,
  });
  const feedbackClasses = cn({
    [styles.feedback]: true,
    [styles.feedbackVisible]: link,
  });

  return (
    <div className={styles.container}>
      {
        link && (
          <Countdown
            date={parseISO(link.expireAt)}
            renderer={({ formatted }) => (
              <div className={styles.timer}>
                {formatted.minutes}:{formatted.seconds}
              </div>
            )}
            onComplete={reset}
          />
        )
      }
      <button
        type="button"
        className={styles.qr}
        disabled={Boolean(link)}
        onClick={generateLink}
      >
        <span className={qrTitleClasses}>
          {
            (isLoading || isFetching)
              ? <LoaderFullContent />
              : t('Нажмите, чтобы получить персональную ссылку на телеграм бот')
          }
        </span>
        <QRCode
          value={link ? link.url : 'No Value'}
          className={qrValueClasses}
        />
      </button>
      <div className={feedbackClasses}>
        <p className={styles.feedbackText}>
          {t('Отсканируйте код, чтобы попасть в телеграм бот')}
        </p>
        <a
          className={styles.feedbackLink}
          href={link?.url}
          target="_blank"
          rel="noopenner noreferrer"
        >
          {t('или нажмите на ссылку')}
        </a>
      </div>
    </div>
  );
};

export default QRCodeGenerator;
