import React, { useMemo } from 'react';
import cn from 'classnames';

import styles from './CancelOrReschedule.module.scss';
import { FiArrowRight, FiRotateCcw, FiXCircle } from 'react-icons/fi';

/**
 *
 * @param fromLessonTime
 * @param toLessonTime
 * @param text
 * @param {'warning'|'danger'} color
 * @returns {JSX.Element}
 * @constructor
 */
const CancelOrReschedule = ({
  fromLessonTime,
  toLessonTime,
  text,
  color,
}) => {

  const cardClasses = useMemo(() => cn({
    [styles.card]: true,
    [styles.cardWarning]: color === 'warning',
    [styles.cardDanger]: color === 'danger',
  }), [color]);

  const icons = useMemo(() => ({
    warning: <FiRotateCcw />,
    danger: <FiXCircle />,
  }), []);

  return (
    <div className={cardClasses}>
      <div className={styles.cardHeader}>
        <div className={styles.cardHeaderIcon}>
          {icons[color]}
        </div>
        <div className={styles.cardHeaderText}>
          {text}
        </div>
      </div>

      <div className={styles.cardBody}>
        <div className={styles.cardCancelTime}>
          {fromLessonTime}
        </div>
        {
          toLessonTime && (
            <>
              <div className={styles.cardTimeDivider}>
                <FiArrowRight />
              </div>
              <div className={styles.cardNewTime}>
                {toLessonTime}
              </div>
            </>
          )
        }
      </div>
    </div>
  );
};

export default CancelOrReschedule;
