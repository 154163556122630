import React, { useCallback } from 'react';
import cn from 'classnames';

import styles from './PhoneChangeForm.module.scss';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import PhoneInputController from '../../../components/PhoneInput';
import Button from '../../../components/Button';
import { useRequestNewPhoneVerificationMutation } from '../../../store/apis/profile';
import { createDataSchema } from '../../../yup';
import { string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const schema = createDataSchema({
  phoneNumber: string().required().min(12),
});

const PhoneChangeForm = ({
  onCancel,
  onSubmitSuccess,
}) => {
  const [t] = useTranslation();
  const [requestNewPhoneVerification, { isLoading, isFetching }] = useRequestNewPhoneVerificationMutation();

  const {
    control,
    handleSubmit: submit,
  } = useForm({
    defaultValues: {},
    resolver: yupResolver(schema),
  });

  const handleSubmit = useCallback(async (values) => {
    try {
      await requestNewPhoneVerification(values).unwrap();
      onSubmitSuccess(values.phoneNumber);
    } catch (e) {
      console.log(JSON.stringify(e.data ?? e, 0, 2));
    }
  }, [onSubmitSuccess, requestNewPhoneVerification]);

  return (
    <form className={styles.phone} onSubmit={submit(handleSubmit)}>
      <PhoneInputController
        control={control}
        name="phoneNumber"
        label={t('Новый номер телефона')}
        placeholder="+998 99 9999999"
        inputFormat="+### ## #######"
        mask=" "
      />

      <div className={styles.phoneFooter}>
        <Button
          type="reset"
          onClick={onCancel}
          color="secondary"
        >
          {t('Отменить')}
        </Button>
        <Button
          type="submit"
          loading={isLoading || isFetching}
        >
          {t('Продолжить')}
        </Button>
      </div>
    </form>
  );
};

export default PhoneChangeForm;
