import React, { useCallback, useEffect, useRef } from 'react';

import styles from './ChatFilesContainer.module.scss';
import { useTranslation } from 'react-i18next';
import { useGetChatDetailsQuery, useLazyGetChatFilesQuery, useReadAllFilesMutation } from '../../../store/apis/chats';
import ChatMessage from '../../../components/ChatMessage';
import { bytesToMegabytes, downloadFile, formatTime, reverseIfExists } from '../../../utils';
import {
  resetUnreadFiles,
  setInitFiles,
  useChat,
  useChatFiles,
  useChatUnreadFilesAndMessages
} from '../../../store/slicers/chat';
import LoaderFullContent from '../../../components/LoaderFullContent';
import FileInfo from '../../../components/FileInfo';
import { FiFileText } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import ChatFilesForm from '../ChatFilesForm';
import { includes } from 'ramda';

const ChatFilesContainer = () => {
  const [t, i18n] = useTranslation();
  const chat = useChat();
  const files = useChatFiles(chat.activeChatId);
  const [, unreadFileCount] = useChatUnreadFilesAndMessages(chat.activeChatId);

  const scrollRef = useRef(null);
  const dispatch = useDispatch();

  const scrollToBottom = useCallback(() => {
    if (scrollRef.current) scrollRef.current.scrollIntoView();
  }, []);

  const [readAllFiles] = useReadAllFilesMutation();
  const {
    data: chatDetails,
  } = useGetChatDetailsQuery({ id: chat.activeChatId }, { skip: !chat.activeChatId });

  const [getChatFiles, { isLoading: isFilesLoading, isFetching: isFilesFetching }] = useLazyGetChatFilesQuery();

  useEffect(() => {
    if (!chat.activeChatId || includes(chat.activeChatId, chat.initializedFileChats)) {
      return;
    }

    getChatFiles({ chatId: chat.activeChatId, pageSize: 50 }, true)
      .unwrap()
      .then((data) => {
        const files = reverseIfExists(data.results);
        dispatch(setInitFiles({ chatId: chat.activeChatId, files }));
        scrollToBottom();
      })
      .catch((e) => {
        console.log(JSON.stringify(e.data ?? e, 0, 2));
      })
  }, [chat.initializedFileChats, chat.activeChatId, scrollToBottom, dispatch, getChatFiles]);

  useEffect(() => {
    scrollToBottom();
  }, [files?.length, scrollToBottom]);

  useEffect(() => {
    if (unreadFileCount > 0) {
      dispatch(resetUnreadFiles(chat.activeChatId));
      readAllFiles(chat.activeChatId);
    }
  }, [unreadFileCount, chat.activeChatId, dispatch, readAllFiles]);

  return (
    <>
      <ChatFilesForm>
        {
          (isFilesFetching || isFilesLoading)
          && <LoaderFullContent />
        }
        <div className={styles.inbox}>
          {
            files?.map(({ id, sendTime, isMyMessage, file }) => (
              <ChatMessage
                key={id}
                type={isMyMessage ? 'self' : 'remote'}
                messageType="file"
                message={
                  <FileInfo
                    name={file.name}
                    color={isMyMessage ? 'secondary' : 'primary'}
                    size={t('{{size}} мб', { size: bytesToMegabytes(file.size) })}
                    icon={<FiFileText />}
                    actionText={t('Скачать')}
                    url={file.url}
                  />
                }
                date={sendTime}
                avatarUrl={chatDetails?.user.photoUrl}
                name={chatDetails?.user.fullName?.[i18n.language]}
              />
            ))
          }
          <div style={{ float: 'left', clear: 'both' }} ref={scrollRef} />
        </div>
      </ChatFilesForm>
    </>
  );
};

export default ChatFilesContainer;
