import React, { useMemo } from 'react';
import cn from 'classnames';

import styles from './Text.module.scss';

const Text = ({
  children,
  level = 1,
  color = 'primary',
  bold = false,
  italic = false,
  noPadding = false,
}) => {
  const textStyles = useMemo(() => cn({
    [styles.text]: true,
    //sizes
    [styles.levelOne]: level === 1,
    [styles.levelTwo]: level === 2,
    [styles.levelThree]: level === 3,
    //colors
    [styles.primary]: color === 'primary',
    [styles.secondary]: color === 'secondary',
    [styles.warning]: color === 'warning',
    [styles.danger]: color === 'danger',
    [styles.success]: color === 'success',
    [styles.light]: color === 'light',
    //utils
    p0: noPadding,
    bold,
    italic,
  }), [bold, color, italic, level, noPadding]);

  return (
    <p className={textStyles}>{children}</p>
  );
};

export default Text;
