import React from 'react';
import cn from 'classnames';

import styles from './PackageCard.module.scss';
import Text from '../../../components/Text';
import { formatDate, formatNumber } from '../../../utils';
import { useTranslation } from 'react-i18next';
import PopupMenu, { PopupMenuItem } from '../../../components/PopupMenu';
import { FiMoreVertical } from 'react-icons/fi';
import PackageCancellationRequestModal from '../PackageCancellationRequestModal';
import useToggle from '../../../hooks/useToggle';
import PackageTransferModal from '../PackageTransferModal';
import { keys } from 'ramda';
import { useNavigate } from 'react-router-dom';
import appRoutes from '../../../app/appRoutes';

const PackageCard = ({
  packId,
  paidTime,
  allTutors,
  tutor,
  pack,
  tariffName,
  lessonCount,
  price,
  consumedLessonCount,
  pricePerLesson,
  balance,
  canDoPackageActions,
  withoutCreditRow,
}) => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const [isOpenPackageCancellationModal, openCancellationModal, closeCancellationModal] = useToggle();

  const goToTransferPage = () => {
    navigate(appRoutes.transferPackage(), { state: { pack, tutor } });
  };

  return (
    <div className={styles.pack}>
      {
        canDoPackageActions && (
          <>
            <PackageCancellationRequestModal
              packId={packId}
              open={isOpenPackageCancellationModal}
              onClose={closeCancellationModal}
            />
            <div className={styles.packHeader}>
              <Text level={1} bold>
                {t('Пакет {{name}}', { name: tariffName })}
              </Text>
              <PopupMenu
                trigger={
                  <button type="button" className={styles.packMore}>
                    <FiMoreVertical />
                  </button>
                }
              >
                <PopupMenuItem
                  label={t('Перенести пакет')}
                  onClick={goToTransferPage}
                  type="primary"
                />
                <PopupMenuItem
                  label={t('Запросить возврат')}
                  onClick={openCancellationModal}
                  type="danger"
                />
              </PopupMenu>
            </div>
          </>
        )
      }
      <div className={styles.packItem}>
        <Text level={3} color="secondary" noPadding>
          {t('Дата оплаты')}: {formatDate(paidTime, true)}
        </Text>
      </div>
      <div className={styles.packItem}>
        <Text level={3} color="secondary" noPadding>
          {t('{{n}} занятий куплено', { count: lessonCount })}
        </Text>
        <Text level={2} noPadding>
          {t('{{amount}} сум', { amount: formatNumber(price) })}
        </Text>
      </div>
      <div className={styles.packItem}>
        <Text level={3} color="secondary" noPadding>
          {t('{{n}} занятий пройдено', { count: consumedLessonCount })}
        </Text>
        <Text level={2} noPadding>
          -{t('{{amount}} сум', { amount: formatNumber(pricePerLesson * consumedLessonCount) })}
        </Text>
      </div>

      {
        withoutCreditRow ? null : (
          <div className={styles.packTotal}>
            <Text level={2} bold>
              {t('Остаток средств')}
            </Text>
            <Text level={2} bold>
              {t('{{amount}} сум', { amount: formatNumber(balance) })}
            </Text>
          </div>
        )
      }
    </div>
  );
};

export default PackageCard;
