import i18n, { dateLocales } from './localizations/i18n';
import {
  FaDiscord,
  FaFacebookF,
  FaInstagram,
  FaLinkedin, FaOdnoklassniki,
  FaTelegramPlane,
  FaTiktok,
  FaTwitter, FaVk, FaWhatsapp,
  FaYoutube
} from 'react-icons/fa';

export const socialLinksIcons = {
  telegram: FaTelegramPlane,
  facebook: FaFacebookF,
  instagram: FaInstagram,
  youtube: FaYoutube,
  twitter: FaTwitter,
  tiktok: FaTiktok,
  linkedin: FaLinkedin,
  discord: FaDiscord,
  whatsapp: FaWhatsapp,
  vk: FaVk,
  odnoklassniki: FaOdnoklassniki,
};

export const errorMessages = {
  NO_DEVICES: i18n.t('Вам нужна веб камера и микрофон, чтобы подключиться к уроку'),
  NO_AUDIO: i18n.t('Вам нужен хотя бы микрофон для того, чтобы подключиться к уроку'),
  CANNOT_GET_USER_MEDIA: i18n.t('Не удалось получить доступ к веб камере или микрофону'),
};
