import React, { useMemo } from 'react';
import cn from 'classnames';

import styles from './Tag.module.scss';

const Tag = ({ label, color }) => {
  const tagClasses = useMemo(() => cn({
    [styles.tag]: true,
    [styles.tagPrimary]: color === 'primary',
    [styles.tagSecondary]: color === 'secondary',
    [styles.tagWarning]: color === 'warning',
  }), [color]);

  return (
    <div className={tagClasses}>{label}</div>
  );
};

export default Tag;
