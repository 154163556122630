import React, { useMemo } from 'react';
import cn from 'classnames';

import styles from './Title.module.scss';

const Title = ({
  children,
  level = 2,
  color = 'primary',
  noPadding = false,
  noMargin = false,
  bold = false,
  className = '',
}) => {
  const components = useMemo(() => ({
    1: 'h1',
    2: 'h2',
    3: 'h3',
    4: 'h4',
    5: 'h5',
    6: 'h6',
  }), []);

  const titleClasses = useMemo(() => cn({
    [styles.title]: true,
    //sizes
    [styles.level1]: level === 1,
    [styles.level2]: level === 2,
    [styles.level3]: level === 3,
    [styles.level4]: level === 4,
    [styles.level5]: level === 5,
    [styles.level6]: level === 6,
    //colors
    [styles.primary]: color === 'primary',
    [styles.secondary]: color === 'secondary',
    [styles.light]: color === 'light',

    m0: noMargin,
    p0: noPadding,
    bold,
    [className]: true,
  }), [bold, className, color, level, noMargin, noPadding]);

  return React.createElement(
    components[level],
    {
      className: titleClasses,
    },
    children,
  );
};

export default Title;
