import React from 'react';
// import cn from 'classnames';
import { Controller } from 'react-hook-form';
import AvatarUploader from './AvatarUploader';

const AvatarUploaderController = ({
  name,
  control,
  label,
  id,
  ...rest
}) => {

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <AvatarUploader
          {...field}
          {...rest}
          label={label}
          id={id}
        />
      )}
    />
  );
};

export default AvatarUploaderController;
