import React, { useCallback, useMemo } from 'react';
import cn from 'classnames';

import styles from './TabsButton.module.scss';

const TabsButton = ({
  tabs,
  currentTab,
  onTabClick,
}) => {
  const buttonClasses = useCallback((tab) => cn({
    [styles.tabsButton]: true,
    [styles.tabsButtonActive]: currentTab === tab,
  }), [currentTab]);

  const handleTabClick = useCallback((tab) => (e) => {
    e.preventDefault();
    onTabClick(tab);
  }, [onTabClick]);

  return (
    <div className={styles.tabs}>
      {
        tabs.map(({ label, id, additionalLabel, icon }) => (
          <button
            type="button"
            className={buttonClasses(id)}
            key={id}
            onClick={handleTabClick(id)}
          >
            <span className={styles.tabsButtonLabel}>
              {label}{additionalLabel && ` · ${additionalLabel}`}
            </span>
            <span className={styles.tabsButtonIcon}>
              {icon}
            </span>
          </button>
        ))
      }
    </div>
  );
};

export default TabsButton;
