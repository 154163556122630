import React, { useEffect } from 'react';

import styles from './Entry.module.scss';
import Navbar from '../../containers/layout/Navbar';
import { Outlet } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { mainApi } from '../../store/apis';
import ScrollToTop from '../../components/ScrollToTop';

const Entry = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(mainApi.util.resetApiState());
  }, []);

  return (
    <div className={styles.entry}>
      <ScrollToTop />
      <Navbar />
      <div className={styles.entryContent}>
        <div className={styles.entryBlock}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Entry;
