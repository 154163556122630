import React, { useCallback } from 'react';

import styles from './RecoverPasswordRequest.module.scss';
import Title from '../../../components/Title';
import Text from '../../../components/Text';
import { Link, useNavigate } from 'react-router-dom';
import appRoutes from '../../../app/appRoutes';
import PhoneInputController from '../../../components/PhoneInput';
import Button from '../../../components/Button';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useRequestRecoveryCodeMutation } from '../../../store/apis/auth';
import { toast } from 'react-toastify';
import { createDataSchema } from '../../../yup';
import { string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import useQueryString from '../../../hooks/useQueryString';
import queryString from 'query-string';

const schema = createDataSchema({
  phoneNumber: string().length(12).required(),
});

const RecoverPasswordRequest = () => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const query = useQueryString();

  const [
    requestCode,
    {
      isLoading,
      isFetching,
    }
  ] = useRequestRecoveryCodeMutation();

  const {
    control,
    handleSubmit: submit,
  } = useForm({
    defaultValues: {},
    resolver: yupResolver(schema),
  });

  const handleSubmit = useCallback(async (values) => {
    try {
      await requestCode(values).unwrap();
      navigate({
        pathname: appRoutes.setRecoverPassword(),
        search: queryString.stringify(query),
      }, { state: values });
      toast.success(t('На Ваш номер был отправлен код подтверждения'));
    } catch (e) {
      console.log(JSON.stringify(e.data ?? e, 0, 2));
    }
  }, [navigate, requestCode, t]);

  return (
    <div className={styles.recover}>
      <form className={styles.recoverForm} onSubmit={submit(handleSubmit)}>
        <div className={styles.recoverFormHeader}>
          <Title level={3}>{t('Восстановление пароля')}</Title>
          <Text level={2} color="secondary">
            {t('Еще нет аккаунта?')}{' '}
            <Link
              to={{
                pathname: appRoutes.register(),
                search: queryString.stringify(query),
              }}
            >
              {t('Регистрация')}
            </Link>
          </Text>
        </div>
        <div className={styles.recoverFormBody}>
          <PhoneInputController
            control={control}
            name="phoneNumber"
            label={t('Номер телефона')}
            placeholder="+998 99 9999999"
            error="Формат мобильного телефона неправильный"
            inputFormat="+### ## #######"
            mask=" "
          />

          <Button type="submit" fullWidth loading={isLoading || isFetching} className="Button_button__JBBzO_Recover">
            {t('Отправить код подтверждения')}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default RecoverPasswordRequest;
