import React, { useCallback } from 'react';
import cn from 'classnames';

import styles from './LinkedTelegramAccounts.module.scss';
import { useTranslation } from 'react-i18next';
import {
  useGetLinkedTelegramBotAccountsQuery,
  useUnsubscribeTelegramBotAccountMutation
} from '../../../store/apis/profile';
import Title from '../../../components/Title';
import ContentEmpty from '../../../components/ContentEmpty';
import EmptySimple from '../../../components/EmptySimple';
import PhoneChangeForm from '../PhoneChangeForm';
import Modal from '../../../components/Modal';
import Button from '../../../components/Button';
import { toast } from 'react-toastify';

const LinkedTelegramAccounts = () => {
  const [t] = useTranslation();

  const {
    data: linkedList,
    isLoading,
    isFetching,
  } = useGetLinkedTelegramBotAccountsQuery({ page: -1 });

  const [
    unsubscribeAccount,
    {
      isLoading: isUnsubscribeLoading,
      isFetching: isUnsubscribeFetching,
    }
  ] = useUnsubscribeTelegramBotAccountMutation();

  const unsubscribe = useCallback((id, cb) => async () => {
    try {
      await unsubscribeAccount(id).unwrap();
      toast.success(t('Аккаунт успешно отвязан от уведомлений'));
      cb();
    } catch (e) {
      console.log(JSON.stringify(e.data ?? e, 0, 2));
    }
  }, [t, unsubscribeAccount]);

  return (
    <div className={styles.container}>
      <Title level={6} bold>
        {t('Подписавшиеся аккаунты')}
      </Title>

      {
        (linkedList && linkedList.results.length > 0)
          ? (
            <ul className={styles.list}>
              {
                linkedList.results.map(({ id, name, username }) => (
                  <li className={styles.listItem} key={id}>
                    {
                      username
                        ? <a href={`https://t.me/${username}`} target="_blank" rel="noopenner noreferrer">{name}</a>
                        : <span>{name}</span>
                    }
                    <Modal
                      trigger={
                        <button
                          type="button"
                          className={styles.listItemAction}
                        >
                          {t('Отвязать аккаунт')}
                        </button>
                      }
                      title={t('Вы уверены?')}
                      closeOnDocumentClick
                    >
                      {(close) => (
                        <div className={styles.listItemActionModal}>
                          <Title level={6}>
                            {t('Бот больше не будет присылать важные уведомления от BILIMDOSH на этот телеграм аккаунт')}
                          </Title>
                          <div className={styles.listItemActionModalFooter}>
                            <Button
                              color="secondary"
                              onClick={close}
                            >
                              {t('Нет, отменить')}
                            </Button>
                            <Button
                              onClick={unsubscribe(id, close)}
                              loading={isUnsubscribeLoading || isUnsubscribeFetching}
                            >
                              {t('Да, отвязать')}
                            </Button>
                          </div>
                        </div>
                      )}
                    </Modal>

                  </li>
                ))
              }
            </ul>
          ) : (
            <EmptySimple
              title={t('У Вас нет подписавшихся аккаунтов телеграм')}
              text={t('Вы можете сгенерировать ссылку на телеграм-бот, нажав на кнопку слева В телеграм бот будут приходить важные уведомления от системы BILIMDOSH')}
              align="center"
            />
          )
      }
    </div>
  );
};

export default LinkedTelegramAccounts;
