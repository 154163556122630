import React from 'react';
import cn from 'classnames';

import styles from './TutorDetailGoals.module.scss';
import { useTranslation } from 'react-i18next';
import Title from '../../../components/Title';
import Tag from '../../../components/Tag';

const TutorDetailGoals = ({
  goals,
}) => {
  const [t, i18n] = useTranslation();

  return (
    <div className={styles.goals}>
      <Title level={4}>{t('Направления обучения')}</Title>
      <div className={styles.goalsList}>
        {goals.map((goal) => (
          <Tag label={goal.name[i18n.language]} key={goal.id} />
        ))}
      </div>
    </div>
  );
};

export default TutorDetailGoals;
