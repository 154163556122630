import React, { useCallback, useMemo } from 'react';
import cn from 'classnames';

import styles from './CheckboxInput.module.scss';
import { FiCheck } from 'react-icons/fi';

const CheckboxInput = ({
  onChange,
  value,
  name,
  label,
  size = 'medium',
}, ref) => {
  const checkboxMarkClasses = useMemo(() => cn({
    [styles.checkboxMark]: true,
    [styles.checkboxMarkChecked]: value,

    // sizes
    [styles.small]: size === 'small',
    [styles.medium]: size === 'medium',
    [styles.large]: size === 'large',
  }), [value, size]);

  const labelClasses = useMemo(() => cn({
    [styles.checkboxLabel]: true,

    // sizes
    [styles.small]: size === 'small',
    [styles.medium]: size === 'medium',
    [styles.large]: size === 'large',
  }), [size]);

  const handleChange = useCallback((e) => {
    const isChecked = e.target.checked;
    onChange(isChecked);
  }, [onChange]);

  return (
    <label className={styles.checkbox}>
      <input type="checkbox" checked={value} onChange={handleChange} name={name} ref={ref} />
      <span className={checkboxMarkClasses}>
        {value ? <FiCheck /> : null}
      </span>
      <span className={labelClasses}>{label}</span>
    </label>
  );
};

export default React.forwardRef(CheckboxInput);
