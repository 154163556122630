import React, { useMemo } from 'react';
import cn from 'classnames';

import styles from './TariffCard.module.scss';
import Text from '../Text';
import Title from '../Title';
import Button from '../Button';
import { ReactComponent as CircleCheck } from '../../icons/CircleCheck.svg';
import Tag from '../Tag';

const TariffCard = ({
  name,
  isActive,
  description,
  price,
  currencyName,
  lessonCount,
  buttonText,
  buttonLoading = false,
  salePercentage,
  id,
  onAction,
  cannotPurchase,
  cannotPurchaseText,
}) => {

  const cardClasses = useMemo(() => cn({
    [styles.card]: true,
    [styles.cardActive]: isActive,
  }), [isActive]);

  return (
    <div className={cardClasses}>
      {
        salePercentage && (
          <div className={styles.cardSale}>
            <Tag label={salePercentage} color="warning" />
          </div>
        )
      }
      <div className={styles.cardName}>
        <Text level={2} color="secondary">{name}</Text>
      </div>
      <div className={styles.cardPrice}>
        <Title level={4} noPadding noMargin>{price}</Title>
        <Text color="secondary" noPadding>{currencyName}</Text>
      </div>
      <div className={styles.cardLessons}>
        <CircleCheck /> <Text level={2} noPadding>{lessonCount}</Text>
      </div>
      <div className={styles.cardDescription}>
        <Text level={3} color="secondary">{description}</Text>
      </div>
      <div className={styles.cardFooter}>
        {
          cannotPurchase
            ? (
              <div className={styles.cardDisabled}>{cannotPurchaseText}</div>
            ) : (
              <Button
                type="button"
                fullWidth
                size="small"
                color={isActive ? 'primary' : 'secondary'}
                onClick={onAction}
                className="Button_BuyTariff"
                loading={buttonLoading}
              >
                {buttonText}
              </Button>
            )
        }
      </div>
    </div>
  );
};

export default TariffCard;
