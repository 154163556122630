import React, { useEffect } from 'react';
import cn from 'classnames';

import styles from './UnreadChatMessages.module.scss';
import Button from '../../../components/Button';
import { useTranslation } from 'react-i18next';
import {
  setBunchLastMessage,
  setInitialUnreadFileCounts,
  setInitialUnreadMessageCounts, useAllLastMessages,
  useChatAllUnreadFilesAndMessages
} from '../../../store/slicers/chat';
import { sum, values } from 'ramda';
import { FiArrowRight, FiBell } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import appRoutes from '../../../app/appRoutes';
import { useLazyGetChatDialogsQuery } from '../../../store/apis/chats';
import { useDispatch } from 'react-redux';
import UserInfoSmall from '../../../components/UserInfoSmall';

const UnreadChatMessages = () => {
  const [t, i18n] = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [unreadMessages, unreadFiles] = useChatAllUnreadFilesAndMessages();
  const allLastMessages = useAllLastMessages();

  const [
    getChatDialogs,
    { data: dialogs, isFetching: isDialogsFetching, isLoading: isDialogsLoading }
  ] = useLazyGetChatDialogsQuery();

  const unreadMessageCount = sum(values(unreadMessages));
  const unreadFileCount = sum(values(unreadFiles));

  useEffect(() => {
    getChatDialogs({ page: -1 })
      .unwrap()
      .then((dialogItems) => {
        const unreadMessagesByChatId = dialogItems.results.reduce((acc, { id, unreadTextMessagesCount }) => ({
          ...acc,
          [id]: unreadTextMessagesCount,
        }), {});
        const unreadFilesByChatId = dialogItems.results.reduce((acc, { id, unreadFileMessagesCount }) => ({
          ...acc,
          [id]: unreadFileMessagesCount,
        }), {});
        dispatch(setInitialUnreadMessageCounts(unreadMessagesByChatId));
        dispatch(setInitialUnreadFileCounts(unreadFilesByChatId));

        const lastMessages = dialogItems.results.reduce((acc, { id, lastMessage }) => ({
          ...acc,
          [id]: lastMessage,
        }), {});
        dispatch(setBunchLastMessage(lastMessages))
      })
      .catch((e) => {
        console.log(JSON.stringify(e.data ?? e, 0, 2));
      });
  }, [dispatch, getChatDialogs]);

  const goToChats = () => {
    navigate(appRoutes.chats());
  };

  return (
    <div className={styles.wrapper}>
      {
        (!unreadMessageCount && !unreadFileCount)
          ? (
            <div className={styles.empty}>
              <img src="/images/chat-placeholder-empty.png" alt=""/>
              <h3 className={styles.emptyTitle}>{t('У Вас нет непрочитанных сообщений')}</h3>
            </div>
          ) : (
            <div className={styles.content}>
              <h3 className={styles.title}>{t('Непрочитанные сообщения')}</h3>
              <div className={styles.dialogs}>
                {
                  (dialogs && dialogs.results.length > 0)
                    ? dialogs.results
                      .filter(({ id }) => unreadMessages[id] || unreadFiles[id])
                      .map(({ id, user }) => (
                        <div className={styles.dialogsItem}>
                          <UserInfoSmall
                            name={user.fullName?.[i18n.language]}
                            photoUrl={user.photoUrl}
                            message={allLastMessages[id]?.text}
                          />
                        </div>
                      ))
                    : null
                }
              </div>
              <div className={styles.action}>
                <button
                  onClick={goToChats}
                  type="button"
                  className={styles.actionButton}
                >
                  {t('В чаты')}
                  <span>
                    <FiArrowRight />
                  </span>
                </button>
              </div>
            </div>
          )
      }
    </div>
  );
};

export default UnreadChatMessages;
