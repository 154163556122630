import React, { useCallback, useEffect, useState } from 'react';
import { endOfDay, endOfWeek, format, formatISO, parseISO, startOfDay, startOfWeek } from 'date-fns';
import WeekCalendarRecord from '../../../components/WeekCalendarRecord';
import WeekCalendar from '../../../components/WeekCalendar';
import { useTranslation } from 'react-i18next';
import {
  useGetRescheduledLessonListQuery,
  useLazyGetCancelledListQuery,
  useLazyGetScheduledLessonsQuery
} from '../../../store/apis/lessons/scheduled';
import { dateLocales } from '../../../localizations/i18n';
import { identity } from 'ramda';

const ScheduleWeek = () => {
  const [t, i18n] = useTranslation();
  const [startDate, setStartDate] = useState(startOfWeek(new Date(), { locale: dateLocales[i18n.language] }));
  const [endDate, setEndDate] = useState(endOfWeek(new Date(), { locale: dateLocales[i18n.language] }));
  const [getLessons, { data: lessons, isLoading, isFetching }] = useLazyGetScheduledLessonsQuery();

  const [getCanceledLessons, {
    data: canceledLessons,
    isLoading: isCanceledLessonsLoading,
    isFetching: isCanceledLessonsFetching,
  }] = useLazyGetCancelledListQuery();

  useEffect(() => {
    getLessons({
      fromTime: formatISO(startOfDay(startDate)),
      toTime: formatISO(endOfDay(endDate)),
    }, true);
    getCanceledLessons({
      lessonFromTime: formatISO(startOfDay(startDate)),
      lessonToTime: formatISO(endOfDay(endDate)),
      typeAliasNot: 'transfer',
    })
  }, [endDate, getCanceledLessons, getLessons, startDate]);

  const {
    data: rescheduledLessons,
    isLoading: isRescheduledLessonsLoading,
    isFetching: isRescheduledLessonsFetching,
  } = useGetRescheduledLessonListQuery();

  const setNewWeekDiapason = useCallback((start, end) => {
    setStartDate(start);
    setEndDate(end);
  }, []);

  // TODO: Доделать отображение расписания в календаре

  const records = lessons?.map((lesson) => ({
    id: lesson.id,
    date: lesson.lessonTime,
    render: () => (
      <WeekCalendarRecord
        schedule={lesson.schedule}
        lessonTime={lesson.lessonTime}
        lessonId={lesson.id}
        cancelAvailable={lesson.cancelAvailable}
        transferAvailable={lesson.transferAvailable}
        startAvailable={lesson.joinAvailable}
        classroomId={lesson.classroomId}
        rescheduleData={null}
        color="primary"
      />
    )
  })) || [];

  const rescheduledRecords = rescheduledLessons?.results
    .map((lesson) => {
      // statusId === 1 ---- new
      // statusId === 2 ---- approved
      // statusId === 3 ---- rejected
      const statusId = lesson.status.id;
      if (statusId === 2) return null;

      return {
        id: lesson.id,
        date: lesson.fromLessonTime,
        render: () => (
          <WeekCalendarRecord
            schedule={lesson.lessonSchedule}
            lessonTime={lesson.fromLessonTime}
            rescheduleData={statusId === 1 ? {
              ...lesson,
              fromLessonTime: format(parseISO(lesson.fromLessonTime), 'dd MMMM, HH:mm', {locale: dateLocales[i18n.language]}),
              toLessonTime: format(parseISO(lesson.toLessonTime), 'dd MMMM, HH:mm', {locale: dateLocales[i18n.language]}),
            } : null}
            cancelData={statusId === 3 ? {
              ...lesson,
              comment: (lesson.autorType.id === 2)
                ? t('Урок был отменен автоматически, поскольку Вы отказали в переносе или не отреагировали вовремя')
                : t('Урок был отменен автоматически, поскольку Учитель отказал в переносе или не отреагировал вовремя'),
              lessonTime: format(parseISO(lesson.fromLessonTime), 'dd MMMM, HH:mm', { locale: dateLocales[i18n.language] }),
            } : null}
            color={statusId === 3 ? 'danger' : 'warning'}
          />
        )
      };
    })
    .filter(identity) || [];

  const canceledRecords = canceledLessons?.results
    .map((lesson) => {
      return {
        id: lesson.id,
        date: lesson.lessonTime,
        render: () => (
          <WeekCalendarRecord
            schedule={lesson.lessonSchedule}
            lessonTime={lesson.lessonTime}
            cancelData={{
              ...lesson,
              lessonTime: format(parseISO(lesson.lessonTime), 'dd MMMM, HH:mm', { locale: dateLocales[i18n.language] }),
            }}
            color="danger"
          />
        )
      };
    }) || [];

  return (
    <WeekCalendar
      onWeekChange={setNewWeekDiapason}
      loading={
        isLoading
        || isFetching
        || isRescheduledLessonsLoading
        || isRescheduledLessonsFetching
        || isCanceledLessonsLoading
        || isCanceledLessonsFetching
      }
      records={[...records, ...rescheduledRecords, ...canceledRecords]}
    />
  );
};

export default ScheduleWeek;
