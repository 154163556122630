import { isRejected, isRejectedWithValue } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import i18n from '../../localizations/i18n';
import appRoutes from '../../app/appRoutes';
import router from '../../app/browserRouter';
import localforage from 'localforage';

export const rtkQueryErrorLogger = (api) => (next) => (action) => {
  if (!isRejectedWithValue(action)) return next(action);

  if (
    action.meta?.arg?.endpointName === 'sendWainSignal'
    && action.payload.status === 404
  ) {
    return next(action);
  }

  const response = action.payload;

  if (response.status === 500) {
    console.error('Произошла ошибка 500');
  }

  if (response.status === 401) {
    toast.error(i18n.t('Срок вашей сессии истек'));
    router.navigate(appRoutes.login());
    localforage.removeItem('credentials');
    return next(action);
  }

  if (response.status === 403) {
    toast.error(response.data?.message, { toastId: 403 });
    return next(action);
  }

  if (response.status === 413) {
    toast.error(i18n.t('Размер файла превышает допустимое значение'), { toastId: 403 });
    return next(action);
  }

  if (response.data) {
    const {
      type,
      message = i18n.t('Произошла неизвестная ошибка'),
      details,
    } = response.data;

    if (type === 'form') {
      toast.error(message);
      return next(action);
    }
    if (type === 'simple') {
      toast.error(message);
      return next(action);
    }
    toast.error(`${response.status} - ${message}`);
    return next(action);
  }

  if (response.status === 'FETCH_ERROR') {
    toast.error(i18n.t('Произошла ошибка связи с сервером Попробуйте немного позже'));
    console.error('FETCH_ERROR: Произошла ошибка связи с сервером');
    return next(action);
  }
  toast.error(`${response.status} - ${response.message}`);

  return next(action);
}
