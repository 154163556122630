import React from 'react';

import styles from './TransferPackageMessageModal.module.scss';
import Modal from '../Modal';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import TextareaInputController from '../TextareaInput/TextareaInputController';
import UserInfoSmall from '../UserInfoSmall';
import { FiArrowDown, FiArrowRight } from 'react-icons/fi';
import { formatNumber } from '../../utils';
import Button from '../Button';
import { yupResolver } from '@hookform/resolvers/yup';
import { createDataSchema } from '../../yup';
import { string } from 'yup';

const schema = createDataSchema({
  comment: string().required(),
});

const TransferPackageMessageModal = ({
  onClose,
  onSubmit,
  teacher,
  fromTeacher,
  pack,
  loading,
}) => {
  const [t, i18n] = useTranslation();

  const {
    control,
    handleSubmit: submit,
    watch,
    setValue,
    reset,
  } = useForm({
    defaultValues: {},
    resolver: yupResolver(schema),
  });

  const handleSubmit = (close) => async (values) => {
    try {
      await onSubmit(values);
      reset();
      close();
    } catch (e) {
      console.log(e);
    }
  };

  const handleClose = () => {
    reset();
    onClose();
  };

  if (!teacher || !fromTeacher || !pack) return null;

  return (
    <Modal
      title={t('Перенести пакет на другого учителя')}
      subtitle={t('Остаток средств в этом пакете будет перенесен в счет другого учителя')}
      variant="secondary"
      closeOnDocumentClick={false}
      open={Boolean(teacher)}
      onClose={handleClose}
    >
      {(close) => (
        <form onSubmit={submit(handleSubmit(close))} className={styles.form}>
          <div className={styles.transfers}>
            <div className={styles.transfersFrom}>
              <UserInfoSmall
                photoUrl={fromTeacher.photoUrl}
                name={fromTeacher.fullName?.[i18n.language]}
                message={fromTeacher.shortInfo[i18n.language]}
              />

            </div>
            <div className={styles.transfersPack}>
              <div className={styles.transfersPackIcon}>
                <FiArrowRight />
              </div>
              <div className={styles.transfersPackAmount}>
                {formatNumber(pack.balance)} {t('сум')}
              </div>
              <div className={styles.transfersPackIcon}>
                <FiArrowDown />
              </div>
            </div>
            <div className={styles.transfersTo}>
              <UserInfoSmall
                photoUrl={teacher.photoUrl}
                name={teacher.fullName?.[i18n.language]}
                message={teacher.shortInfo[i18n.language]}
              />
            </div>
          </div>

          <div className={styles.formMessage}>
            <TextareaInputController
              name="comment"
              control={control}
              placeholder={t('Напишите комментарий')}
              variant="secondary"
            />
          </div>

          <div className={styles.formActions}>
            <Button type="button" size="medium" onClick={close} color="secondary">
              {t('Отменить')}
            </Button>
            <Button
              type="submit"
              size="medium"
              loading={loading}
            >
              {t('Перенести')}
            </Button>
          </div>
        </form>
      )}
    </Modal>
  );
};

export default TransferPackageMessageModal;
