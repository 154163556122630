import React, { useCallback } from 'react';
import DatePicker from 'react-datepicker';
import { PatternFormat } from 'react-number-format';
import TextInput from '../TextInput';
import { isValid, parseISO } from 'date-fns';
import { dateToISO } from '../../utils';

const PatterFormatWithRef = React.forwardRef((props, ref) => {
  return (
    <PatternFormat
      {...props}
      getInputRef={ref}
    />
  );
});

const DateInput = React.forwardRef(({
  value,
  withTime = false,
  locale,
  onChange,
  todayButton,
  timeInputLabel,
  error,
  ...restProps
}, ref) => {
  const handleChange = useCallback((date) => {
    const formatedDate = dateToISO(date, withTime);
    onChange(formatedDate);
  }, [onChange, withTime]);

  const preparedSelected = isValid(parseISO(value)) ? parseISO(value) : null;

  return (
    <DatePicker
      {...restProps}
      onChange={handleChange}
      dateFormat={withTime ? 'dd.MM.yyyy (HH:mm)' : 'dd.MM.yyyy'}
      selected={preparedSelected}
      showTimeInput={withTime}
      locale={locale}
      todayButton={todayButton}
      timeInputLabel={timeInputLabel}
      customInput={
        <PatterFormatWithRef
          customInput={TextInput}
          format={withTime ? '##.##.#### (##:##)' : '##.##.####'}
          mask="_"
          allowEmptyFormatting
          {...restProps}
          error={error}
        />
      }
    />
  );
});

export default DateInput;
