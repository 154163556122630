import React from 'react';
import cn from 'classnames';

import styles from './TutorAbout.module.scss';
import Title from '../../../components/Title';
import { useTranslation } from 'react-i18next';
import Text from '../../../components/Text';

const TutorAbout = ({ text }) => {
  const [t] = useTranslation();

  return (
    <div className={styles.about}>
      <Title level={4}>{t('О преподавателе')}</Title>
      <Text level={3}>{text}</Text>
    </div>
  );
};

export default TutorAbout;
