import React, { useEffect } from 'react';

import styles from './CancellingLessonsAlert.module.scss';
import Container from '../../../components/Container';
import { Link, useLocation } from 'react-router-dom';
import appRoutes from '../../../app/appRoutes';
import { useGetUnpaidInvoicesQuery } from '../../../store/apis/tariffs';
import { useGetServiceConfigsQuery } from '../../../store/apis/serviceConfigs';
import { useTranslation } from 'react-i18next';
import { useGetLessonSchedulesQuery } from '../../../store/apis/lessons/scheduled';

const CancellingLessonsAlert = () => {
  const [t] = useTranslation();
  const location = useLocation();

  const {
    data: lessonSchedules,
    refetch,
  } = useGetLessonSchedulesQuery({ page: -1, isActive: false });

  const {
    data: invoiceList,
  } = useGetUnpaidInvoicesQuery({ page: -1 });

  useEffect(() => {
    refetch();
  }, [location.pathname, location.search, refetch]);

  if (!lessonSchedules || lessonSchedules?.results.length === 0) return null;
  if (invoiceList && invoiceList.results.length > 0) return null;

  return (
    <Container>
      <div className={styles.alert}>
        <h3 className={styles.alertTitle}>
          {t('У Вас есть уроки, которые будут отменены')}
        </h3>
        <p className={styles.alertText}>
          {t('Если Вы не пополните баланс, то расписание для уроков будет отменено')}
        </p>
        <div className={styles.alertActions}>
          <Link
            to={{
              pathname: appRoutes.schedule(),
              search: `?tab=cancellingLessons`
            }}
            className={styles.alertLink }
          >
            {t('Посмотреть отменяемые уроки')}
          </Link>
        </div>
      </div>
    </Container>
  );
};

export default CancellingLessonsAlert;
