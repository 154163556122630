import React from 'react';
import TextareaInput from './TextareaInput';
import { Controller } from 'react-hook-form';

const TextareaInputController = ({
  control,
  name,
  ...rest
}) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({ field, fieldState }) => (
        <TextareaInput {...field} {...rest} error={fieldState.error?.message} />
      )}
    />
  )
};

export default TextareaInputController;
