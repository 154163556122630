import { invalidateOn } from '../utils';
import apiRoutes from '../../apiRoutes';
import { mainApi } from './index';

mainApi.injectEndpoints({
  endpoints: (build) => ({
    getFavoriteTutors: build.query({
      query: (params) => ({
        url: apiRoutes.favoriteTutors(),
        params,
      }),
      providesTags: ['FAVORITE_TUTORS'],
    }),
    addFavoriteTutors: build.mutation({
      query: ({ tutorId, params }) => ({
        url: apiRoutes.favoriteTutors(tutorId),
        method: 'PUT',
      }),
      onQueryStarted: async ({ tutorId, params }, { dispatch, queryFulfilled, getState }) => {
        let addingToFavoritesResult;
        for (const { endpointName, originalArgs } of mainApi.util.selectInvalidatedBy(getState(), ['TUTORS'])) {
          // we only want to update `getPosts` here
          if ( endpointName !== 'getTutorList') continue;
          addingToFavoritesResult = dispatch(
            mainApi.util.updateQueryData(endpointName, originalArgs, (draft) => {
              draft.results.forEach((teacher) => {
                if (teacher.id === tutorId) teacher.isFavorite = true;
              });
            })
          )
        }

        const addingToFavoritesDetailsResult = dispatch(
          mainApi.util.updateQueryData('getTutorDetails', tutorId, (draft) => {

            draft.isFavorite = true;
          })
        );

        try {
          await queryFulfilled;
        } catch (e) {
          addingToFavoritesResult.undo();
          addingToFavoritesDetailsResult.undo();
        }
      },
      invalidatesTags: invalidateOn({ success: ['FAVORITE_TUTORS'] }),
    }),
    removeFavoriteTutors: build.mutation({
      query: ({ tutorId, params }) => ({
        url: apiRoutes.favoriteTutors(tutorId),
        method: 'DELETE',
      }),
      onQueryStarted: async ({ tutorId, params }, { dispatch, queryFulfilled, getState }) => {
        let addingToFavoritesResult;

        for (const { endpointName, originalArgs } of mainApi.util.selectInvalidatedBy(getState(), ['TUTORS'])) {
          if ( endpointName !== 'getTutorList') continue;
          addingToFavoritesResult = dispatch(
            mainApi.util.updateQueryData(endpointName, originalArgs, (draft) => {
              draft.results.forEach((teacher) => {
                if (teacher.id === tutorId) teacher.isFavorite = false;
              });
            })
          )
        }

        const addingToFavoritesDetailsResult = dispatch(
          mainApi.util.updateQueryData('getTutorDetails', tutorId, (draft) => {

            draft.isFavorite = false;
          })
        );

        try {
          await queryFulfilled;
        } catch (e) {
          addingToFavoritesResult.undo();
          addingToFavoritesDetailsResult.undo();
        }
      },
      invalidatesTags: invalidateOn({ success: ['FAVORITE_TUTORS'] }),
    }),

  })
})

export const {
  useGetFavoriteTutorsQuery,
  useAddFavoriteTutorsMutation,
  useRemoveFavoriteTutorsMutation,
} = mainApi;
