import React from 'react';
import * as Sentry from "@sentry/react";
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "react-datepicker/dist/react-datepicker.css";
import 'react-toastify/scss/main.scss';
import 'swiper/css';
import '@smastrom/react-rating/style.css';
import 'react-range-slider-input/dist/style.css';
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import { I18nextProvider } from 'react-i18next';
import i18n from './localizations/i18n';
import uz from 'date-fns/locale/uz';
import ru from 'date-fns/locale/ru';
import en from 'date-fns/locale/en-US';
import './index.scss';
import { CaptureConsole, HttpClient } from '@sentry/integrations';

const container = document.getElementById('root');
const root = createRoot(container);

registerLocale('uz', uz);
registerLocale('en', en);
registerLocale('ru', ru);

Sentry.init({
  dsn: "https://32c4ea460091d172eb3f8c5cf8203549@o4505743067840512.ingest.sentry.io/4505754111770624",
  integrations: [
    new HttpClient({
      failedRequestStatusCodes: [[500, 505], 507, 404],
      failedRequestTargets: [
        'https://api.beshplus.uz',
        'https://app.beshplus.uz',
      ],
    }),
    new CaptureConsole({
      levels: ['error'],
    }),
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["https:api.beshplus.uz"],
    }),
    new Sentry.Replay(),
  ],
  enabled: process.env.REACT_APP_ENV === 'production',
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

root.render(
  <I18nextProvider i18n={i18n}>
    <Provider store={store}>
      <App />
    </Provider>
  </I18nextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
