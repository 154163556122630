import React, { useMemo } from 'react';
import cn from 'classnames';

import styles from './TextInput.module.scss';

const TextInput = ({
  error = null,
  disabled = false,
  type = 'text',
  label = null,
  id = null,
  appendix,
  ...rest
}, ref) => {
  const inputClasses = useMemo(() => cn({
    [styles.inputField]: true,
    [styles.inputFieldError]: error,
  }), [error]);

  const labelClasses = useMemo(() => cn({
    [styles.inputLabel]: true,
    [styles.inputLabelError]: error,
  }), [error]);

  return (
    <div className={styles.input}>
      {label && (<label htmlFor={id} className={labelClasses}>{label}</label>)}
      <div className={inputClasses}>
        <input
          {...rest}
          ref={ref}
          type={type}
          className={styles.inputFieldInput}
          disabled={disabled}
          id={id}
        />
        {appendix && <div className={styles.inputFieldAppendix}>{appendix}</div>}
      </div>
      {error && (<span className={styles.inputError}>{error}</span>)}
    </div>
  );
};

export default React.forwardRef(TextInput);
