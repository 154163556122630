import React from 'react';
import cn from 'classnames';
import ReactInputVerificationCode from 'react-input-verification-code';
import './ConfirmationPhoneInput.scss';

const ConfirmationPhoneInput = (props) => {

  return (
    <ReactInputVerificationCode
      length={6}
      placeholder=" "
      {...props}
    />
  );
};

export default ConfirmationPhoneInput;
