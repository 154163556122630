import React from 'react';
import cn from 'classnames';

import styles from './ScheduleWeeklyStartDateInput.module.scss';
import LoaderFullContent from '../LoaderFullContent';
import Text from '../Text';
import EmptySimple from '../EmptySimple';
import DateInput from '../DateInput';
import { useTranslation } from 'react-i18next';

const ScheduleWeeklyStartDateInput = ({
  isLoading,
  schedules = [],
  onChange,
  value
}) => {
  const [t] = useTranslation();

  return (
    <>
      {isLoading && <LoaderFullContent />}

      <div className={styles.tip}>
        <Text level={3} color="secondary">
          {t('Еженедельные занятия - это регулярные занятия, которые проводятся каждую неделю в выбранные вами дни и время')}
        </Text>
      </div>
      {
        (schedules.length === 0) ? (
          <EmptySimple
            title={t('У учителя нет доступного времени для еженедельных занятий')}
            text={t('Попробуйте забронировать разовые занятия')}
            align="center"
          />
        ) : (
          <div className={styles.startTime}>
            <DateInput
              label={t('Выберите дату начала первого урока')}
              onChange={onChange}
              value={value}
            />
          </div>
        )
      }
    </>
  );
};

export default ScheduleWeeklyStartDateInput;
