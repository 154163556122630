import React, { useState } from 'react';
import cn from 'classnames';

import styles from './ChatWindowHeader.module.scss';
import ContentLoader from 'react-content-loader';
import UserInfoSmall from '../../../components/UserInfoSmall';
import { useChat } from '../../../store/slicers/chat';
import { useGetChatDetailsQuery } from '../../../store/apis/chats';
import { useTranslation } from 'react-i18next';

const ChatWindowHeader = () => {
  const [_, i18n] = useTranslation();
  const chat = useChat();

  const {
    data: chatDetails,
  } = useGetChatDetailsQuery({ id: chat.activeChatId }, { skip: !chat.activeChatId });

  if (!chatDetails || !chatDetails.user) return null;

  return (
    <UserInfoSmall
      name={chatDetails.user.fullName?.[i18n.language]}
      photoUrl={chatDetails.user.photoUrl}
      message={chatDetails.user.userType.name[i18n.language]}
    />
  );
};

export default ChatWindowHeader;
