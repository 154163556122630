import React from 'react';
import cn from 'classnames';

import styles from './PopupMenu.module.scss';
import Popup from 'reactjs-popup';

const PopupMenu = ({
  trigger,
  children,
}) => {

  return (
    <Popup
      trigger={trigger}
      arrow={false}
      modal={false}
      position="bottom left"
    >
      <div className={styles.popup}>
        {children}
      </div>
    </Popup>
  );
};

export default PopupMenu;
