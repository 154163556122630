import React, { useCallback, useMemo, useState } from 'react';
import cn from 'classnames';
import styles from './Navbar.module.scss';

import { FiArrowRight, FiMenu, FiX } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Button';
import SimpleSelect from '../../../components/SimpleSelect';
import { externalRoutes } from '../../../settings';
import { useNavigate } from 'react-router-dom';
import appRoutes from '../../../app/appRoutes';
import LaguageSelect from '../../../components/LaguageSelect';

const languages = [
  { id: 'ru', value: 'ru', label: 'RU', chipValue: 'Рус' },
  { id: 'uz', value: 'uz', label: 'UZ', chipValue: 'Узб' },
  { id: 'en', value: 'en', label: 'EN', chipValue: 'Eng' },
];

const Navbar = () => {
  const [t, i18n] = useTranslation();
  const [menuVisible, setMenuVisible] = useState(false);
  const navigate = useNavigate();

  const currentLanguage = languages.find((l) => l.id === i18n.language);

  const menuClasses = useMemo(() => cn({
    [styles.navbarMenu]: true,
    [styles.visible]: menuVisible,
  }), [menuVisible]);

  const handleChangeLanguage = useCallback((option) => {
    i18n.changeLanguage(option.value);
    localStorage.setItem('userLanguage', option.value);
  }, [i18n]);

  const handleToggleMenu = useCallback((e) => {
    e.preventDefault();
    setMenuVisible(!menuVisible);
  }, [menuVisible]);

  const goToEntry = useCallback(() => {
    navigate(appRoutes.login());
  }, [navigate]);

  const logos = {
    ru: '/images/logo-rus.png',
    uz: '/images/logo-uzb.png',
    en: '/images/logo-eng.png',
  };

  return (
    <section className={styles.navbar}>
      <div className={styles.navbarBrand}>
        <a href={externalRoutes.root(i18n.language)} className={styles.navbarBrandLink}>
          <img
            src={logos[i18n.language]}
            alt="Логотип компании"
            className={styles.navbarBrandLogo}
          />
        </a>
      </div>
      <div
        className={cn(styles.navbarMenuCloseWrapper, menuVisible ? styles.navbarMenuCloseWrapperVisible : '')}
        onClick={() => {
          setMenuVisible(false);
        }}
      />
      <nav className={cn(styles.navbarMenu, menuVisible ? styles.navbarMenuVisible : '')}>
        <button
          type="button"
          className={styles.navbarMenuClose}
          onClick={() => {
            setMenuVisible(false);
          }}
        >
          <FiX />
        </button>
        <ul className={styles.navbarMenuList}>
          <li className={styles.navbarMenuListItem}>
            <a href={externalRoutes.landingTutors} className={styles.navbarMenuListLink}>
              {t('Репетиторы')}
            </a>
          </li>
          <li className={styles.navbarMenuListItem}>
            <a href={externalRoutes.faq} className={styles.navbarMenuListLink}>
              {t('FAQ')}
            </a>
          </li>
          <li className={styles.navbarMenuListItem}>
            <a href={externalRoutes.about} className={styles.navbarMenuListLink}>
              {t('Контакты')}
            </a>
          </li>
        </ul>
        <div className={styles.navbarMenuListEnter}>
          <Button
            type="button"
            size="medium"
            color="secondary"
            onClick={() => {
              if (window) window.location.href = externalRoutes.app;
            }}
          >
            {t('Личный кабинет')}
          </Button>
        </div>
      </nav>
      <div className={styles.navbarAside}>
        <div className={styles.navbarAsideLang}>
          <LaguageSelect
            options={languages}
            selected={currentLanguage}
            onChange={handleChangeLanguage}
          />
        </div>
        <div className={styles.navbarAsideToggler}>
          <button
            type="button"
            className={styles.navbarAsideTogglerButton}
            onClick={() => {
              setMenuVisible(true);
            }}
          >
            <span className={styles.navbarAsideTogglerButtonLine} />
            <span className={styles.navbarAsideTogglerButtonLineActive} />
            <span className={styles.navbarAsideTogglerButtonLine} />
          </button>
        </div>
      </div>
    </section>
  );

  return (
    <div className={styles.body}>
      <div className={styles.navbar}>
        <div className="container">
          <div className={styles.navbarWrapper}>
            <div className={styles.navbarBrand}>
              <a href={externalRoutes.root(i18n.language)} className={styles.navbarBrandLink}>
                <img
                  src={logos[i18n.language]}
                  alt="Логотип компании"
                  width="0"
                  height="0"
                  sizes="100vw"
                  className={styles.navbarBrandLogo}
                />
              </a>
            </div>

            <button type="button" className={styles.navbarBurger} onClick={handleToggleMenu}>
              <FiMenu />
            </button>
            <div className={menuClasses}>
              <button type="button" className={styles.navbarClose} onClick={handleToggleMenu}>
                <FiX />
              </button>
              <nav className={styles.navbarNav}>
                <ul className={styles.navbarNavMenu}>
                  {/*<li className={styles.navbarNavMenuItem}>*/}
                  {/*  <a href="/" className={styles.navbarNavMenuLink}>{t('Обучение')}</a>*/}
                  {/*</li>*/}
                  <li className={styles.navbarNavMenuItem}>
                    <a href={externalRoutes.landingTutors} className={styles.navbarNavMenuLink}>{t('Репетиторы')}</a>
                  </li>
                  {/*<li className={styles.navbarNavMenuItem}>*/}
                  {/*  <a href="/" className={styles.navbarNavMenuLink}>{t('Отзывы')}</a>*/}
                  {/*</li>*/}
                  {/*<li className={styles.navbarNavMenuItem}>*/}
                  {/*  <a href="/" className={styles.navbarNavMenuLink}>{t('Цены')}</a>*/}
                  {/*</li>*/}
                </ul>
              </nav>
              <div className={styles.navbarAside}>
                <div className={styles.navbarAsideLanguage}>
                  <SimpleSelect
                    options={languages}
                    selected={currentLanguage}
                    onChange={handleChangeLanguage}
                  />
                </div>
                <div className={styles.navbarAsideEnter}>
                  <Button
                    type="button"
                    size="small"
                    color="primary"
                    icon={<FiArrowRight />}
                    iconPosition="end"
                    onClick={goToEntry}
                  >
                    {t('Войти')}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
