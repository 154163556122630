import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

const initialState = {
  tutorId: null,
  tariffId: null,
  scheduleType: null,
};

export const booking = createSlice({
  name: 'booking',
  initialState,
  reducers: {
    clearBooking: (state) => initialState,
    setSchedule: (state, { payload: data }) => ({
      ...state,
      ...data,
    }),
    setTariff: (state, { payload: tariffId }) => ({ ...state, tariffId }),
    clearTariff: (state, { payload: tariffId }) => ({ ...state, tariffId: null }),
  },
});

export const useBookingData = () => {
  return useSelector((state) => state.booking);
};

export const {
  clearBooking,
  setSchedule,
  setTariff,
  clearTariff,
} = booking.actions;
