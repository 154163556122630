import React, { useState } from 'react';
import cn from 'classnames';

import styles from './FaqItem.module.scss';
import { FiMinus, FiPlus } from 'react-icons/fi';
import Button from '../Button';
import { useTranslation } from 'react-i18next';

const FaqItem = ({ title, body, videoUrl, onVideoClick }) => {
  const [t] = useTranslation();
  const [open, setOpen] = useState(false);

  return (
    <div className={styles.card}>
      <div className={styles.cardHeader}>
        <div className={styles.cardTitle}>
          {title}
        </div>
        <div className={styles.cardToggler}>
          <button
            type="button"
            className={cn({
              [styles.cardTogglerButton]: true,
              [styles.cardTogglerButtonOpened]: open,
            })}
            onClick={() => setOpen(!open)}
          >
            {open ? <FiMinus /> : <FiPlus/>}
          </button>
        </div>
      </div>
      <div
        className={cn({
          [styles.cardBody]: true,
          [styles.cardBodyVisible]: open,
        })}
      >
        <p className={styles.cardBodyText}>{body}</p>
        {videoUrl && (
          <div className={styles.cardBodyLink}>
            <Button
              onClick={onVideoClick}
              size="small"
              color="danger"
            >
              {t('Посмотреть видео')}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default FaqItem;
