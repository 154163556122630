import apiRoutes from '../../../apiRoutes';
import { mainApi } from '../index';

mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getSubjects: builder.query({
      query: (params) => ({
        url: apiRoutes.subjects(),
        params
      }),
    })
  })
});

export const {
  useGetSubjectsQuery,
} = mainApi
