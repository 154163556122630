import { useEffect } from 'react';
import { useGetRoomInfoMutation, useSendWainSignalMutation } from '../../store/apis/room';
import { useDispatch } from 'react-redux';
import { setConnectionPreparing, setUnexpectedBehaviour } from '../../store/slicers/room';
import useQueryString from '../../hooks/useQueryString';
import { useStartDialogMutation } from '../../store/apis/chats';
import useSockets from '../../hooks/useSockets';
import { closeChat, openChat } from '../../store/slicers/chat';
import usePeerConnection from './usePeerConnection';
import useMedia from './useMedia';
import EventType from '../EventType';

const usePrepareRoom = () => {
  const { roomId, tutorId } = useQueryString();
  const dispatch = useDispatch();

  const PeerConnection = usePeerConnection();
  const MediaDevice = useMedia()

  const [sendWaitSignal, { isLoading: isSendingSignal, startedTimeStamp }] = useSendWainSignalMutation();
  const [startDialog] = useStartDialogMutation();
  const [createOrGetRoom] = useGetRoomInfoMutation();

  const socket = useSockets();

  const emitOfferAndIces = () => {
    const localOffer = PeerConnection.localOffer;
    const localIces = PeerConnection.localIceCandidates;

    socket.emit('directSend', {
      userType: 'tutor',
      userId: tutorId,
      type: 'offerAndIces',
      data: {
        offer: localOffer,
        ices: localIces,
      },
    });
  };

  const initializeRoom = (offer, ices) => {
    dispatch(setConnectionPreparing('tutorConnecting'));
    // console.log('Проверяю есть ли стрим с локальной камеры');

    if (!MediaDevice.stream) {
      dispatch(setUnexpectedBehaviour({
        show: true,
        cause: 'NO_MEDIA_DEVICE_STREAM',
        canClose: false,
        canReload: true,
      }));
      dispatch(setConnectionPreparing(null));
      // console.log('Нет стрима с локальной камеры');
      return;
    }

    // console.log('Получаю данные комнаты и конфиг для подключения');
    createOrGetRoom({ roomId, tutorId }).unwrap()
      .then((cfg) => {
        // console.log('Конфиг получен, создаю Пир коннекшен');
        PeerConnection
          .startPeerConnection(cfg, MediaDevice.stream)
          .setRemoteDescription(offer);
        // console.log('Пир коннекшен создан');
      })

    PeerConnection
      .on(EventType.REMOTE_DESCRIPTION, () => {
        // console.log('Remote description установлен. Вызван хендлер');

        // console.log('Создаю промисы для установки ICE кандидатов');
        const addIceCandidatesPromises = ices.map((candidate) => {
          return new Promise((resolve) => {
            return PeerConnection.setRemoteIceCandidate(candidate)
              .then(resolve)
              .catch((err) => {
                // console.log('Не получилось установить один ICE по причине:');
                // console.log(err);
                resolve();

              });
          })
        });

        // console.log('Устанавливаю удаленные ICE кандидаты');
        Promise.all(addIceCandidatesPromises)
          .then(() => {
            // console.log('Кандидаты установлены');
            PeerConnection.emit(EventType.REMOTE_ICE_CANDIDATE)
          })
      })
      .on(EventType.REMOTE_ICE_CANDIDATE, () => {
        // console.log('Создаю ответ');
        PeerConnection.createAnswer();
      })
      .on(EventType.LOCAL_DESCRIPTION_SETTED, () => {
        // console.log('Локальный ответ установлен');
        // PeerConnection.emit('ICE_CANDIDATES_SETTED');
      })
      .on(EventType.ICE_CANDIDATES_SETTED, () => {
        // console.log('Локальные кандидаты установлены');
        // console.log('Пробую отправить кадидаты и ответ');
        emitOfferAndIces();
        // console.log('Отправлены');
      })
      .on(EventType.REMOTE_STREAM, (stream) => {
        document.getElementById('remote-video-player').srcObject = stream;
        setTimeout(() => {
          dispatch(setConnectionPreparing(null));
        }, 7000);
      })
      .on(EventType.ERROR, () => {
        dispatch(setConnectionPreparing(null));
      });
  };

  // Эффект открывает чат с преподавателем
  useEffect(() => {
    if (socket.connected) {
      startDialog({ userId: tutorId, userType: 'tutor' }).unwrap()
        .then((chat) => {
          dispatch(openChat(chat.id));
        })
        .catch((e) => {
          console.error(e);
        });
      return;
    }
    setTimeout(() => {
      socket.connect();
      startDialog({ userId: tutorId, userType: 'tutor' }).unwrap()
        .then((chat) => {
          dispatch(openChat(chat.id));
        })
        .catch((e) => {
          console.log(JSON.stringify(e.data ?? e, 0, 2));
        });
    }, 500);

    return () => {
      dispatch(closeChat());
    };
  }, []);

  // Отправляет сигнал о готовности подключаться.
  useEffect(() => {
    dispatch(setConnectionPreparing('connecting'))
    MediaDevice
      .on(EventType.STREAM, (_, state) => {
        if (state === 'new' && !isSendingSignal) {
          sendWaitSignal(roomId).then(() => {
            dispatch(setConnectionPreparing('waitTutor'));
          });
        }
      });

    // dispatch(setConnectionPreparing(null));
  }, [roomId]);

  useEffect(() => {
    const onReadyToConnect = () => {
      if (isSendingSignal) return;
      dispatch(setConnectionPreparing('connecting'))
      sendWaitSignal(roomId).then(() => {
        dispatch(setConnectionPreparing('waitTutor'));
      });
    };

    const onOfferAndIces = (data) => {
      // console.log('Получен оффер и ICES от учителя');
      const { ices, offer } = data;
      if (PeerConnection.peerConnection) {
        PeerConnection.closePeerConnection();
      }
      initializeRoom(offer, ices);
    };

    socket.on('offerAndIces', onOfferAndIces);
    socket.on('readyToConnect', onReadyToConnect)

    return () => {
      socket.off('offerAndIces', onOfferAndIces);
      socket.off('readyToConnect', onReadyToConnect)
    }
  });

  useEffect(() => {
    return () => {
      console.log('Пир коннекшен закрывается');
      PeerConnection.closePeerConnection();
    }
  }, []);

};

export default usePrepareRoom;
