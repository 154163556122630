import apiRoutes from '../../apiRoutes';
import { mainApi } from './index';
import { invalidateOn } from '../utils';

mainApi.injectEndpoints({
  endpoints: (build) => ({
    getTariffs: build.query({
      query: (params) => ({
        url: apiRoutes.tariffs(),
        params,
      }),
    }),
    checkSchedule: build.mutation({
      query: (body) => ({
        url: apiRoutes.checkSchedule(),
        body,
        method: 'POST',
      }),
    }),

    buyPackage: build.mutation({
      query: (body) => ({
        url: apiRoutes.buyPackage(),
        body,
        method: 'POST',
      }),
      invalidatesTags: invalidateOn({
        success: ['TUTORS_SINGLE_SCHEDULES', 'TUTORS_WEEKLY_SCHEDULES', 'SCHEDULES', 'UNPAID_INVOICES', 'USER_SCHEDULE_LIMIT'],
      })
    }),
    bookWithoutBuy: build.mutation({
      query: (body) => ({
        url: apiRoutes.bookWithoutBuy(),
        body,
        method: 'POST',
      }),
      invalidatesTags: invalidateOn({
        success: ['TUTORS_SINGLE_SCHEDULES', 'TUTORS_WEEKLY_SCHEDULES', 'SCHEDULES', 'USER_SCHEDULE_LIMIT', 'FREE_BALANCE'],
      })
    }),

    getPackageList: build.query({
      query: (params) => ({
        url: apiRoutes.packages(),
        params,
      }),
      providesTags: ['PACKAGE_LIST'],
    }),
    requestCancellationPackage: build.mutation({
      query: (body) => ({
        url: apiRoutes.packageCancellationRequests(),
        body,
        method: 'POST',
      }),
      invalidatesTags: invalidateOn({
        success: ['PACKAGE_LIST', 'FREE_BALANCE'],
      })
    }),
    transferPackage: build.mutation({
      query: ({ packId, body }) => ({
        url: apiRoutes.transferPackage(packId),
        body,
        method: 'POST',
      }),
      invalidatesTags: invalidateOn({
        success: ['PACKAGE_LIST', 'FREE_BALANCE'],
      })
    }),
    getUnpaidInvoices: build.query({
      query: (params) => ({
        url: apiRoutes.unpaidInvoices(),
        params,
      }),
      providesTags: ['UNPAID_INVOICES'],
    }),
    getUnpaidInvoiceDetails: build.query({
      query: (id) => ({
        url: apiRoutes.unpaidInvoices(id),
      }),
      providesTags: ['UNPAID_INVOICE_DETAILS'],
    }),
    getPackagesHistory: build.query({
      query: (params) => ({
        url: apiRoutes.packagesHistory(),
        params,
      }),
      providesTags: ['PACKAGES_HISTORY'],
    }),
    cancelInvoice: build.mutation({
      query: (invoice) => ({
        url: apiRoutes.unpaidInvoices(invoice),
        method: 'DELETE',
      }),
      invalidatesTags: invalidateOn({
        success: ['UNPAID_INVOICES', 'UNPAID_INVOICE_DETAILS'],
      })
    }),
    getFreeBalance: build.query({
      query: (params) => ({
        url: apiRoutes.freeBalance(),
        params,
      }),
      providesTags: ['FREE_BALANCE'],
    }),
  }),
})

export const {
  useLazyGetTariffsQuery,
  useCheckScheduleMutation,
  useBuyPackageMutation,
  useBookWithoutBuyMutation,
  useGetPackageListQuery,
  useRequestCancellationPackageMutation,
  useTransferPackageMutation,
  useGetUnpaidInvoicesQuery,
  useLazyGetUnpaidInvoiceDetailsQuery,
  useGetPackagesHistoryQuery,
  useCancelInvoiceMutation,
  useGetFreeBalanceQuery,
} = mainApi;
