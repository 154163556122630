import React, { useCallback } from 'react';
import cn from 'classnames';

import styles from './TeacherList.module.scss';
import { useTranslation } from 'react-i18next';
import TeacherCard from '../../../components/TeacherCard';
import { joinExisting } from '../../../utils';
import ContentLoader from 'react-content-loader';
import { openChat } from '../../../store/slicers/chat';
import appRoutes from '../../../app/appRoutes';
import { useNavigate } from 'react-router-dom';
import { useStartDialogMutation } from '../../../store/apis/chats';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import { head } from 'ramda';
import { useAddFavoriteTutorsMutation, useRemoveFavoriteTutorsMutation } from '../../../store/apis/favorites';
import useQueryString from '../../../hooks/useQueryString';

const TeacherList = ({
  teachers = [],
  loading,
}) => {
  const [, i18n] = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { page = 1, pageSize = 20, ...restQuery } = useQueryString();

  const [
    startDialog,
    {
      isLoading: isStartDialogLoading,
      isFetching: isStartDialogFetching,
    }
  ] = useStartDialogMutation();

  const [
    addToFavorite,
  ] = useAddFavoriteTutorsMutation();

  const [
    removeFromFavorite,
  ] = useRemoveFavoriteTutorsMutation();

  const openTutorChat = useCallback((tutorId) => async () => {
    try {
      const chat = await startDialog({ userId: tutorId, userType: 'tutor' }).unwrap();
      dispatch(openChat(chat.id));
      navigate(appRoutes.chats());
    } catch (e) {
      console.log(JSON.stringify(e.data ?? e, 0, 2));
    }
  }, [dispatch, navigate, startDialog]);

  const goToTeacherSchedule = useCallback((tutorId) => () => {
    navigate({
      pathname: appRoutes.tutors(tutorId),
      search: queryString.stringify({ section: 'schedule' }),
    });
  }, [navigate]);

  const makeFavorite = useCallback((id) => async () => {
    await addToFavorite({
      tutorId: id,
      params: {
        page,
        pageSize,
        ...restQuery,
      },
    });
  }, [addToFavorite, page, pageSize, restQuery]);

  const removeFavorite = useCallback((id) => async () => {
    await removeFromFavorite({
      tutorId: id,
      params: {
        page,
        pageSize,
        ...restQuery,
      },
    });
  }, [removeFromFavorite, page, pageSize, restQuery]);

  return (
    <div className={styles.teachers}>
      <div className={styles.teachersList}>
        {
          loading
            ? (
              <div className={styles.teachersListItem}>
                <ContentLoader
                  backgroundColor={'#c8c8c8'}
                  foregroundColor={'#eceaea'}
                  width="100%"
                  height="460"
                  speed={2}
                >
                  <rect x="0" y="0" rx="20" ry="20" width="100%" height="220" />
                  <rect x="0" y="240" rx="20" ry="20" width="100%" height="220" />
                </ContentLoader>
              </div>
            ) : (
              teachers?.map(({
                id,
                photoUrl,
                fullName,
                firstName,
                lastName,
                secondName,
                rating,
                subjects,
                price,
                learningGoals,
                mostRecentEducation,
                introductionText,
                isFavorite,
              }) => (
                <div className={styles.teachersListItem} key={id}>
                  <TeacherCard
                    isLiked={isFavorite}
                    id={id}
                    photo={photoUrl}
                    name={fullName?.[i18n.language]}
                    firstName={firstName?.[i18n.language]}
                    lastName={lastName?.[i18n.language]}
                    secondName={secondName?.[i18n.language]}
                    rating={rating}
                    price={price}
                    subject={head(subjects)?.name[i18n.language]}
                    tags={
                      learningGoals.map((goal) => ({
                        id: goal.id,
                        name: goal.shortName[i18n.language]
                      }))
                    }
                    education={
                      mostRecentEducation
                        ? joinExisting([
                          mostRecentEducation.educationInstitution.shortName[i18n.language],
                          mostRecentEducation.speciality[i18n.language],
                          mostRecentEducation.educationLevel.shortName[i18n.language],
                        ], ', ')
                        : null
                    }
                    description={introductionText?.[i18n.language]}
                    onWrite={openTutorChat(id)}
                    onWriteLoading={isStartDialogLoading || isStartDialogFetching}
                    onSchedule={goToTeacherSchedule(id)}
                    onLike={makeFavorite(id)}
                    onDislike={removeFavorite(id)}
                  />
                </div>
              ))
            )
        }
      </div>
    </div>
  );
};

export default TeacherList;
