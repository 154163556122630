import React from 'react';

import styles from './BookScheduleConfirm.module.scss';
import { useBookingData } from '../../../store/slicers/booking';
import { useTranslation } from 'react-i18next';
import Title from '../../../components/Title';
import Text from '../../../components/Text';
import { getHourLabelById, intlFormatDate } from '../../../utils';
import Button from '../../../components/Button';
import { keys } from 'ramda';
import useTranslatableConstants from '../../../hooks/useTranslatableConstants';

const BookScheduleConfirm = ({
  onConfirm,
  onCancel,
  confirmLoading,
}) => {
  const [t] = useTranslation();
  const { weekDayNames } = useTranslatableConstants();

  const bookingData = useBookingData();

  const weekSchedule = bookingData.scheduleType === 1
    ? bookingData.lessonsSpan.reduce((acc, span) => {
      const { weekDayId } = span;
      if (acc[weekDayId]) {
        return { ...acc, [weekDayId]: [...acc[weekDayId], span] };
      }
      return { ...acc, [weekDayId]: [span] };
    }, {})
    : {};

  return (
    <div className={styles.booking}>
      {
        bookingData.scheduleType === 1 && (
          <div className={styles.bookingData}>
            <Text level={1} bold>
              {t('Вы бронируете недельное расписание')}
            </Text>

            {
              keys(weekSchedule).map((weekDayId) => (
                <div className={styles.bookingWeek} key={weekDayId}>
                  <Title level={4}>{weekDayNames[weekDayId]}</Title>
                  <ul className={styles.bookingList}>
                    {
                      weekSchedule[weekDayId].map((timeSpan) => (
                        <li key={timeSpan.id} className={styles.bookingListItem}>
                          {getHourLabelById(timeSpan.hourId)}
                        </li>
                      ))
                    }
                  </ul>
                </div>
              ))
            }
          </div>
        )
      }
      {
        bookingData.scheduleType === 2 && (
          <div className={styles.bookingData}>
            <Text level={1}>
              {t('Количество бронируемых уроков')}:
              {' '}
              <span style={{ fontWeight: 700 }}>
                {bookingData.lessonsTime.length}
              </span>
            </Text>

            <ul className={styles.bookingList}>
              {
                bookingData.lessonsTime.map(({ value }) => (
                  <li key={value} className={styles.bookingListItem}>
                    {intlFormatDate(value)}
                  </li>
                ))
              }
            </ul>
          </div>
        )
      }
      <div className={styles.bookingFooter}>
        <Button
          type="button"
          color="secondary"
          size="small"
          onClick={onCancel}
        >
          {t('Отменить')}
        </Button>
        <Button
          type="button"
          color="primary"
          size="small"
          onClick={onConfirm}
          loading={confirmLoading}
          className="Button_ScheduleConfirmation"
        >
          {t('Да, подтверждаю')}
        </Button>
      </div>
    </div>
  );
};

export default BookScheduleConfirm;
