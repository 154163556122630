import React, { useCallback, useEffect, useState } from 'react';

import styles from './ChatDialogs.module.scss';
import SearchInput from '../../../components/SearchInput';
import UserInfoSmall from '../../../components/UserInfoSmall';
import { useTranslation } from 'react-i18next';
import ContentLoader from 'react-content-loader';
import { formatTime } from '../../../utils';
import { useLazyGetChatDialogsQuery } from '../../../store/apis/chats';
import { useDispatch } from 'react-redux';
import {
  openChat,
  setBunchLastMessage,
  setInitialUnreadFileCounts,
  setInitialUnreadMessageCounts,
  useAllLastMessages,
  useChat,
  useChatAllUnreadFilesAndMessages
} from '../../../store/slicers/chat';
import EmptySimple from '../../../components/EmptySimple';
import cn from 'classnames';

const ChatDialogs = ({
  onDialogClick,
}) => {
  const [t, i18n] = useTranslation();
  const dispatch = useDispatch();
  const chat = useChat();
  const [
    getChatDialogs,
    { data: dialogs, isFetching: isDialogsFetching, isLoading: isDialogsLoading }
  ] = useLazyGetChatDialogsQuery();

  const [unreadMessages, unreadFiles] = useChatAllUnreadFilesAndMessages();
  const allLastMessages = useAllLastMessages();
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    getChatDialogs({ page: -1 })
      .unwrap()
      .then((dialogItems) => {
        const unreadMessagesByChatId = dialogItems.results.reduce((acc, { id, unreadTextMessagesCount }) => ({
          ...acc,
          [id]: unreadTextMessagesCount,
        }), {});
        const unreadFilesByChatId = dialogItems.results.reduce((acc, { id, unreadFileMessagesCount }) => ({
          ...acc,
          [id]: unreadFileMessagesCount,
        }), {});
        dispatch(setInitialUnreadMessageCounts(unreadMessagesByChatId));
        dispatch(setInitialUnreadFileCounts(unreadFilesByChatId));

        const lastMessages = dialogItems.results.reduce((acc, { id, lastMessage }) => ({
          ...acc,
          [id]: lastMessage,
        }), {});
        dispatch(setBunchLastMessage(lastMessages))
      })
      .catch((e) => {
        console.log(JSON.stringify(e.data ?? e, 0, 2));
      });
  }, [dispatch, getChatDialogs]);

  const handleClickDialog = useCallback((chatId) => () => {
    dispatch(openChat(chatId));
    onDialogClick(chatId);
  }, [dispatch, onDialogClick]);

  const dialogItemClasses = useCallback((isActive) => cn({
    [styles.dialogsItem]: true,
    [styles.dialogsItemActive]: isActive,
  }), []);

  return (
    <div className={styles.dialogs}>
      <div className={styles.dialogsSearch}>
        <SearchInput value={searchValue} onChange={setSearchValue} placeholder={t('Поиск')} />
      </div>
      {
        isDialogsLoading && (
          <ContentLoader
            backgroundColor={'#c8c8c8'}
            foregroundColor={'#eceaea'}
            width="100%"
            height="340"
            style={{ padding: '0 1em', boxSizing: 'border-box' }}
            speed={2}
          >
            <rect x="0" y="0" rx="6" ry="6" width="100%" height="48" />
            <rect x="0" y="68" rx="6" ry="6" width="100%" height="48" />
            <rect x="0" y="136" rx="6" ry="6" width="100%" height="48" />
            <rect x="0" y="204" rx="6" ry="6" width="100%" height="48" />
            <rect x="0" y="272" rx="6" ry="6" width="100%" height="48" />
          </ContentLoader>
        )
      }
      {
        !isDialogsLoading && (!dialogs || dialogs.results.length === 0) && (
          <EmptySimple
            title={t('У вас нет диалогов')}
            text={t('Вы можете написать учителю из каталога или Вашего расписания')}
            align="center"
          />
        )
      }
      {
        (dialogs && dialogs.results.length > 0)
          ? dialogs.results.map(({ id, user }) => (
            <div className={dialogItemClasses(id === chat.activeChatId)} onClick={handleClickDialog(id)} key={id}>
              <div className={styles.dialogsItemUser}>
                <UserInfoSmall
                  name={user.fullName?.[i18n.language]}
                  photoUrl={user.photoUrl}
                  message={allLastMessages[id]?.text}
                />
              </div>
              <div className={styles.dialogsItemMeta}>
                <div className={styles.dialogsItemTime}>
                  {formatTime(allLastMessages[id]?.sendTime)}
                </div>
                {
                  unreadMessages[id] || unreadFiles[id] ? (
                    <div className={styles.dialogsItemAlert}>
                      <span className={styles.dialogsItemAlertChip}>
                        {unreadMessages[id] + unreadFiles[id]}
                      </span>
                    </div>
                  ) : null
                }
              </div>
            </div>
          )) : null
      }
    </div>
  );
};

export default ChatDialogs;
