import React, { useCallback } from 'react';
import { NumberFormatBase, usePatternFormat } from 'react-number-format';
import TextInput from '../TextInput';
import { normalizeNumber } from '../../utils';
import { Controller, useFormState, get as getError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const PhoneFormat = React.forwardRef((props, ref) => {
  const _props = usePatternFormat(props);

  const removeFormatting = useCallback((val) => normalizeNumber(val), []);

  return (
    <NumberFormatBase
      {..._props}
      getInputRef={ref}
      removeFormatting={removeFormatting}
    />
  );
});

const PhoneInputController = ({
  name, control, inputFormat, mask = '_', ...rest
}) => {
  const [t] = useTranslation();
  const { errors } = useFormState({ control });
  const error = getError(errors, name);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, ...restField } }) => (
        <PhoneFormat
          {...rest}
          {...restField}
          onValueChange={({ value }) => {
            onChange(value);
          }}
          error={t(error?.message)}
          valueIsNumericString
          format={inputFormat}
          customInput={TextInput}
          mask={mask}
        />
      )}
    />
  );
};

export default PhoneInputController;
