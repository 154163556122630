import React, { useMemo } from 'react';
import cn from 'classnames';

import styles from './TutorReviewItem.module.scss';
import Title from '../Title';
import { Rating } from '@smastrom/react-rating';
import RatingInput from '../RatingInput';
import { head, map, split } from 'ramda';

const TutorReviewItem = ({
  userPhoto,
  userName,
  points,
  comment,
  date,
}) => {

  const userInitials = useMemo(() => userName ? map((nameItem) => head(nameItem), split(' ', userName)) : ['🫠', null], [userName]);

  return (
    <div className={styles.review}>
      <div className={styles.reviewHeader}>
        <div className={styles.reviewUser}>
          <div className={styles.reviewUserPhoto}>
            {
              userPhoto
                ? <img src={userPhoto} alt=""/>
                : <div className={styles.reviewUserPhotoPlaceholder}>{userInitials[0]} {userInitials[1]}</div>
            }
          </div>
          <div className={styles.reviewUserName}>
            <Title noMargin level={5} bold>{userName}</Title>
            <div className={styles.reviewUserRate}>
              <RatingInput value={points} readOnly />
            </div>
          </div>
        </div>
        <div className={styles.reviewDate}>
          {date}
        </div>
      </div>

      <div className={styles.reviewBody}>
        {comment}
      </div>
    </div>
  );
};

export default TutorReviewItem;
