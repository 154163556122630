import apiRoutes from '../../apiRoutes';
import { mainApi } from './index';
import { invalidateOn } from '../utils';

mainApi.injectEndpoints({
  endpoints: (build) => ({
    getSocialLinks: build.query({
      query: (params) => ({
        url: apiRoutes.socialLinks(),
        params,
      }),
    }),
    getQuoteOfTheDay: build.query({
      query: (params) => ({
        url: apiRoutes.quoteOfTheDay(),
        params,
      }),
    }),
  }),
})

export const {
  useGetSocialLinksQuery,
  useGetQuoteOfTheDayQuery,
} = mainApi;
