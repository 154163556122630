// import cn from 'classnames';

import styles from './RoomControls.module.scss';
import { FiPhoneOff } from 'react-icons/fi';
import React from 'react';
import useMedia from '../../../rtc/hooks/useMedia';
import { useNavigate } from 'react-router-dom';
import appRoutes from '../../../app/appRoutes';

const StopCallButton = () => {
  const MediaDevice = useMedia();
  const navigate = useNavigate();

  const stop = () => {
    MediaDevice.stop();
    navigate(appRoutes.schedule(), { replace: true });
  };

  return (
    <button
      className={styles.controlsButton}
      style={{ color: 'red' }}
      onClick={stop}
    >
      <FiPhoneOff/>
    </button>
  );
};

export default StopCallButton;
