import React, { useCallback } from 'react';
import cn from 'classnames';

import styles from './Favorite.module.scss';
import { useTranslation } from 'react-i18next';
import { useGetFavoriteTutorsQuery } from '../../../store/apis/favorites';
import Title from '../../../components/Title';
import Container from '../../../components/Container';
import TeacherList from '../../../containers/tutorList/TeacherList';
import Pagination from '../../../components/Pagination';
import useQueryString from '../../../hooks/useQueryString';
import { useNavigate } from 'react-router-dom';
import queryString from 'query-string';

const Favorite = () => {
  const [t] = useTranslation();
  const { page = 1, pageSize = 20, ...query } = useQueryString();
  const navigate = useNavigate();

  const {
    data: favoriteTutors,
    isLoading: isFavoriteTutorsLoading,
    isFetching: isFavoriteTutorsFetching,
  } = useGetFavoriteTutorsQuery({ page, pageSize, ...query });

  const handlePageChange = useCallback((newPage) => {
    navigate({
      pathname: '',
      search: queryString.stringify({ ...query, page: newPage }),
    });
  }, [navigate, query]);

  return (
    <Container>
      <Title level={3}>{t('Ваши избранные учителя')}</Title>
      <section className={styles.bodyContent}>
        <TeacherList
          loading={isFavoriteTutorsLoading || isFavoriteTutorsFetching}
          teachers={favoriteTutors?.results}
        />
        <div className={styles.bodyPagination}>
          <Pagination
            count={favoriteTutors?.totalPages}
            currentPage={page}
            onPageChange={handlePageChange}
          />
        </div>
      </section>
    </Container>
  );
};

export default Favorite;
