import React from 'react';
// import cn from 'classnames';

import ConfirmationPhoneInput from './ConfirmationPhoneInput';
import { Controller, useFormState } from 'react-hook-form';
import { get as getError } from 'react-hook-form';

const ConfirmationPhoneInputController = ({
  name,
  control,
}) => {
  const { errors } = useFormState({ control });
  const error = getError(errors, name);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({ field }) => (
        <ConfirmationPhoneInput
          {...field}
          error={error}
          ref={null}
        />
      )}
    />
  );
};

export default ConfirmationPhoneInputController;
