import React, { useMemo } from 'react';
import cn from 'classnames';

import styles from './TextareaInput.module.scss';

const TextareaInput = ({
  disabled,
  id,
  error,
  label,
  rows = 4,
  variant = 'primary',
  ...rest
}, ref) => {
  const inputClasses = useMemo(() => cn({
    [styles.inputField]: true,
    [styles.inputFieldError]: error,
    [styles[variant]]: true,
  }), [error, variant]);

  const labelClasses = useMemo(() => cn({
    [styles.inputLabel]: true,
    [styles.inputLabelError]: error,
  }), [error]);

  return (
    <div className={styles.input}>
      {label && (<label htmlFor={id} className={labelClasses}>{label}</label>)}
      <div className={inputClasses}>
        <textarea
          {...rest}
          ref={ref}
          className={cn(styles.inputFieldInput, styles[variant])}
          disabled={disabled}
          id={id}
          rows={rows}
        />
      </div>
      {error && (<span className={styles.inputError}>{error}</span>)}
    </div>
  );
};

export default React.forwardRef(TextareaInput);
