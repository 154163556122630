import React, { useMemo } from 'react';
import cn from 'classnames';

import styles from './Divider.module.scss';

const Divider = ({
  mt = 16,
  mb = 16,
  className = '',
}) => {
  const dividerClasses = useMemo(() => cn({
    [styles.divider]: true,
    [className]: className,
  }))
  return (
    <div
      className={dividerClasses}
      style={{ marginTop: mt, marginBottom: mb }}
    />
  );
};

export default Divider;
