import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';

const initialState = {
  timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
};

export const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    changeTimezone: (state, { payload }) => {
      return {
        ...state,
        timezone: payload,
      }
    },
  },
});

export const useUserTimezone = () => {
  const userTimezone = useSelector((state) => state.user.timezone);
  return {
    timezone: userTimezone,
    hourShift: format(new Date(), 'OOOO'),
  }
};

export const {
  changeTimezone,
} = user.actions;
