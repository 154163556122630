import { object, setLocale } from 'yup';
import i18n from './localizations/i18n';

setLocale({
  mixed: {
    required: i18n.t('Обязательное поле'),
    oneOf: i18n.t('Данные полей должны совпадать'),
  },
  string: {
    min: (v) => i18n.t('Минимальная длина поля {{min}}', { min: v.min }),
    length: (v) => i18n.t('Длина поля должна быть {{length}}', { length: v.length }),
    email: i18n.t('Неверный формат электронной почты'),
  }
});

export const createDataSchema = (obj) => {
  return object(obj);
};
