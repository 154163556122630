// import cn from 'classnames';

import styles from './ComplexTextInput.module.scss';
import React, { useMemo } from 'react';
import cn from 'classnames';

const ComplexTextInput = ({
  name, label, error, disabled, placeholder, ...rest
}) => {
  const selectClasses = useMemo(() => cn({
    [styles.input]: true,
    [styles.inputDisabled]: disabled,
    [styles.inputError]: error,
  }), [error, disabled]);

  return (
    <div className={selectClasses}>
      <label htmlFor={`complex-text-input-${name}`}>{error || label}</label>
      <input
        type="text"
        id={`complex-text-input-${name}`}
        className={styles.inputField}
        placeholder={placeholder}
        {...rest}
      />
    </div>
  );
};

export default ComplexTextInput;
