import React from 'react';
import cn from 'classnames';

import styles from './ContentEmpty.module.scss';
import Title from '../Title';
import Text from '../Text';
import Button from '../Button';

const ContentEmpty = ({
  imgSrc,
  title,
  subtitle,
  actionText,
  onAction,
  btnClasses,
}) => {

  return (
    <div className={styles.empty}>
      <div className={styles.emptyWrapper}>
        <img src={imgSrc} alt="" className={styles.emptyImage} />
        <Title level={4}>{title}</Title>
        <Text color="secondary" level={3}>{subtitle}</Text>
        {
          onAction && (
            <Button
              type="button"
              fullWidth
              onClick={onAction}
              className={btnClasses}
            >
              {actionText}
            </Button>
          )
        }
      </div>
    </div>
  );
};

export default ContentEmpty;
