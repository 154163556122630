import apiRoutes from '../../../apiRoutes';
import { mainApi } from '../index';

mainApi.injectEndpoints({
  endpoints: (build) => ({
    getTutorReview: build.query({
      query: (params) => ({
        url: apiRoutes.tutorReviews(),
        params, // tutorId, studentId, scoreFrom, scoreTo, sort (-score), page, pageSize
      }),
    }),
    postTutorReview: build.mutation({
      query: (body) => ({
        url: apiRoutes.createTutorReview(),
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useGetTutorReviewQuery,
  usePostTutorReviewMutation,
} = mainApi;
