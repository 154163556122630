import React from 'react';
import { Controller, useFormState, get as getError } from 'react-hook-form';
import DateInput from './DateInput';
import { useTranslation } from 'react-i18next';

const DateInputController = ({
  control,
  name,
  withTime,
  ...rest
}) => {
  const [t, i18n] = useTranslation();
  const { errors } = useFormState({ control });

  const error = getError(errors, name);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <DateInput
          {...field}
          withTime={withTime}
          todayButton={t('Сегодня')}
          timeInputLabel={t('Время')}
          locale={i18n.language}
          error={error?.message}
          {...rest}
        />
      )}
    />
  );
};

export default DateInputController;
