import React from 'react';
import useQueryString from '../../hooks/useQueryString';
import { Navigate } from 'react-router';
import appRoutes from '../../app/appRoutes';
import RoomContent from '../../contents/room/RoomContent';
import usePrepareRoom from '../../rtc/hooks/usePrepareRoom';


const Room = () => {
  const { roomId, tutorId } = useQueryString();

  usePrepareRoom();

  if (!roomId) return <Navigate to={appRoutes.schedule()} replace />
  return (
    <RoomContent />
  );
};

export default Room;
