import { compose, filter, head, identity, join, pipe, prop, reverse, sortBy, times, toLower } from 'ramda';
import {
  differenceInDays, differenceInHours, differenceInMinutes,
  differenceInMonths,
  differenceInQuarters, differenceInSeconds,
  differenceInYears,
  format, formatDistance,
  formatISO,
  intlFormat,
  intlFormatDistance,
  isValid,
  parseISO
} from 'date-fns';
import i18n, { dateLocales } from './localizations/i18n';
import { useMemo } from 'react';
import { ErrorCode } from 'react-dropzone';

export const joinExisting = (arr, separator = '/') => pipe(
  filter(identity),
  join(separator),
)(arr);

const formatter = new Intl.NumberFormat('ru-RU');
export const formatNumber = (number) => {
  if (number === 0) return String(number);
  if (!number || Number.isNaN(number)) {
    return null;
  }
  if (Number.isNaN(Number(number))) return number;

  return formatter.format(number);
};

export const normalizeNumber = (num) => {
  if (typeof num === 'number') return num;
  return num && num.replace(/[^0-9]/g, '');
};

export const dateToISO = (date, withTime) => {
  if (!date) return null;
  if (withTime) return formatISO(date);
  return format(date, 'yyyy-MM-dd');
};

export const dictionaryToOptions = (dicts) => {
  if (!dicts || !dicts.results) return [];
  return dicts.results.map((dict) => ({
    value: dict.id,
    label: dict.name[i18n.language],
  }))
};

export const sortByField = (fieldName, arr) => {
  return sortBy(compose(toLower, prop(fieldName)))(arr);
};

export const formatDate = (date, withTime) => {
  if (!date) return null;
  const dateObj = date instanceof Date ? date : parseISO(date);
  if (!isValid(dateObj)) return null;
  return withTime
    ? format(dateObj, 'dd.MM.yyyy (HH:mm)')
    : format(dateObj, 'dd.MM.yyyy');
};

export const formatTime = (date) => {
  if (!date) return null;
  const dateObj = date instanceof Date ? date : parseISO(date);
  if (!isValid(dateObj)) return null;
  return format(dateObj, 'HH:mm');
};

export const intlFormatDate = (date, options = {}) => {
  if (!date) return null;
  const dateObj = date instanceof Date ? date : parseISO(date);

  if (!isValid(dateObj)) return null;
  return format(
    dateObj,
    'dd MMMM yyyy, HH:mm',
    { locale: dateLocales[i18n.language] });
}

export const customIntlFormatDistance = (start, end) => {

  const opts = {
    locale: dateLocales[i18n.language],
    addSuffix: true,
  };
  const yearsDifference = differenceInYears(start, end);
  if (yearsDifference >= 1) {
    opts.unit = 'year';
    return formatDistance(end, start, opts);
  }
  const quartersDifference = differenceInQuarters(start, end);
  if (quartersDifference >= 1) {
    opts.unit = 'month';
    return formatDistance(end, start, opts);
  }
  const monthsDifference = differenceInMonths(start, end);
  if (monthsDifference >= 1) {
    opts.unit = 'month';
    return formatDistance(end, start, opts);
  }
  const daysDifference = differenceInDays(start, end);
  if (daysDifference >= 1) {
    opts.unit = 'day';
    return formatDistance(end, start, opts);
  }
  const hoursDifference = differenceInHours(start, end);
  if (hoursDifference >= 1) {
    opts.unit = 'hour';
    return formatDistance(end, start, opts);
  }
  const minutesDifference = differenceInMinutes(start, end);
  if (minutesDifference >= 1) {
    opts.unit = 'minute';
    return formatDistance(end, start, opts);
  }
  const secondsDifference = differenceInSeconds(start, end);
  if (secondsDifference >= 1) {
    opts.unit = 'second';
    return formatDistance(end, start, opts);
  }

  return formatDistance(end, start, opts);
}

export const sleep = (ms) => new Promise(r => setTimeout(r, ms));

export const getFileDropError = (code) => {
  const fileDropErrorMessages = {
    [ErrorCode.FileTooLarge]: i18n.t('Размер файла превышает допустимое значение'),
    [ErrorCode.FileInvalidType]: i18n.t('Нельзя выбрать данный тип файла'),
    [ErrorCode.TooManyFiles]: i18n.t('Нужно выбрать только один файл'),
    [ErrorCode.FileTooSmall]: i18n.t('Размер файла слишком мал'),
  };

  return fileDropErrorMessages[code];
};

export const bytesToMegabytes = (amount) => {
  const parseAmount = Number(amount);
  if (Number.isNaN(parseAmount)) return null;
  const mbAmount = amount / 1048576;
  return Math.round(mbAmount * 10) / 10;
};

export const setErrorsToFields = (erroredResponse, setError) => {

  if (erroredResponse.data && erroredResponse.data.type === 'form') {
    erroredResponse.data.details.forEach((err) => {
      setError(err.name, { type: 'manual', message: head(err.errors) });
    });
  }
};

export const getHeadIfExists = (value) => value ? head(value) : null;

export const reverseIfExists = (value) => value ? reverse(value) : null;

export const getCorrectYearLabel = (value) => {
  const year = Number(value);
  const lastNum = year % 10;
  const lastTwoNums = year % 100;

  if (lastTwoNums >= 11 && lastTwoNums <= 14) {
    return i18n.t('{{year}} лет', { year: value });
  }

  switch(lastNum) {
    case 1: return i18n.t('{{year}} год', { year: value });
    case 2:
    case 3:
    case 4: return i18n.t('{{year}} года', { year: value });
    default: return i18n.t('{{year}} лет', { year: value });
  }
};

export const getHourLabelById = (hourId) => {
  const hoursDict = times((idx) => idx < 10 ? `0${idx}:00` : `${idx}:00`, 24);
  return hoursDict[hourId];
};

export const downloadFile = (url) => {
  const link = document.createElement('a');
  link.href = url;
  link.download = 'download';
  link.target = '_blank';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const formatSecondsToTimer = (seconds) => {
  const restSeconds = seconds % 60;
  const minutes = (seconds / 60).toFixed(0);
  const secondsStr = restSeconds >= 10 ? restSeconds : `0${restSeconds}`;
  const minutesStr = minutes >= 10 ? minutes : `0${minutes}`;

  return `${minutesStr}:${secondsStr}`;
};

export const generateUUID = () => {
  // Get current time in milliseconds
  let d = new Date().getTime();

  // Define the UUID template with placeholder characters
  let uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx";

  // Replace the placeholders with random hexadecimal digits
  uuid = uuid.replace(/[xy]/g, function(c) {
    // Generate a random number between 0 and 15
    let r = (d + Math.random()*16)%16 | 0;

    // Update value of d for the next placeholder
    d = Math.floor(d/16);

    // Convert the number to a hexadecimal digit and return it
    return (c == "x" ? r : (r&0x3|0x8)).toString(16);
  });

  return uuid;
}
