import React, { useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import router from './app/browserRouter';
import { ToastContainer } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import GoogleTranslateWarningModal from './containers/shared/GoogleTranslateWarningModal';

function App() {

  return (
    <>
      <RouterProvider router={router} />
      <ToastContainer
        position="bottom-right"
      />
      <GoogleTranslateWarningModal />
    </>
  );
}

export default App;
