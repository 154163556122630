import React from 'react';
import cn from 'classnames';
import Select, { components } from 'react-select';
import styles from './SimpleSelect.module.scss';
import { FiGlobe } from 'react-icons/fi';

const SimpleSelect = ({
  options,
  selected,
  defaultSelected,
  onChange,
}) => {
  return (
    <Select
      instanceId="superSelect"
      styles={{
        control: (styles) => ({
          ...styles,
          border: 'none',
          boxShadow: 'none',
          width: '100%',
          backgroundColor: 'transparent',
        }),
        indicatorSeparator: () => ({ display: 'none' }),
        menu: (styles) => ({
          ...styles,
          boxShadow: '2px 0px 6px 5px rgba(235,228,228,0.75)',
          minWidth: 200,
          borderRadius: 12,
          overflow: 'hidden',
          left: '-180%',
        }),
        singleValue: (styles) => ({
          ...styles,
          fontSize: 18,
          cursor: 'pointer',
        }),
        option: (styles, state) => ({
          ...styles,
          backgroundColor: state.isSelected ? '#536DFE' : 'none',
        }),
        dropdownIndicator: (styles) => ({ ...styles, display: 'none' })
      }}
      components={{
        SingleValue: (props) => (
          <components.SingleValue {...props}>
            <FiGlobe />
          </components.SingleValue>
        )
      }}
      onChange={onChange}
      isSearchable={false}
      defaultValue={defaultSelected}
      value={selected}
      options={options}
    />
  );
};

export default SimpleSelect;
