import React, { useCallback, useState } from 'react';
import cn from 'classnames';

import styles from './CalendarSwiperDays.module.scss';
import Text from '../Text';
import { format, formatISO, getDate, isSameDay } from 'date-fns';
import { dateLocales } from '../../localizations/i18n';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Scrollbar, Mousewheel, FreeMode } from 'swiper';
import { useTranslation } from 'react-i18next';

const CalendarSwiperDays = ({
  days = [],
  onChange,
  value,
}) => {
  const [, i18n] = useTranslation();
  const [activeSlideDate, setActiveSlideDate] = useState(value || new Date());

  const selectDay = useCallback((date) => () => {
    onChange(date);
  }, [onChange]);

  const scheduleDaysItemClasses = useCallback((isActive) => cn({
    [styles.scheduleDaysItem]: true,
    [styles.scheduleDaysItemActive]: isActive,
  }), []);

  const handleSlideChange = useCallback(({ activeIndex }) => {
    setActiveSlideDate(days[activeIndex]);
  }, [days]);

  return (
    <div>
      <Text bold level={3}>
        <span style={{ textTransform: 'capitalize' }}>
          {format(activeSlideDate, 'LLLL yyyy', { locale: dateLocales[i18n.language] })}
        </span>
      </Text>

      <div>
        <Swiper
          modules={[FreeMode, Mousewheel]}
          freeMode
          mousewheel={{
            releaseOnEdges: true,
            forceToAxis: true,
            sensitivity: 1,
          }}
          spaceBetween={8}
          slidesPerView="auto"
          onSlideChange={handleSlideChange}
        >
          {
            days.map((date) => (
              <SwiperSlide style={{ width: 64 }} key={formatISO(date)} onClick={selectDay(date)}>
                <div className={scheduleDaysItemClasses(isSameDay(date, value))}>
                  <div className={styles.scheduleDaysDay}>{getDate(date)}</div>
                  <div className={styles.scheduleDaysWeekName}>
                    {format(date, i18n.language === 'en' ? 'E' : 'EEEEEE', { locale: dateLocales[i18n.language] })}
                  </div>
                </div>
              </SwiperSlide>
            ))
          }
        </Swiper>
      </div>
    </div>
  );
};

export default CalendarSwiperDays;
