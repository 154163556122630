import React, { useCallback, useMemo, useState } from 'react';
import cn from 'classnames';

import styles from './Balance.module.scss';
import TabsButton from '../../../components/TabsButton';
import { useTranslation } from 'react-i18next';
import { FiCreditCard, FiDollarSign, FiFileText, FiRotateCcw } from 'react-icons/fi';
import Title from '../../../components/Title';
import Text from '../../../components/Text';
import BalancesByTutor from '../../../containers/balance/BalancesByTutor';
import Condition from '../../../components/Condition';
import Container from '../../../components/Container';
import BalanceDebit from '../../../containers/balance/BalanceDebit';
import { useNavigate } from 'react-router-dom';
import { useGetFreeBalanceQuery, useGetPackageListQuery } from '../../../store/apis/tariffs';
import { formatNumber } from '../../../utils';
import ContentLoader from 'react-content-loader';
import UnpaidInvoices from '../../../containers/balance/UnpaidInvoices';
import PackagesHistory from '../../../containers/balance/PackagesHistory';
import useQueryString from '../../../hooks/useQueryString';

const Balance = () => {
  const [t] = useTranslation();
  // const [currentTab, setCurrentTab] = useState('balance');
  const navigate = useNavigate();
  const { tab = 'balance' } = useQueryString();

  const {
    data: packages,
    isLoading,
    isFetching
  } = useGetPackageListQuery();

  const {
    data: freeBalance,
    isLoading: isFreeBalanceLoading,
    isFetching: isFreeBalanceFetching,
  } = useGetFreeBalanceQuery();

  const tabs = useMemo(() => [
    {
      id: 'balance',
      label: t('Активные пакеты'),
      additionalLabel: null,
      icon: <FiCreditCard />,
    },
    {
      id: 'transactions',
      label: t('История списаний'),
      additionalLabel: null,
      icon: <FiRotateCcw />,
    },
    {
      id: 'packagesHistory',
      label: t('История пополнений'),
      additionalLabel: null,
      icon: <FiDollarSign />,
    },
    {
      id: 'unpaidInvoices',
      label: t('Неоплаченные счета'),
      additionalLabel: null,
      icon: <FiFileText />,
    },
  ], [t]);

  const handleChangeTab = useCallback((tab) => {
    // setCurrentTab(tab);
    navigate({
      pathname: '',
      search: `?tab=${tab}`,
    })
  }, [navigate]);

  const { lessonCount, balance } = packages && packages.results.length > 0
    ? packages.results.reduce((acc, pack) => {
      return {
        ...acc,
        lessonCount: acc.lessonCount + pack.lessonCount,
        balance: acc.balance + pack.balance,
      }
    }, { lessonCount: 0, balance: 0 })
    : { lessonCount: 0, balance: 0 }

  return (
    <Container>
      <div className={styles.balance}>
        <div className={styles.balanceHeader}>
          {
            (isLoading || isFetching)
              ? (
                <ContentLoader
                  backgroundColor={'#c8c8c8'}
                  foregroundColor={'#eceaea'}
                  width="360"
                  height="28"
                  style={{ marginBottom: '.7em' }}
                  speed={2}
                >
                  <rect x="0" y="0" rx="6" ry="6" width="100%" height="100%" />
                </ContentLoader>
              ) : (
                <div className={styles.balanceAmounts}>
                  <div className={styles.balanceAmountsItem}>
                    <Text level={3} color="secondary" noPadding>{t('Баланс по учителям')}</Text>
                    <Title level={4}>
                      {t('{{balance}} сум', { balance: formatNumber(balance) })}
                      {' / '}
                      {t('{{lessonCount}} занятий', { count: lessonCount })}
                    </Title>
                  </div>
                  <div className={styles.balanceAmountsItem}>
                    <Text level={3} color="secondary" noPadding>{t('На счету')}</Text>
                    <Title level={4}>
                      {t('{{balance}} сум', { balance: formatNumber(freeBalance?.amount) })}
                    </Title>
                  </div>
                </div>
              )
          }
          <TabsButton
            tabs={tabs}
            currentTab={tab}
            onTabClick={handleChangeTab}
          />
        </div>
        <div className={styles.balanceBody}>
          <Condition when={tab} is="balance">
            <BalancesByTutor />
          </Condition>
          <Condition when={tab} is="transactions">
            <BalanceDebit />
          </Condition>
          <Condition when={tab} is="packagesHistory">
            <PackagesHistory />
          </Condition>
          <Condition when={tab} is="unpaidInvoices">
            <UnpaidInvoices />
          </Condition>
        </div>
      </div>
    </Container>
  );
};

export default Balance;
