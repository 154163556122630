import React, { useCallback } from 'react';

import styles from './RecoverPasswordForm.module.scss';
import Title from '../../../components/Title';
import { useLocation, useNavigate } from 'react-router-dom';
import appRoutes from '../../../app/appRoutes';
import PhoneInputController from '../../../components/PhoneInput';
import Button from '../../../components/Button';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Navigate } from 'react-router';
import PasswordInputController from '../../../components/PasswordInput/PassswordInputController';
import { createDataSchema } from '../../../yup';
import { ref, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useRecoverPasswordMutation } from '../../../store/apis/auth';
import { toast } from 'react-toastify';
import useQueryString from '../../../hooks/useQueryString';
import queryString from 'query-string';

const schema = createDataSchema({
  code: string().length(6).required(),
  newPassword: string().required(),
  passwordConfirmation: string().oneOf([ref('newPassword'), null]),
});

const RecoverPasswordForm = () => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const query = useQueryString();
  const { state } = useLocation();

  const [
    recoverPassword,
    {
      isLoading,
      isFetching,
    }
  ] = useRecoverPasswordMutation();

  const {
    control,
    handleSubmit: submit,
  } = useForm({
    defaultValues: {
      phoneNumber: state.phoneNumber,
    },
    resolver: yupResolver(schema),
  });

  const handleSubmit = useCallback(async (values) => {
    try {
      await recoverPassword(values).unwrap();
      toast.success(t('Ваш пароль был изменен'));
      navigate({
        pathname: appRoutes.login(),
        search: queryString.stringify(query),
      }, { replace: true });
    } catch (e) {
      console.log(JSON.stringify(e.data ?? e, 0, 2));
    }
  }, [navigate, recoverPassword, t]);

  if (!state) return <Navigate to={appRoutes.recoverPassword()} replace/>;

  return (
    <form className={styles.form} onSubmit={submit(handleSubmit)}>
      <div className={styles.formHeader}>
        <Title level={3}>{t('Новый пароль')}</Title>
      </div>
      <div className={styles.formBody}>
        <PhoneInputController
          name="code"
          control={control}
          label={t('Подтверждающий код')}
          id="code"
          inputFormat="### ###"
          placeholder="123 456"
          mask=" "
        />
        <PasswordInputController
          name="newPassword"
          control={control}
          label={t('Пароль')}
          placeholder={t('Введите пароль')}
        />
        <PasswordInputController
          name="passwordConfirmation"
          control={control}
          label={t('Повторите пароль')}
          placeholder={t('Введите пароль еще раз')}
        />

        <Button type="submit" fullWidth loading={isLoading || isFetching} className="Button_button__JBBzO_NewParole">
          {t('Сохранить')}
        </Button>
      </div>
    </form>
  );
};

export default RecoverPasswordForm;
