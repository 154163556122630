import React, { useState } from 'react';
import cn from 'classnames';

import styles from './TutorReviewsList.module.scss';
import Title from '../../../components/Title';
import { useTranslation } from 'react-i18next';
import { FaStar } from 'react-icons/fa';
import TutorReviewItem from '../../../components/TutorReviewItem';
import { useParams } from 'react-router';
import { useGetTutorReviewQuery } from '../../../store/apis/tutors/tutorReviews';
import useQueryString from '../../../hooks/useQueryString';
import ContentEmpty from '../../../components/ContentEmpty';
import { intlFormatDistance, parseISO } from 'date-fns';
import EmptySimple from '../../../components/EmptySimple';
import ContentLoader from 'react-content-loader';
import { customIntlFormatDistance } from '../../../utils';

const TutorReviewsList = ({
  rating,
  reviewCount,
}) => {
  const [t, i18n] = useTranslation();
  const { id } = useParams();
  const [currentPage, setCurrentPage] = useState(1);

  const { data: review, isLoading, isFetching } = useGetTutorReviewQuery({ tutorId: id, page: currentPage, pageSize: 20 });

  return (
    <div className={styles.reviews}>
      <Title level={4}>{t('Отзывы')}</Title>
      <div className={styles.reviewsHeader}>
        <span className={styles.reviewsHeaderStar}><FaStar /></span>
        <span className={styles.reviewsHeaderPoints}>{rating || 0}</span>
        <span className={styles.reviewsHeaderDivider} />
        <span className={styles.reviewsHeaderCounts}>{reviewCount || 0}</span>
        <span className={styles.reviewsHeaderLabel}>{t('оценок')}</span>
      </div>
      <div className={styles.reviewsBody}>
        {
          (isLoading || isFetching) && (
            [1, 2, 3].map((id) => (
              <ContentLoader
                key={id}
                backgroundColor={'#c8c8c8'}
                foregroundColor={'#eceaea'}
                width="100%"
                height="140"
                speed={2}
              >
                <rect x="0" y="0" rx="12" ry="12" width="7%" height="60px" />
                <rect x="9%" y="10" rx="6" ry="6" width="91%" height="16" />
                <rect x="9%" y="35" rx="6" ry="6" width="50%" height="16" />
                <rect x="0" y="70" rx="6" ry="6" width="100%" height="48" />
              </ContentLoader>
            ))
          )
        }
        {
          (review && review.results.length > 0)
            ? (
              review.results.map((reviewItem) => (
                <TutorReviewItem
                  key={reviewItem.id}
                  userPhoto={reviewItem.student.photoUrl}
                  userName={reviewItem.student.fullName?.[i18n.language]}
                  points={parseFloat(reviewItem.score)}
                  date={customIntlFormatDistance(new Date(), parseISO(reviewItem.createdTime))}
                  comment={reviewItem.comment}
                />
              ))
            ) : (
              <EmptySimple
                title={t('У преподавателя пока нет отзывов')}
                text={null}
                align="center"
              />
            )
        }
      </div>
    </div>
  );
};

export default TutorReviewsList;
