import React from 'react';
import PasswordInput from './PasswordInput';
import { Controller, useFormState } from 'react-hook-form';
import { get as getError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const PasswordInputController = ({
  control,
  name,
  ...rest
}) => {
  const [t] = useTranslation();
  const { errors } = useFormState({ control });
  const error = getError(errors, name);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({ field }) => (
        <PasswordInput {...field} {...rest} error={t(error?.message)} />
      )}
    />
  )
};

export default PasswordInputController;
