import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

const initialState = {
  activeChatId: null,
  activeTabId: 1,
  messages: {},
  files: {},
  initializedTextChats: [],
  initializedFileChats: [],
  // { [chatId]: 0 }
  unreadMessageCounts: {},
  unreadFileCounts: {},
  lastMessages: {},
  isDialogsInitialized: false,
};

export const chat = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    openChat: (state, { payload: chatId }) => {
      state.activeChatId = chatId;
    },
    setActiveChatTab: (state, { payload: tabId }) => {
      state.activeTabId = tabId;
    },
    closeChat: (state) => {
      state.activeChatId = null;
    },

    setInitMessages: (state, { payload: { chatId, messages } }) => {
      state.messages[chatId] = messages;
      state.initializedTextChats.push(chatId);
    },
    addNewMessage: (state, { payload: { chatId, message } }) => {
      if (!state.messages[chatId]) {
        state.messages[chatId] = [message];
      } else {
        state.messages[chatId].push(message);
      }
    },
    initializeDialogs: (state) => {
      state.isDialogsInitialized = true;
    },
    unInitializeDialogs: (state) => {
      state.isDialogsInitialized = false;
    },

    setInitFiles: (state, { payload: { chatId, files } }) => {
      state.files[chatId] = files;
      state.initializedFileChats.push(chatId);
    },
    addNewFile: (state, { payload: { chatId, file } }) => {
      if (!state.files[chatId]) {
        state.files[chatId] = [file];
      } else {
        state.files[chatId].push(file);
      }
    },

    incrementUnreadMessage: (state, { payload: chatId }) => {
      if (state.unreadMessageCounts[chatId]) {
        state.unreadMessageCounts[chatId] = state.unreadMessageCounts[chatId] + 1;
      } else {
        state.unreadMessageCounts[chatId] = 1;
      }
    },
    incrementUnreadFile: (state, { payload: chatId }) => {
      if (state.unreadFileCounts[chatId]) {
        state.unreadFileCounts[chatId] = state.unreadFileCounts[chatId] + 1;
      } else {
        state.unreadFileCounts[chatId] = 1;
      }
    },
    resetUnreadMessages: (state, { payload: chatId }) => {
      state.unreadMessageCounts[chatId] = 0;
    },
    resetUnreadFiles: (state, { payload: chatId }) => {
      state.unreadFileCounts[chatId] = 0;
    },

    setUnreadMessageCounts: (state, { payload: { chatId, count } }) => {
      state.unreadMessageCounts[chatId] = count;
    },
    setUnreadFileCounts: (state, { payload: { chatId, count } }) => {
      state.unreadFileCounts[chatId] = count;
    },
    setInitialUnreadMessageCounts: (state, { payload: data }) => {
      state.unreadMessageCounts = data;
    },
    setInitialUnreadFileCounts: (state, { payload: data }) => {
      state.unreadFileCounts = data;
    },

    setLastMessage: (state, { payload: { chatId, message } }) => {
      state.lastMessages[chatId] = message;
    },
    setBunchLastMessage: (state, { payload: messages }) => {
      state.lastMessages = { ...state.lastMessages, ...messages };
    },
  },
});

export const useChat = () => {
  return useSelector((state) => state.chat);
};

export const useChatMessages = (chatId) => {
  return useSelector((state) => state.chat.messages[chatId]);
};

export const useChatFiles = (chatId) => {
  return useSelector((state) => state.chat.files[chatId]);
};

export const useLastMessage = (chatId) => {
  return useSelector((state) => state.chat.lastMessages[chatId]);
};

export const useAllLastMessages = () => {
  return useSelector((state) => state.chat.lastMessages);
};
export const useChatUnreadFilesAndMessages = (chatId) => {
  const messageUnreadCount = useSelector((state) => state.chat.unreadMessageCounts[chatId]);
  const fileUnreadCount = useSelector((state) => state.chat.unreadFileCounts[chatId]);
  return [messageUnreadCount, fileUnreadCount];
};

export const useChatAllUnreadFilesAndMessages = () => {
  const messageUnread = useSelector((state) => state.chat.unreadMessageCounts);
  const fileUnread = useSelector((state) => state.chat.unreadFileCounts);
  return [messageUnread, fileUnread];
};

export const {
  openChat,
  closeChat,
  setActiveChatTab,
  setInitMessages,
  addNewMessage,

  setInitFiles,
  addNewFile,

  setUnreadMessageCounts,
  setUnreadFileCounts,
  resetUnreadMessages,
  resetUnreadFiles,
  setLastMessage,
  setBunchLastMessage,
  incrementUnreadMessage,
  incrementUnreadFile,
  setInitialUnreadMessageCounts,
  setInitialUnreadFileCounts,
} = chat.actions;
