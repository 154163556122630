// import cn from 'classnames';

import styles from './RoomControls.module.scss';
import { FiVideo, FiVideoOff } from 'react-icons/fi';
import React from 'react';
import { useMediaState } from '../../../store/slicers/room';
import useMedia from '../../../rtc/hooks/useMedia';

const VideoButton = () => {
  const mediaState = useMediaState();
  const MediaDevice = useMedia();

  return (
    <button
      className={styles.controlsButton}
      onClick={() => {
        MediaDevice.toggle('Video');
      }}
    >
      {mediaState.isVideoEnabled ? <FiVideo/> : <FiVideoOff/>}
    </button>
  );
};

export default VideoButton;
