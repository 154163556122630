import React, { useCallback, useMemo, useState } from 'react';
import QRCode from 'react-qr-code';
import styles from './Profile.module.scss';
import Container from '../../../components/Container';
import { useTranslation } from 'react-i18next';
import AvatarProfileChanger from '../../../containers/profile/AvatarProfileChanger';
import Title from '../../../components/Title';
import { FiChevronDown, FiChevronUp, FiKey } from 'react-icons/fi';
import Divider from '../../../components/Divider';
import IconButton from '../../../components/IconButton';
import ProfileForm from '../../../containers/profile/ProfileForm';
import PasswordForm from '../../../containers/profile/PasswordForm';
import cn from 'classnames';
import Popup from 'reactjs-popup';
import Modal from '../../../components/Modal';
import ModalControlled from '../../../components/Modal/ModalControlled';
import PhoneChangeForm from '../../../containers/profile/PhoneChangeForm';
import PhoneConfirmationForm from '../../../containers/profile/PhoneConfirmationForm';
import { useGetUserProfileQuery } from '../../../store/apis/profile';
import ContentLoader from 'react-content-loader';
import { head } from 'ramda';
import { getHeadIfExists, joinExisting } from '../../../utils';
import QRCodeGenerator from '../../../containers/profile/QRCodeGenerator';
import LinkedTelegramAccounts from '../../../containers/profile/LinkedTelegramAccounts';

const Profile = () => {
  const [t] = useTranslation();
  const [openedPassword, setOpenedPassword] = useState(false);
  const [openedConfirmationPhone, setOpenedConfirmationPhone] = useState(false);
  const [newPhone, setNewPhone] = useState(null);

  const { data: profile, isLoading, isFetching } = useGetUserProfileQuery();

  const passwordContentClasses = useMemo(() => cn({
    [styles.passwordContent]: true,
    [styles.passwordContentVisible]: openedPassword,
  }), [openedPassword]);

  const handleTogglePasswordForm = useCallback(() => {
    setOpenedPassword(!openedPassword);
  }, [openedPassword]);

  const handleOpenConfirmationPhone = useCallback(() => {
    setOpenedConfirmationPhone(true);
  }, []);

  const handleCloseConfirmationPhone = useCallback(() => {
    setOpenedConfirmationPhone(false);
  }, []);

  if (isLoading || isFetching) return (
    <Container>
      <div className={styles.profile}>
        <ContentLoader
          speed={2}
          width="100%"
          height={700}
          viewBox="0 0 100% 700"
          backgroundColor={'#c8c8c8'}
          foregroundColor={'#eceaea'}
        >
          <rect x="0" y="0" rx="4" ry="4" width="346" height="20" />
          <rect x="0" y="42" rx="4" ry="4" width="25%" height="180" />
          <rect x="30%" y="42" rx="4" ry="4" width="70%" height="24" />
          <rect x="30%" y="90" rx="4" ry="4" width="70%" height="88" />
          <rect x="30%" y="200" rx="4" ry="4" width="70%" height="88" />
          <rect x="30%" y="310" rx="4" ry="4" width="70%" height="88" />
          <rect x="30%" y="420" rx="4" ry="4" width="70%" height="88" />
          <rect x="30%" y="530" rx="4" ry="4" width="70%" height="88" />
          <rect x="30%" y="640" rx="4" ry="4" width="70%" height="88" />
        </ContentLoader>
      </div>
    </Container>
  );

  return (
    <Container>
      <div className={styles.profile}>
        <Title level={4}>{t('Профиль')}</Title>
        <div className={styles.profileWrapper}>
          <div className={styles.profileAside}>
            <AvatarProfileChanger
              src={profile.photoUrl}
              placeholder={
                joinExisting([getHeadIfExists(profile.firstName), getHeadIfExists(profile.lastName)], '')
              }
            />
          </div>
          <div className={styles.profileForm}>
            <ProfileForm initialValues={profile} />
            <Divider mt={40} mb={40}/>
            <div className={styles.profilePhone}>
              <div className={styles.profilePhoneField}>
                <div className={styles.profilePhoneFieldLabel}>{t('Номер телефона')}</div>
                <div className={styles.profilePhoneFieldValue}>+{profile.phoneNumber}</div>
              </div>
              <div className={styles.profilePhoneActions}>
                <Modal
                  trigger={
                    <button type="button" className={styles.profilePhoneActionsButton}>
                      {t('Изменить')}
                    </button>
                  }
                  title={t('Сменить номер телефона')}
                  closeOnDocumentClick={false}
                >
                  {(close) => (
                    <PhoneChangeForm
                      onCancel={close}
                      onSubmitSuccess={(newPhoneNumber) => {
                        handleOpenConfirmationPhone();
                        setNewPhone(newPhoneNumber);
                        close();
                      }}
                    />
                  )}
                </Modal>

                <ModalControlled
                  title={t('Подтверждение номера телефона')}
                  open={openedConfirmationPhone}
                  onClose={handleCloseConfirmationPhone}
                  closeOnDocumentClick={false}
                >
                  <PhoneConfirmationForm
                    phone={newPhone}
                    onSubmitSuccess={handleCloseConfirmationPhone}
                  />
                </ModalControlled>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.notification}>
        <Title level={4}>{t('Уведомления')}</Title>
        <div className={styles.notificationWrapper}>
          <div className={styles.notificationAside}>
            <QRCodeGenerator />
          </div>
          <div className={styles.notificationForm}>
            <LinkedTelegramAccounts />
          </div>
        </div>
      </div>
      <div className={styles.password}>
        <div className={styles.passwordHeader}>
          <div className={styles.passwordHeaderTitle}>
            <Title level={4}><FiKey /> {t('Смена пароля')}</Title>
          </div>
          <div className={styles.passwordHeaderToggler}>
            <IconButton
              icon={openedPassword ? <FiChevronUp /> : <FiChevronDown />}
              size="large"
              type="button"
              color="transparent"
              onClick={handleTogglePasswordForm}
            />
          </div>
        </div>
        <div className={passwordContentClasses}>
          <div className={styles.passwordContentAside} />
          <div className={styles.passwordContentForm}>
            <PasswordForm />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Profile;
