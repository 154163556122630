import React from 'react';

import styles from './RoomControls.module.scss';
import { FiMessageSquare, FiX } from 'react-icons/fi';
import VideoButton from './VideoButton';
import AudioButton from './AudioButton';
import ScreenShareButton from './ScreenShareButton';
import FullScreenButton from './FullScreenButton';
import ChatButton from './ChatButton';
import StopCallButton from './StopCallButton';

const RoomControls = () => {
  // const dispatch = useDispatch();
  // const navigate = useNavigate();
  // const [fullScreen, setFullScreen] = useState(false);
  //
  // const chatVisibilityTogglerClasses = useMemo(() => cn({
  //   [styles.controlsButton]: true,
  //   [styles.controlsButtonToggler]: true,
  // }), []);
  //
  // const {
  //   setAudioTrack,
  //   setVideoTrack,
  //   stopUserMedia,
  //   stopDisplayMedia,
  //   getDisplayMedia,
  //   getUserMedia,
  // } = useMediaStream();
  //
  // const {
  //   closePeerConnection,
  //   replaceTrack,
  // } = useRTC();
  //
  // const controlsState = useRoomControlsState();
  //
  // const toggleMic = useCallback(() => {
  //   dispatch(setMicEnabled(!controlsState.micEnabled));
  //   setAudioTrack(!controlsState.micEnabled);
  // }, [controlsState.micEnabled, dispatch, setAudioTrack]);
  //
  // const toggleVideo = useCallback(() => {
  //   dispatch(setVideoEnabled(!controlsState.videoEnabled));
  //   setVideoTrack(!controlsState.videoEnabled);
  // }, [controlsState.videoEnabled, dispatch, setVideoTrack]);
  //
  // const stop = useCallback(() => {
  //   stopUserMedia();
  //   stopDisplayMedia();
  //   closePeerConnection('user close');
  //   navigate(appRoutes.schedule(), { replace: true });
  // }, [stopUserMedia, stopDisplayMedia, closePeerConnection, navigate]);
  //
  // const stopScreenSharing = useCallback(async () => {
  //   const currentStream = await getUserMedia(selfVideoRef.current);
  //   stopDisplayMedia();
  //   const track = currentStream.getVideoTracks()[0];
  //   await replaceTrack(track);
  //   dispatch(setScreenSharingEnabled(false));
  // }, [dispatch, getUserMedia, replaceTrack, selfVideoRef, stopDisplayMedia]);
  //
  //
  // const startScreenSharing = useCallback(async () => {
  //   let currentStream;
  //   try {
  //     currentStream = await getDisplayMedia(selfVideoRef);
  //   } catch (e) {
  //     Sentry.captureException(e);
  //     return;
  //   }
  //   const track = currentStream.getVideoTracks()[0];
  //   try {
  //     await replaceTrack(track);
  //   } catch (e) {
  //     Sentry.captureException(e);
  //     return;
  //   }
  //   track.addEventListener('ended', () => {
  //     stopScreenSharing();
  //   });
  //   dispatch(setScreenSharingEnabled(true));
  // }, [stopScreenSharing, replaceTrack, dispatch, selfVideoRef, getDisplayMedia]);
  //


  return (
    <div className={styles.controls}>
      <AudioButton />
      <VideoButton />
      <StopCallButton />
      <ScreenShareButton />
      <FullScreenButton />
      <ChatButton />
    </div>
  );
};

export default RoomControls;
