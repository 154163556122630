import axios from 'axios';
import apiRoutes, { baseUrl } from './apiRoutes';
import localforage from 'localforage';
import { identity } from 'ramda';
import i18n from './localizations/i18n';
import { socket } from './socket';
import browserRouter from './app/browserRouter';
import appRoutes from './app/appRoutes';
import { toast } from 'react-toastify';

const httpClient = axios.create({
  baseURL: baseUrl,
});

httpClient.interceptors.request.use(async (config) => {
  const credentials = await localforage.getItem('credentials');

  if (credentials && credentials.accessToken) {
    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${credentials.accessToken}`,
        'Accept-Language': i18n.language,
      },
    };
  }

  return {
    ...config,
    headers: {
      ...config.headers,
      'Accept-Language': i18n.language,
    }
  };
});

let isRefreshing = false;
let refreshSubscribers = [];

const subscribeTokenRefresh = (cb) => {
  refreshSubscribers.push(cb);
};

const onRefreshed = (newCredentials) => {
  refreshSubscribers.map(cb => cb(newCredentials.accessToken));
  refreshSubscribers = [];
};

httpClient.interceptors.response.use(identity, async (error) => {
  if (!error.response || error.response.status !== 401) return Promise.reject(error);

  const credentials = await localforage.getItem('credentials');

  if (!credentials || !credentials.refreshToken) return Promise.reject(error);
  const originalRequest = error.config;

  if (!isRefreshing) {
    isRefreshing = true;
    axios.post(baseUrl + apiRoutes.refreshToken(), { refreshToken: credentials.refreshToken })
      .then(newCredentials => {
        isRefreshing = false;
        localforage.setItem('credentials', newCredentials.data)
          .then(() => {
            socket.connect();
          });
        onRefreshed(newCredentials.data);
      })
      .catch((err) => {
        localforage.removeItem('credentials')
          .then(() => {
            isRefreshing = false;
            browserRouter.navigate(appRoutes.login());
            toast.error(i18n.t('Срок вашей сессии истек'));
          })
      });
  }

  const retryOrigReq = new Promise((resolve, reject) => {
    subscribeTokenRefresh(token => {
      // replace the expired token and retry
      originalRequest.headers['Authorization'] = 'Bearer ' + token;
      resolve(axios(originalRequest));
    });
  });
  return retryOrigReq;

  // try {
  //   const newCredentials = await axios.post(baseUrl + apiRoutes.refreshToken(), { refreshToken: credentials.refreshToken });
  //   await localforage.setItem('credentials', newCredentials.data);
  //   socket.connect();
  //   return await httpClient(originalRequest);
  // } catch (e) {
  //   await localforage.clear();
  //   console.error(e);
  //   return Promise.reject(error);
  // }
});

export default httpClient;
