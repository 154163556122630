import React, { useCallback, useEffect, useRef } from 'react';

import styles from './ChatMessagesContainer.module.scss';
import LoaderFullContent from '../../../components/LoaderFullContent';
import { formatTime, reverseIfExists } from '../../../utils';
import ChatMessage from '../../../components/ChatMessage';
import {
  useGetChatDetailsQuery,
  useLazyGetChatMessagesQuery,
  useReadAllMessagesMutation
} from '../../../store/apis/chats';
import {
  resetUnreadMessages,
  setInitMessages,
  useChat,
  useChatMessages,
  useChatUnreadFilesAndMessages
} from '../../../store/slicers/chat';
import { useDispatch } from 'react-redux';
import ChatMessageForm from '../ChatMessageForm';
import { includes } from 'ramda';
import { useTranslation } from 'react-i18next';

const ChatMessagesContainer = () => {
  const [, i18n] = useTranslation();
  const chat = useChat();
  const chatMessages = useChatMessages(chat.activeChatId);
  const [unreadMessageCount] = useChatUnreadFilesAndMessages(chat.activeChatId);

  const dispatch = useDispatch();

  const scrollRef = useRef(null);

  const scrollToBottom = useCallback(() => {
    if (scrollRef.current) scrollRef.current.scrollIntoView();
  }, []);

  const [readAllMessages] = useReadAllMessagesMutation();

  const {
    data: chatDetails,
  } = useGetChatDetailsQuery({ id: chat.activeChatId }, { skip: !chat.activeChatId });

  const [getChatMessages, { isLoading: isMessagesFetching, isFetching: isMessagesLoading }] = useLazyGetChatMessagesQuery();

  useEffect(() => {
    if (!chat.activeChatId || includes(chat.activeChatId, chat.initializedTextChats)) {
      return;
    }

    getChatMessages({ chatId: chat.activeChatId, pageSize: 50 }, true)
      .unwrap()
      .then((data) => {
        const messages = reverseIfExists(data.results);
        scrollToBottom();
        dispatch(setInitMessages({ chatId: chat.activeChatId, messages }));
      })
      .catch((e) => {
        console.log(JSON.stringify(e.data ?? e, 0, 2));
      });
  }, [
    scrollToBottom,
    chat.activeChatId,
    chat.initializedTextChats,
    dispatch,
    getChatMessages
  ]);

  useEffect(() => {
    scrollToBottom();
  }, [chatMessages?.length, scrollToBottom]);

  useEffect(() => {
    if (unreadMessageCount > 0) {
      dispatch(resetUnreadMessages(chat.activeChatId));
      readAllMessages(chat.activeChatId);
    }
  }, [chat.activeChatId, dispatch, readAllMessages, unreadMessageCount]);

  if (isMessagesFetching || isMessagesLoading) return (
    <LoaderFullContent />
  );

  return (
    <>
      <div className={styles.inbox}>
        {
          (chatMessages && chatMessages.length > 0)
            ? chatMessages.map(({ id, isMyMessage, text, sendTime }) => (
              <ChatMessage
                key={id}
                type={isMyMessage ? 'self' : 'remote'}
                message={text}
                date={sendTime}
                name={chatDetails?.user.fullName?.[i18n.language] || ''}
                avatarUrl={chatDetails?.user.photoUrl}
              />
            )) : null
        }
        <div style={{ float: 'left', clear: 'both' }} ref={scrollRef} />
      </div>
      <ChatMessageForm />
    </>
  )
};

export default ChatMessagesContainer;
