// import cn from 'classnames';

import styles from './TutorFavoriteTogglerFromDetails.module.scss';
import Button from '../../../components/Button';
import { FiHeart } from 'react-icons/fi';
import React from 'react';
import { useAddFavoriteTutorsMutation, useRemoveFavoriteTutorsMutation } from '../../../store/apis/favorites';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useGetTutorDetailsQuery } from '../../../store/apis/tutors/tutorListApi';

const TutorFavoriteTogglerFromDetails = () => {
  const [t] = useTranslation();
  const { id: tutorId } = useParams();

  const {
    data: tutor,
  } = useGetTutorDetailsQuery(tutorId);

  const [
    addToFavorite,
  ] = useAddFavoriteTutorsMutation();

  const [
    removeFromFavorite,
  ] = useRemoveFavoriteTutorsMutation();

  const makeFavorite = async () => {
    await addToFavorite({
      tutorId: tutor.id,
    });
  };

  const removeFavorite = async () => {
    await removeFromFavorite({
      tutorId: tutor.id,
    });
  };

  return (
    <div style={{ marginBottom: 12 }}>
      <Button
        size="small"
        fullWidth
        icon={<FiHeart/>}
        iconPosition="start"
        color={tutor.isFavorite ? 'danger' : 'danger-outline'}
        className="Button_GetTrialLesson"
        onClick={tutor.isFavorite ? removeFavorite : makeFavorite}
      >
        {tutor.isFavorite ? t('Убрать из списка любимых') : t('Добавить в список любимых')}
      </Button>
    </div>
  );
};

export default TutorFavoriteTogglerFromDetails;
