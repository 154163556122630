import React from 'react';
import cn from 'classnames';

import styles from './InlineData.module.scss';

const InlineData = ({
  label,
  value,
}) => {

  return (
    <div className={styles.inline}>
      <div className={styles.inlineLabel}>{label}</div>
      <div className={styles.inlineValue}>{value}</div>
    </div>
  );
};

export default InlineData;
