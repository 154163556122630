// import cn from 'classnames';

import styles from './RoomControls.module.scss';
import { FiMaximize, FiMinimize } from 'react-icons/fi';
import React from 'react';
import { setFullScreen, useUIRoomState } from '../../../store/slicers/room';
import { useDispatch } from 'react-redux';

const FullScreenButton = () => {
  const uiRoomState = useUIRoomState();
  const dispatch = useDispatch();

  const toFullScreen = () => {
    const current = document.getElementById('video-container');
    if (uiRoomState.isFullScreen) {
      dispatch(setFullScreen(false));
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) { /* Safari */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) { /* IE11 */
        document.msExitFullscreen();
      }
    } else {
      dispatch(setFullScreen(true));
      if (current.requestFullscreen) {
        current.requestFullscreen();
      } else if (current.webkitRequestFullscreen) { /* Safari */
        current.webkitRequestFullscreen();
      } else if (current.msRequestFullscreen) { /* IE11 */
        current.msRequestFullscreen();
      }
    }
  }

  return (
    <button className={styles.controlsButton} onClick={toFullScreen}>
      {uiRoomState.isFullScreen ? <FiMinimize/> : <FiMaximize/>}
    </button>
  );
};

export default FullScreenButton;
