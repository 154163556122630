import React from 'react';

import styles from './AppQRLinks.module.scss';
import { useTranslation } from 'react-i18next';
import { useGetSocialLinksQuery } from '../../../store/apis/staticContents';
import { socialLinksIcons } from '../../../constants';

const AppQRLinks = () => {
  const [t] = useTranslation();

  const {
    data: socials,
    isLoading,
    isFetching,
  } = useGetSocialLinksQuery({ page: -1 });

  //TODO: Ссылки на плеймаркет и QR код

  const renderIcon = (iconName) => {
    const Icon = socialLinksIcons[iconName];
    return <Icon />
  };

  return (
    <div className={styles.tmp}>
      <h6 className={styles.tmpTitle}>{t('Скоро и Вашем мобильном телефоне')}</h6>
      <img src="/images/promo-app.png" alt=""/>
      {
        (socials?.results && socials.results.length > 0)
          && (
            <div className={styles.tmpLinks}>
              <h6 className={styles.tmpTitleSecond}>{t('Следите за нашими новостями')}</h6>
              <div className={styles.tmpSocials}>
                {
                  socials?.results.map(({ id, iconName, url }) => (
                    <a
                      href={url}
                      target="_blank"
                      className={styles.tmpSocialsItem}
                      key={id}
                      rel="noopener noreferrer"
                    >
                      {renderIcon(iconName)}
                    </a>
                  ))
                }
              </div>
            </div>
         )
      }
    </div>
  );

  // return (
  //   <div className={styles.apps}>
  //     <h6 className={styles.appsTitle}>{t('В приложении еще удобнее')}</h6>
  //     <div className={styles.appsQr}>
  //       <div className={styles.appsQrHint}>
  //         {t('Сканируй')}
  //       </div>
  //       <div className={styles.appsQrImage}>
  //         <img src="/images/qr.png" alt="QR код" />
  //       </div>
  //       <div className={styles.appsQrLinks}>
  //         <a href="#">
  //           <img src="/images/play-market.png" alt="Play market badge" />
  //         </a>
  //         <a href="#">
  //           <img src="/images/app-store.png" alt="App store badge" />
  //         </a>
  //       </div>
  //     </div>
  //   </div>
  // );
};

export default AppQRLinks;
