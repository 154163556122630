import React from 'react';
import cn from 'classnames';

import styles from './LoaderFullContent.module.scss';
import { PulseLoader } from 'react-spinners';

const LoaderFullContent = () => {

  return (
    <div className={styles.loader}>
      <PulseLoader color="#536DFE" />
    </div>
  );
};

export default LoaderFullContent;
