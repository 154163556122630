import React, { useMemo } from 'react';
import cn from 'classnames';

import styles from './IconButton.module.scss';

const IconButton = ({
  type = 'button',
  icon = null,
  shape = 'circle',
  color = 'primary',
  size = 'medium',
  onClick,
}) => {
  const buttonClasses = useMemo(() => cn({
    [styles.button]: true,
    //shapes
    [styles.circle]: shape === 'circle',
    [styles.square]: shape === 'square',
    //colors
    [styles.primary]: color === 'primary',
    [styles.transparent]: color === 'transparent',
    //sizes
    [styles.medium]: size === 'medium',
    [styles.large]: size === 'large',
    [styles.small]: size === 'small',
  }), [color, shape, size]);

  return (
    <button type={type} className={buttonClasses} onClick={onClick}>
      {icon}
    </button>
  );
};

export default IconButton;
