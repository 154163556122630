import React, { useCallback } from 'react';

import styles from './ScheduleCard.module.scss';
import { FiCheck, FiFeather, FiSend, FiX } from 'react-icons/fi';
import Button from '../../../components/Button';
import { useTranslation } from 'react-i18next';
import CancelLessonForm from '../CancelLessonForm';
import UserInfoSmall from '../../../components/UserInfoSmall';
import RescheduleLessons from '../RescheduleLessons';
import LessonDates from '../../../components/LessonDates';
import CancelOrReschedule from '../../../components/CancelOrReschedule';
import Text from '../../../components/Text';
import { useStartDialogMutation } from '../../../store/apis/chats';
import { openChat } from '../../../store/slicers/chat';
import appRoutes from '../../../app/appRoutes';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useApproveRescheduleMutation, useRejectRescheduleMutation } from '../../../store/apis/lessons/scheduled';
import { toast } from 'react-toastify';
import Popconfirm from '../../../components/Popconfirm';
import queryString from 'query-string';
import cn from 'classnames';

const ScheduleCard = ({
  scheduleId,
  date,
  lessonTime,
  roomId,
  time,
  tutorId,
  tutorPhoto,
  tutorName,
  tutorGraduation,
  cancelAvailable,
  transferAvailable,
  startAvailable,
  rescheduleData,
  cancelData,
  subject,
  isActive,
}) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [
    startDialog,
    {
      isLoading: isStartDialogLoading,
      isFetching: isStartDialogFetching,
    }] = useStartDialogMutation();

  const [approveRescheduleAsync, { isLoading: isApproveRescheduleLoading, isFetching: isApproveRescheduleFetching }] = useApproveRescheduleMutation();
  const [rejcetRescheduleAsync, { isLoading: isRejectRescheduleLoading, isFetching: isRejectRescheduleFetching }] = useRejectRescheduleMutation();

  const openTutorChat = useCallback(async () => {
    try {
      const chat = await startDialog({ userId: tutorId, userType: 'tutor' }).unwrap();
      dispatch(openChat(chat.id));
      navigate(appRoutes.chats());
    } catch (e) {
      console.log(JSON.stringify(e.data ?? e, 0, 2));
    }
  }, [tutorId, dispatch, navigate, startDialog]);

  const rejectReschedule = useCallback((rescheduledDataId) => async () => {
    if (!rescheduledDataId) {
      toast.warning(t('Похоже что этот урок нельзя отменить'));
      return;
    }
    try {
      await rejcetRescheduleAsync(rescheduledDataId).unwrap();
      toast.success(t('Вы отказали в переносе Урок отменен'));
    } catch (e) {
      console.log(JSON.stringify(e.data ?? e, 0, 2));
    }
  }, [rejcetRescheduleAsync, t]);

  const approveReschedule = useCallback((rescheduledDataId) => async () => {
    if (!rescheduledDataId) {
      toast.warning(t('Похоже что этот урок нельзя перенести'));
      return;
    }
    try {
      await approveRescheduleAsync(rescheduledDataId).unwrap();
      toast.success(t('Урок перенес на другую дату'));
    } catch (e) {
      console.log(JSON.stringify(e.data ?? e, 0, 2));
    }
  }, [approveRescheduleAsync, t]);

  const connectToRoom = useCallback(() => {
    navigate({
      pathname: appRoutes.room(),
      search: queryString.stringify({
        roomId,
        tutorId,
      }),
    });
  }, [tutorId, roomId, navigate]);

  return (
    <div className={cn(styles.card, isActive ? null : styles.cardInactive)}>
      <div className={styles.cardSubject}>
        <span className={styles.cardSubjectItem}>
          <FiFeather /> {subject}
        </span>
      </div>
      {
        rescheduleData
          ? (
            <CancelOrReschedule
              fromLessonTime={rescheduleData.fromLessonTime}
              toLessonTime={rescheduleData.toLessonTime}
              text={
                rescheduleData.autorType.id === 2
                ? t('Вы запросили перенос занятия')
                : t('Учитель запросил перенос занятия')
              }
              color="warning"
            />
          ) : null
      }
      {
        cancelData ? (
          <CancelOrReschedule
            fromLessonTime={cancelData.lessonTime}
            text={t('Занятие отменено')}
            color="danger"
          />
        ) : null
      }
      {
        (!rescheduleData && !cancelData) && (
          <LessonDates date={date} time={time} />
        )
      }
      <div className={styles.cardTutor}>
        <div className={styles.cardTutorInfo}>
          <Link to={appRoutes.tutors(tutorId)}>
            <UserInfoSmall
              photoUrl={tutorPhoto}
              name={tutorName}
              message={tutorGraduation}
            />
          </Link>
        </div>
        <button
          type="button"
          onClick={openTutorChat}
          className={styles.cardTutorChat}
          disabled={isStartDialogLoading || isStartDialogFetching}
        >
          <FiSend />
        </button>
      </div>

      <div className={styles.cardActions}>
        <div className={styles.cardActionsItem}>
          {
            (cancelAvailable && isActive) && (
              <CancelLessonForm
                scheduleId={scheduleId}
                lessonTime={lessonTime}
                tutorInfo={{
                  photoUrl: tutorPhoto,
                  name: tutorName,
                  message: tutorGraduation,
                }}
              />
            )
          }
        </div>
        <div className={styles.cardActionsItem}>
          {
            (transferAvailable && isActive) && (
              <RescheduleLessons
                scheduleId={scheduleId}
                lessonTime={lessonTime}
                tutorInfo={{
                  photoUrl: tutorPhoto,
                  name: tutorName,
                  message: tutorGraduation,
                }}
                tutorId={tutorId}
              />
            )
          }
        </div>
      </div>

      <div className={styles.cardFooter}>
        <div className={styles.cardFooterItem}>
          {
            (startAvailable && isActive) && (
              <Button
                type="button"
                size="small"
                fullWidth
                onClick={connectToRoom}
              >
                {t('Подключиться к уроку')}
              </Button>
            )
          }
          {
            (rescheduleData && isActive) && (
              <div className={styles.cardFooterComment}>
                <Text noPadding level={2} color="secondary">{t('Комментарий')}</Text>
                <Text level={3}>{rescheduleData.comment}</Text>
              </div>
            )
          }
          {
            (cancelData && isActive) && (
              <div className={styles.cardFooterComment}>
                <Text noPadding level={2} color="secondary">{t('Комментарий')}</Text>
                <Text level={3}>{cancelData.comment}</Text>
              </div>
            )
          }
          {
            (rescheduleData && rescheduleData.approveAvailable && isActive) && (
              <div className={styles.cardFooterActions}>
                <Popconfirm
                  title={t('Вы уверены что хотите отказать?')}
                  text={t('Это занятие будет отменено и удалено из расписания')}
                  trigger={
                    <Button
                      size="mini"
                      color="secondary"
                      icon={<FiX />}
                      iconPosition="start"
                      fullWidth
                    >
                      {t('Отказать')}
                    </Button>
                  }
                  onApprove={rejectReschedule(rescheduleData.id)}
                  rejectText={t('Нет, закрыть окно')}
                  approveText={t('Да, отменить занятие')}
                  loading={isRejectRescheduleLoading || isRejectRescheduleFetching}
                />
                <Popconfirm
                  title={t('Вы уверены что хотите принять?')}
                  text={t('Это занятие будет перенесено на указанное учителем время')}
                  trigger={
                    <Button
                      size="mini"
                      color="secondary"
                      icon={<FiCheck />}
                      iconPosition="start"
                      fullWidth
                    >
                      {t('Принять')}
                    </Button>
                  }
                  onApprove={approveReschedule(rescheduleData.id)}
                  rejectText={t('Нет, закрыть окно')}
                  approveText={t('Да, перенести занятие')}
                  loading={isApproveRescheduleFetching || isApproveRescheduleLoading}
                />
              </div>
            )
          }
          {
            (!startAvailable && !rescheduleData && !cancelData && isActive) && (
              (
                <div className={styles.cardFooterTip}>
                  {t('Незадолго до урока, здесь появится ссылка на подключение после того, как учитель создаст комнату')}
                </div>
              )
            )
          }
          {
            (!isActive) && (
              <div className={styles.cardFooterTip}>
                {t('Данный урок еще не оплачен или не хватает средств на балансе')}
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
};

export default ScheduleCard;
