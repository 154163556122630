import React, { useMemo } from 'react';

import styles from './UserInfoSmall.module.scss';
import { head, map, split } from 'ramda';

const UserInfoSmall = ({
  photoUrl,
  name,
  message,
}) => {
  const userInitials = useMemo(() =>
    name ? map((nameItem) => head(nameItem), split(' ', name)) : ['🫠', null],
    [name]
  );
  return (
    <div className={styles.user}>
      <div className={styles.userAvatar}>
        {
          photoUrl
            ? <img src={photoUrl} alt=""/>
            : <div className={styles.userAvatarPlaceholder}>{userInitials[0]}{userInitials[1]}</div>
        }
      </div>
      <div className={styles.userBody}>
        <div className={styles.userName}>{name}</div>
        <div className={styles.userMessage}>{message}</div>
      </div>
    </div>
  );
};

export default UserInfoSmall;
