import React, { useMemo } from 'react';
import cn from 'classnames';

import styles from './Slot.module.scss';

const Slot = ({
  label,
  isActive,
  onClick,
  disabled,
}) => {
  const scheduleTimeSlotButtonClasses = useMemo(() => cn({
    [styles.slotButton]: true,
    [styles.slotButtonActive]: isActive,
  }), [isActive]);

  return (
    <div className={styles.slot}>
      <button
        className={scheduleTimeSlotButtonClasses}
        type="button"
        onClick={onClick}
        disabled={disabled}
      >
        {label}
      </button>
    </div>
  );
};

export default Slot;
