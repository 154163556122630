import { Controller } from 'react-hook-form';
import RatingInput from './RatingInput';


const RatingInputController = ({
  name, control, disabled,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <RatingInput
          {...field}
          isDisabled={disabled}
        />
      )}
    />
  )
};

export default RatingInputController;
