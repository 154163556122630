import React, { useCallback, useMemo } from 'react';
import cn from 'classnames';

import styles from './ChatFilesForm.module.scss';
import { FiPaperclip } from 'react-icons/fi';
import { toast } from 'react-toastify';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { bytesToMegabytes, getFileDropError } from '../../../utils';
import { addNewFile, useChat } from '../../../store/slicers/chat';
import { usePostFileMessageMutation } from '../../../store/apis/chats';
import LoaderFullContent from '../../../components/LoaderFullContent';
import { useDispatch } from 'react-redux';

const ChatFilesForm = ({
  children,
}) => {
  const [t] = useTranslation();
  const chat = useChat();
  const dispatch = useDispatch();

  const [sendFileMessage, { isLoading, isFetching }] = usePostFileMessageMutation();

  const sendChatFile = useCallback(async (files) => {
    const promises = files.map((file) => {
      return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('chatId', chat.activeChatId);
        sendFileMessage(formData)
          .unwrap()
          .then((file) => {
            dispatch(addNewFile({ chatId: chat.activeChatId, file }));
            resolve(file);
          })
          .catch(reject)
      });
    });

    await Promise.all(promises);

  }, [chat.activeChatId, dispatch, sendFileMessage]);

  const rejectFile = useCallback((files) => {
    files.forEach(({ file, errors }) => {
      const { name, size } = file;
      errors.forEach(({ code }) => {
        toast.error(`${getFileDropError(code)} - ${name} (${bytesToMegabytes(size)}мб)`);
      });
    });
  }, []);

  const {
    getRootProps,
    getInputProps,
    open,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    maxSize: 1048576 * 5,
    multiple: true,
    noClick: true,
    noKeyboard: true,
    onDropRejected: rejectFile,
    onDropAccepted: sendChatFile,
  });

  const dropzoneClasses = useMemo(() => cn({
    [styles.fileForm]: true,
    [styles.fileFormAccepted]: isDragAccept,
    [styles.fileFormRejected]: isDragReject,
  }), [isDragAccept, isDragReject]);

  return (
    <section {...getRootProps({ className: dropzoneClasses })}>
      {
        (isLoading || isFetching) && <LoaderFullContent />
      }
      <div className={styles.dropzoneFeedback}>
        {t('Бросьте файл(-ы) для отправки')}
      </div>
      <input {...getInputProps()} />
      <div className={styles.fileFormMessages}>
        {children}
      </div>
      <div className={styles.fileFormFooter}>
        <div className={styles.fileFormFooterContainer}>
          <button
            type="button"
            onClick={open}
            className={styles.fileFormButton}
          >
          <span className={styles.fileFormButtonIcon}>
            <FiPaperclip />
          </span>
          <span className={styles.fileFormButtonTexts}>
            <span className={styles.fileFormButtonLabel}>{t('Отправить файл')}</span>
            <span className={styles.fileFormButtonSubtitle}>{t('не более 5 мб')}</span>
          </span>
          </button>
        </div>
      </div>
    </section>
  );
};

export default ChatFilesForm;
