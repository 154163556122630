import React, { useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import styles from './TutorSearchForm.module.scss';
import { useDebounce } from "@uidotdev/usehooks";
import { useForm } from 'react-hook-form';
import SelectInputController from '../../../components/ComplexSelectInput/SelectInputController';
import Button from '../../../components/Button';
import { FiFilter, FiSearch, FiX } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import { useGetSubjectsQuery } from '../../../store/apis/dictionaries/subjects';
import { dictionaryToOptions, sortByField } from '../../../utils';
import { useGetLanguagesQuery } from '../../../store/apis/dictionaries/languages';
import RangeSelectController from '../../../components/RangeSelect/RangeSelectController';
import SortSelect from '../../../components/SortSelect';
import queryString from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';
import useQueryString from '../../../hooks/useQueryString';
import { identity } from 'ramda';
import TextInputController from '../../../components/TextInput/TextInputController';
import ComplexTextInput from '../../../components/ComplexTextInput';
import ComplexTextInputController from '../../../components/ComplexTextInput/ComplexTextInputController';

const parseDiapasonQuery = (from, to) => {
  const values = [from, to].filter(identity);
  return values.length > 0 ? values : null;
};

const TutorSearchForm = () => {
  const [t] = useTranslation();
  const { state } = useLocation();
  const [formVisible, setFormVisible] = useState(false);
  const [sort, setSort] = useState(null);
  const [searchTerms, setSearchTerm] = useState(null);
  const debounceSearchTerm = useDebounce(searchTerms, 300);
  const query = useQueryString();
  const navigate = useNavigate();

  const {
    data: subjectData,
    isLoading: isSubjectsLoading,
    isFetching: isSubjectsFetching,
  } = useGetSubjectsQuery({ page: -1 });

  const {
    data: languagesData,
    isLoading: isLanguagesLoading,
    isFetching: isLanguagesFetching,
  } = useGetLanguagesQuery({ page: -1 });

  const {
    control,
    handleSubmit: submit,
    reset,
    watch,
  } = useForm({
    values: {
      ...query,
      subject: query.subject ? Number(query.subject) : null,
      language: query.language ? Number(query.language) : null,
      price: parseDiapasonQuery(query.priceFrom, query.priceTo),
      workExperience: parseDiapasonQuery(query.workExperienceFrom, query.workExperienceTo),
      age: parseDiapasonQuery(query.ageFrom, query.ageTo),
      lastName: query.lastName || '',
    },
  });

  const formClasses = useMemo(() => cn({
    [styles.searchForm]: true,
    [styles.searchFormVisible]: formVisible,
  }), [formVisible]);

  const handleShowForm = () => {
    setFormVisible(true);
  };

  const handleHideForm = () => {
    setFormVisible(false);
  };

  const handleSubmit = (values) => {
    const { age, price, workExperience, ...restValue } = values;
    const parsedValues = {
      ...restValue,
      priceFrom: price ? price[0] : null,
      priceTo: price ? price[1] : null,
      workExperienceFrom: workExperience ? workExperience[0] : null,
      workExperienceTo: workExperience ? workExperience[1] : null,
      ageFrom: age ? age[0] : null,
      ageTo: age ? age[1] : null,
    };

    navigate({
      pathname: '',
      search: queryString.stringify({
        page: 1,
        pageSize: 20,
        ...parsedValues,
      })
    }, { state });
    // handleHideForm(false);
  };

  const handleReset = () => {
    reset();
    navigate({ pathname: '' }, { state });
    setSort(null);
    handleHideForm();
  };

  const handleSort = (sort) => {
    setSort(sort);

    navigate({
      pathname: '',
      search: queryString.stringify({
        page: 1,
        pageSize: 20,
        ...query,
        sort: sort.value,
      }),
    }, { state });
  };
  useEffect(() => {
    if (debounceSearchTerm) {
      handleSubmit(debounceSearchTerm);
    }
  }, [debounceSearchTerm]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      setSearchTerm(value);
    })
    return () => subscription.unsubscribe()
  }, [watch])

  return (
    <div className={styles.search}>
      <Button
        type="button"
        size="small"
        icon={<FiFilter />}
        iconPosition="start"
        onClick={handleShowForm}
        className={styles.searchFormFilter}
      >
        {t('Фильтры')}
      </Button>
      <form onSubmit={submit(handleSubmit)} className={formClasses}>
        <button type="button" className={styles.searchFormClose} onClick={handleHideForm}>
          <FiX />
        </button>
        <div className={styles.searchFormFields}>
          <div className={styles.searchFormFieldsItem}>
            <SelectInputController
              control={control}
              placeholder={t('Выберите предмет')}
              name="subject"
              label={t('Я хочу изучать')}
              options={sortByField('label', dictionaryToOptions(subjectData))}
              disabled={isSubjectsFetching || isSubjectsLoading}
            />
          </div>
          <div className={styles.searchFormFieldsItem}>
            <RangeSelectController
              control={control}
              name="price"
              label={t('Цена за урок')}
              min={50000}
              max={1000000}
              step={50000}
              affix="UZS"
              placeholder={t('Укажите цену')}
              diapasonLabel={t('От {{from}} до {{to}}', { from: 50000, to: 1000000 })}
            />
          </div>
          <div className={styles.searchFormFieldsItem}>
            <SelectInputController
              control={control}
              placeholder={t('Выберите язык')}
              name="language"
              label={t('Говорит на языке')}
              options={sortByField('label', dictionaryToOptions(languagesData))}
              disabled={isLanguagesLoading || isLanguagesFetching}
            />
          </div>
          <div className={styles.searchFormFieldsItem}>
            <RangeSelectController
              control={control}
              name="workExperience"
              label={t('Стаж преподавателя')}
              min={1}
              max={60}
              step={1}
              affix={t('лет')}
              placeholder={t('Укажите стаж')}
              diapasonLabel={t('От {{from}} до {{to}}', { from: 1, to: 60 })}
            />
          </div>
          <div className={styles.searchFormFieldsItem}>
            <RangeSelectController
              control={control}
              name="age"
              label={t('Возраст преподавателя')}
              min={18}
              max={80}
              step={1}
              affix={t('лет')}
              placeholder={t('Укажите возраст')}
              diapasonLabel={t('От {{from}} до {{to}}', { from: 18, to: 80 })}
            />
          </div>
          <div className={styles.searchFormFieldsItem}>
            <ComplexTextInputController
              control={control}
              name="lastName"
              label={t('Фамилия')}
              placeholder={t('Введите фамилию')}
            />
            {/*{TODO: Реализовать справочник времен}*/}
            {/*<SelectInputController*/}
            {/*  control={control}*/}
            {/*  label={t('Время занятий')}*/}
            {/*  name="time"*/}
            {/*  options={[*/}
            {/*    { value: 1, label: 'Любое время' },*/}
            {/*    { value: 2, label: 'Вечернее' },*/}
            {/*    { value: 3, label: 'Дневное' },*/}
            {/*    { value: 4, label: 'Утреннее' },*/}
            {/*  ]}*/}
            {/*/>*/}
          </div>
        </div>
        <div className={styles.searchFormButtons}>
          <div className={styles.searchFormButtonsItem}>
            <Button
              type="reset"
              color="light"
              icon={<FiX />}
              iconPosition="start"
              fullWidth
              className={styles.searchFormButtonsButton}
              onClick={handleReset}
            >
              {t('Очистить')}
            </Button>
          </div>
          <div className={styles.searchFormButtonsItem}>
            <Button
              type="submit"
              icon={<FiSearch />}
              iconPosition="start"
              fullWidth
              className={styles.searchFormButtonsButton}
              onClick={() => {
                handleHideForm();
              }}
            >
              {t('Найти')}
            </Button>
          </div>
        </div>
      </form>
      <div className={styles.sort}>
        <SortSelect
          options={[
            { label: t('Популярные'), value: null },
            { label: t('С высоким рейтингом'), value: '-rating.value' },
            { label: t('Дешевле'), value: 'price' },
            { label: t('Дороже'), value: '-price' },
            { label: t('Больше провел уроков'), value: '-completedLessonCount' },
            { label: t('Меньше провел уроков'), value: 'completedLessonCount' },
            { label: t('Повзрослее'), value: 'birthdate' },
            { label: t('Помоложе'), value: '-birthdate' },
          ]}
          label={t('Сначала показывать')}
          selected={sort}
          placeholder={`${t('Сначала показывать')}: ${t('Популярные')}`}
          onChange={handleSort}
        />
      </div>
    </div>
  );
};

export default TutorSearchForm;
