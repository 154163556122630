import React, { useCallback, useMemo } from 'react';
import cn from 'classnames';

import styles from './Tabs.module.scss';
import { isNil, isNotNil } from 'ramda';

const Tabs = ({
  items = [],
  activeItem,
  onChange = () => {},
}) => {
  const getButtonClasses = useCallback((isActive) => cn({
    [styles.tabsButton]: true,
    [styles.tabsButtonActive]: isActive,
  }), []);

  return (
    <div className={styles.tabs}>
      <div className={styles.tabsWrapper}>
        {
          items.map(({ id, name, icon, badge }) => (
            <button
              key={id}
              onClick={() => onChange(id, { id, name, icon, badge })}
              className={getButtonClasses(id === activeItem)}
            >
              <span className={styles.tabsButtonIcon}>
                {icon}
              </span>
              {name}
              {
                badge ? (
                  <div className={styles.tabsBadge}>
                    {badge}
                  </div>
                ) : null
              }
            </button>
          ))
        }
      </div>
    </div>
  );
};

export default Tabs;
