import React, { useCallback } from 'react';
import cn from 'classnames';

import styles from './PhoneConfirmationForm.module.scss';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import ConfirmationPhoneInputController from '../../../components/ConfirmationPhoneInput/ConfirmationPhoneInputController';
import Button from '../../../components/Button';
import { useVerifyNewPhoneMutation } from '../../../store/apis/profile';
import { toast } from 'react-toastify';

const PhoneConfirmationForm = ({
  phone,
  onSubmitSuccess,
}) => {
  const [t] = useTranslation();
  const [verfiyPhone, { isLoading, isFetching }] = useVerifyNewPhoneMutation();
  const {
    control,
    handleSubmit: submit,
    register,
  } = useForm({
    defaultValues: {
      code: '',
      phoneNumber: phone,
    },
  });

  const handleSubmit = useCallback(async (values) => {
    try {
      await verfiyPhone(values).unwrap();
      toast.success(t('Номер телефона изменен'));
      onSubmitSuccess();
    } catch (e) {
      console.log(JSON.stringify(e.data ?? e, 0, 2));
    }
  }, [onSubmitSuccess, t, verfiyPhone]);

  return (
    <form onSubmit={submit(handleSubmit)}>
      <div className={styles.subtitle}>
        {t('Код был отправлена на номер', { phone })}
      </div>
      <div className={styles.body}>
        <input type="hidden" {...register('phoneNumber')} />
        <ConfirmationPhoneInputController control={control} name="code" />
      </div>
      <div className={styles.footer}>
        <Button type="submit" loading={isLoading || isFetching}>
          {t('Продолжить')}
        </Button>
      </div>
    </form>
  );
};

export default PhoneConfirmationForm;
