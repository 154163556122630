import React, { useMemo } from 'react';
import cn from 'classnames';

import styles from './RoomContent.module.scss';
import Title from '../../../components/Title';
import { FiX } from 'react-icons/fi';
import ChatWindow from '../../../containers/chat/ChatWindow';
import { useTranslation } from 'react-i18next';
import SelfVideo from '../SelfVideo';
import NoPermissionsWarningModal from '../../../containers/shared/RoomWarning/NoPermissionsWarningModal';
import NoMicrophoneWarning from '../../../containers/shared/RoomWarning/NoMicrophoneWarning';
import { setChatVisible, useUIRoomState } from '../../../store/slicers/room';
import RoomControls from '../RoomControls';
import { useDispatch } from 'react-redux';
import RoomLoading from '../../../containers/shared/RoomLoading';
import UnexpectedBehaviourModal from '../../../containers/shared/RoomWarning/UnexpectedBehaviourModal';

const RoomContent = () => {
  const uiRoomState = useUIRoomState();
  const dispatch = useDispatch();

  const [t] = useTranslation();

  const chatClasses = useMemo(() => cn({
    [styles.lobbyChat]: true,
    [styles.visible]: uiRoomState.isChatVisible,
  }), [uiRoomState.isChatVisible]);

  return (
    <div className={styles.lobby}>
      <div className={styles.lobbyHeader}>
        <Title level={4}>{t('Классная комната')}</Title>
      </div>
      <div className={styles.lobbyBody}>
        <div className={styles.lobbyVideo} id="video-container">
          <div className={styles.lobbyVideoViews}>
            <div className={styles.lobbyVideoRemote}>
              <NoPermissionsWarningModal />
              <NoMicrophoneWarning />
              <RoomLoading />
              <UnexpectedBehaviourModal />
              <video
                className={styles.lobbyVideoRemotePlayer}
                autoPlay
                playsInline
                id="remote-video-player"
              />
            </div>
            <div className={styles.lobbyVideoSelf} >
              <SelfVideo />
            </div>
          </div>
          <RoomControls />
        </div>
        <div className={chatClasses}>
          <button
            type="button"
            className={styles.lobbyChatClose}
            onClick={() => {
              dispatch(setChatVisible(false));
            }}
          >
            <FiX />
          </button>
          <ChatWindow />
        </div>
      </div>
    </div>
  );
};

export default RoomContent;
