import React, { useCallback, useEffect } from 'react';
import cn from 'classnames';

import styles from './TariffChoiceList.module.scss';
import ContentLoader from 'react-content-loader';
import TariffCard from '../../../components/TariffCard';
import { formatNumber } from '../../../utils';
import { useLazyGetTariffsQuery } from '../../../store/apis/tariffs';
import { useTranslation } from 'react-i18next';
import { useBookingData } from '../../../store/slicers/booking';

const TariffChoiceList = ({
  tutorPrice,
  tutorId,
  onChoose,
  btnLoading,
}) => {
  const [t, i18n] = useTranslation();
  const [getTariffs, { data: tariffs, isLoading: isTariffsLoading, isFetching: isTariffsFetching }] = useLazyGetTariffsQuery();
  const bookingData = useBookingData();


  const chooseTariff = useCallback((tariff) => () => {
    onChoose(tariff);
  }, [onChoose]);

  useEffect(() => {
    getTariffs({ pageSize: -1, needPriceFor: tutorPrice, tutorId });
  }, [getTariffs, tutorId, tutorPrice]);

  return (isTariffsLoading || isTariffsFetching)
    ? (
      <ContentLoader
        backgroundColor={'#c8c8c8'}
        foregroundColor={'#eceaea'}
        width="760"
        height="340"
        speed={2}
      >
        <rect x="0" y="0" rx="12" ry="12" width="240px" height="100%" />
        <rect x="260" y="0" rx="12" ry="12" width="240px" height="100%" />
        <rect x="520" y="0" rx="12" ry="12" width="240px" height="100%" />
      </ContentLoader>
    ) : (
      <div className={styles.tariffs}>
        {
          tariffs?.results.map((tariff) => (
            <div className={styles.tariffsItem} key={tariff.id}>
              {/* TODO: Установить признак продвигаемого пакета isActive */}
              <TariffCard
                id={tariff.id}
                isActive={tariff.id === 3}
                name={tariff.name[i18n.language]}
                description={tariff.description[i18n.language]}
                salePercentage={(tariff.salePercentage > 0) && t('{{percent}}% экономии', { percent: tariff.salePercentage })}
                lessonCount={t('{{count}} занятий', { count: tariff.lessonCount })}
                price={formatNumber(tariff.price.totalPrice)}
                currencyName={t('сум')}
                buttonText={t('Купить пакет')}
                buttonLoading={btnLoading}
                onAction={chooseTariff(tariff)}
                cannotPurchase={bookingData.lessonsSpan?.length > tariff.tutorInfo.maxAllowedSchedules}
                cannotPurchaseText={t('Вам нужно выбрать другой тариф чтобы забронировать выбранное количество уроков')}
              />
            </div>
          ))
        }
      </div>
    );
};

export default TariffChoiceList;
