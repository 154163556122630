import React, { useCallback, useState } from 'react';
import cn from 'classnames';

import styles from './PasswordInput.module.scss';
import TextInput from '../TextInput';
import { FiEye, FiEyeOff } from 'react-icons/fi';

const PasswordInput = (props, ref) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = useCallback(() => {
    setShowPassword(!showPassword);
  }, [showPassword]);

  return (
    <TextInput
      {...props}
      type={showPassword ? 'text' : 'password'}
      error={props.error}
      ref={ref}
      appendix={
        <button type="button" className={styles.passwordToggler} onClick={handleTogglePassword}>
          {showPassword ? <FiEyeOff /> : <FiEye/>}
        </button>
      }
    />
  );
};

export default React.forwardRef(PasswordInput);
