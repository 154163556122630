import React, { useCallback } from 'react';
import cn from 'classnames';

import styles from './PostReviewModal.module.scss';
import Button from '../../../components/Button';
import { useTranslation } from 'react-i18next';
import Modal from '../../../components/Modal';
import { useForm } from 'react-hook-form';
import RatingInputController from '../../../components/RatingInput/RatingInputController';
import TextareaInputController from '../../../components/TextareaInput/TextareaInputController';
import { usePostTutorReviewMutation } from '../../../store/apis/tutors/tutorReviews';
import { toast } from 'react-toastify';
import { useGetLessonsQuery } from '../../../store/apis/lessons/scheduled';
import Popup from 'reactjs-popup';

const PostReviewModal = ({ tutorId }) => {
  const [t] = useTranslation();

  const {
    data: lessons,
    isLoading: isLessonsLoading,
    isFetching: isLessonsFetching,
  } = useGetLessonsQuery({ tutorId, page: 1, pageSize: 1 }, { skip: !tutorId });

  const [
    postTutorReview,
    { isLoading, isFetching },
  ] = usePostTutorReviewMutation();

  const {
    control,
    handleSubmit: submit,
    watch,
    setValue,
    reset,
  } = useForm({
    defaultValues: {
      score: 5,
    },
  });

  const handleReset = useCallback((cb) => () => {
    reset();
    cb();
  }, [reset]);

  const handleSubmit = useCallback((close) => async (values) => {
    try {
      const body = {
        tutorId,
        score: values.score,
        comment: values.comment,
      };
      await postTutorReview(body).unwrap();
      toast.success(t('Ваш отзыв был отправлен на модерацию'));
      close();
    } catch (e) {
      console.log(JSON.stringify(e.data ?? e, 0, 2));
    }
  }, [postTutorReview, t, tutorId]);


  if (!lessons || lessons.results.length === 0) {
    return (
      <Popup
        on={['hover']}
        trigger={open => (
          <button
            className={styles.reviewButtonDisabled}
          >
            {t('Оставить отзыв')}
          </button>
        )}
        mouseEnterDelay={500}
        position="top center"
        closeOnDocumentClick
      >
        <span>
          {t('Вы не можете оставить отзыв так как не прошли ни одного урока')}
        </span>
      </Popup>
    );
  }

  return (
    <Modal
      trigger={
        <Button
          type="button"
          size="small"
          fullWidth
          color="secondary"
          loading={isLessonsLoading || isLessonsFetching}
        >
          {t('Оставить отзыв')}
        </Button>
      }
      title={t('Оставьте отзыв об учителе')}
      subtitle={t('Это поможет другим студентам определиться с преподавателем')}
      variant="secondary"
    >
      {(close) => (
        <form onSubmit={submit(handleSubmit(close))} className={styles.form}>
          <div className={styles.formItemStar}>
            <RatingInputController control={control} name="score" disabled={isLoading || isFetching} />
          </div>
          <div className={styles.formItemField}>
            <TextareaInputController control={control} name="comment" placeholder={t('Напишите комментарий')} disabled={isLoading || isFetching} />
          </div>
          <div className={styles.formItemSubmit}>
            <Button
              type="reset"
              onClick={handleReset(close)}
              color="secondary"
              disabled={isLoading || isFetching}
            >
              {t('Отменить')}
            </Button>
            <Button
              type="submit"
              disabled={isLoading || isFetching}
              loading={isLoading || isFetching}
            >
              {t('Оставить отзыв')}
            </Button>
          </div>
        </form>
      )}
    </Modal>
  );
};

export default PostReviewModal;
