import React, { useCallback, useEffect, useMemo, useState } from 'react';

import styles from './Schedule.module.scss';
import TabsButton from '../../../components/TabsButton';
import { useTranslation } from 'react-i18next';
import { FiCalendar, FiList, FiRotateCcw, FiXOctagon } from 'react-icons/fi';
import Condition from '../../../components/Condition';
import Container from '../../../components/Container';
import ScheduleHistory from '../../../containers/schedule/ScheduleHistory';
import ScheduleTodayList from '../../../containers/schedule/ScheduleTodayList';
import ScheduleWeek from '../../../containers/schedule/ScheduleWeek';
import { useNavigate } from 'react-router-dom';
import useSockets from '../../../hooks/useSockets';
import { useDispatch } from 'react-redux';
import { mainApi } from '../../../store/apis';
import CancellingLessons from '../../../containers/schedule/CancellingLessons';
import useQueryString from '../../../hooks/useQueryString';

const Schedule = () => {
  const [t] = useTranslation();
  const query = useQueryString();
  const [currentTab, setCurrentTab] = useState(query.tab ?? 'list');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const socket = useSockets();

  useEffect(() => {
    const refreshSchedules = () => {
      dispatch(mainApi.util.invalidateTags(['SCHEDULES']));
    };

    socket.on('lessonStart', refreshSchedules);
    return () => {
      socket.off('lessonStart', refreshSchedules);
    };
  }, []);

  const tabs = useMemo(() => [
    {
      id: 'list',
      label: t('Ближайшие уроки'),
      icon: <FiList />,
    },
    {
      id: 'calendar',
      label: t('Календарь уроков'),
      additionalLabel: null,
      icon: <FiCalendar />,
    },
    {
      id: 'history',
      label: t('История уроков'),
      additionalLabel: null,
      icon: <FiRotateCcw />,
    },
    {
      id: 'cancellingLessons',
      label: t('Отменяемые уроки'),
      additionalLabel: null,
      icon: <FiXOctagon />,
    },
  ], [t]);

  const handleChangeTab = useCallback((tab) => {
    setCurrentTab(tab);
    navigate({
      pathname: '',
      search: '?tab=' + tab,
    });
  }, [navigate]);

  // TODO: Расписание и экшены в карточках расписания
  return (
    <Container>
      <div className={styles.schedule}>
        <div className={styles.scheduleHeader}>
          <TabsButton
            tabs={tabs}
            currentTab={currentTab}
            onTabClick={handleChangeTab}
          />
        </div>
        <div className={styles.scheduleBody}>
          <Condition when={currentTab} is="list">
            <ScheduleTodayList />
          </Condition>

          <Condition when={currentTab} is="calendar">
            <ScheduleWeek />
          </Condition>

          <Condition when={currentTab} is="history">
            <ScheduleHistory />
          </Condition>

          <Condition when={currentTab} is="cancellingLessons">
            <CancellingLessons />
          </Condition>
        </div>
      </div>
    </Container>
  );
};

export default Schedule;
