import React, { useCallback } from 'react';
import cn from 'classnames';

import styles from './PasswordForm.module.scss';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import PasswordInputController from '../../../components/PasswordInput/PassswordInputController';
import CheckboxInput from '../../../components/CheckboxInput';
import CheckboxInputController from '../../../components/CheckboxInput/CheckboxInputController';
import Button from '../../../components/Button';
import { FiSave } from 'react-icons/fi';
import { createDataSchema } from '../../../yup';
import { boolean, ref, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useChangePasswordMutation } from '../../../store/apis/profile';
import localforage from 'localforage';
import { useNavigate } from 'react-router-dom';
import appRoutes from '../../../app/appRoutes';
import { setErrorsToFields } from '../../../utils';
import { toast } from 'react-toastify';

const schema = createDataSchema({
  oldPassword: string().required(),
  newPassword: string().required(),
  confirmationNewPassword: string().oneOf([ref('newPassword'), null]).required(),
  isAgree: boolean().required().isTrue(),
});

const PasswordForm = () => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const [changePassword, { isLoading, isFetching }] = useChangePasswordMutation();

  const {
    control,
    handleSubmit: submit,
    watch,
    formState: { isDirty },
    setError,
  } = useForm({
    defaultValues: {
      isAgree: false,
    },
    resolver: yupResolver(schema),
  });

  const handleSubmit = useCallback(async (values) => {
    try {
      await changePassword(values).unwrap();
    } catch (e) {
      return setErrorsToFields(e, setError);
      console.log(JSON.stringify(e.data ?? e, 0, 2));
    }

    await localforage.clear();
    navigate(appRoutes.login());
    toast.success(t('Ваш пароль был изменен'));
  }, [changePassword, navigate, setError]);

  return (
    <form onSubmit={submit(handleSubmit)}>
      <PasswordInputController
        name="oldPassword"
        control={control}
        label={`${t('Ваш текущий пароль')}*`}
        id="currentPassword"
      />
      <PasswordInputController
        name="newPassword"
        control={control}
        label={`${t('Новый пароль')}*`}
        id="newPassword"
      />
      <PasswordInputController
        name="confirmationNewPassword"
        control={control}
        label={`${t('Повторите пароль чтобы не ошибиться')}*`}
        id="confirmationNewPassword"
      />
      <CheckboxInputController
        name="isAgree"
        control={control}
        label={t('Я согласен с тем, что после смены пароля произойдёт выход из аккаунта на всех устройствах.')}
      />
      <Button
        type="submit"
        icon={<FiSave />}
        iconPosition="start"
        disabled={!isDirty || watch('isAgree') === false}
        loading={isLoading || isFetching}
      >
        {t('Сохранить')}
      </Button>
    </form>
  );
};

export default PasswordForm;
