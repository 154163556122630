// import cn from 'classnames';

import styles from './CancellingLessons.module.scss';
import Title from '../../../components/Title';
import Text from '../../../components/Text';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Table from '../../../components/Table';
import { Link, useNavigate } from 'react-router-dom';
import appRoutes from '../../../app/appRoutes';
import UserInfoSmall from '../../../components/UserInfoSmall';
import { formatDate, getHourLabelById } from '../../../utils';
import useQueryString from '../../../hooks/useQueryString';
import { useGetLessonSchedulesQuery } from '../../../store/apis/lessons/scheduled';
import ContentEmpty from '../../../components/ContentEmpty';
import ContentLoader from 'react-content-loader';
import Button from '../../../components/Button';

const CancellingLessons = () => {
  const [t, i18n] = useTranslation();
  const navigate = useNavigate();
  const {
    page = 1,
    pageSize = 20,
    state = 2,
    ...restQuery
  } = useQueryString();

  const {
    data: lessonSchedules,
    isLoading,
    isFetching
  } = useGetLessonSchedulesQuery({ page: -1, isActive: false });

  const columns = useMemo(() => [
    {
      id: 1,
      accessor: 'tutor',
      Header: t('Учитель'),
      Cell: ({ value }) => (
        <Link to={appRoutes.tutors(value.id)} className={styles.tableLink}>
          <UserInfoSmall
            photoUrl={value.photoUrl}
            name={value.fullName?.[i18n.language]}
            message={value.shortInfo[i18n.language]}
          />
        </Link>
      )
    },
    {
      id: 2,
      accessor: 'subject',
      Header: t('Предмет'),
    },
    {
      id: 3,
      accessor: 'date',
      Header: t('Дата урока'),
      Cell: ({ value }) => formatDate(value),
    },
    {
      id: 4,
      accessor: 'time',
      Header: t('Время урока'),
      Cell: ({ value }) => getHourLabelById(value.hourId),
    },
    {
      id: 5,
      accessor: 'deleteAfterTime',
      Header: t('Время удаления'),
      Cell: ({ value }) => formatDate(value, true),
    },

  ], [i18n.language, t]);

  const data = lessonSchedules?.results.map((schedule) => ({
    id: schedule.id,
    date: schedule.startAtDate,
    time: schedule.timeSpan,
    deleteAfterTime: schedule.deleteAfterTime,
    subject: schedule.subject?.shortName[i18n.language],
    tutor: schedule.tutor,
  }));

  const goToTutorsSearch = useCallback(() => {
    navigate(appRoutes.tutors());
  }, [navigate]);

  if (isLoading || isFetching) return (
    <ContentLoader
      backgroundColor={'#c8c8c8'}
      foregroundColor={'#eceaea'}
      width="100%"
      height="340"
      style={{ padding: '0', boxSizing: 'border-box' }}
      speed={2}
    >
      {[0, 1, 2, 3, 4, 5].map((id) => {
        const height = 48;
        const positionY = id * (height + 10);
        return (
          <rect x="0" y={positionY} rx="6" ry="6" width="100%" height={height} key={id} />
        )
      })}
    </ContentLoader>
  );

  if (lessonSchedules && lessonSchedules.results.length === 0) return (
    <ContentEmpty
      imgSrc="/images/empty-sleeping.png"
      title={t('У Вас нет отменяемых уроков')}
      subtitle={t('Здесь будут отображаться уроки, для которых нехватает средств на балансе')}
      actionText={t('Хотите найти репетитора?')}
      onAction={goToTutorsSearch}
      btnClasses="Button_button__JBBzO_Find"
    />
  );

  return (
    <div>
      <Title level={4}>{t('Отменяемые уроки')}</Title>
      <Text color="secondary" level={3}>{t('Уроки, для которых нехватает средств на балансе и будут отменены, если Вы не пополните баланс')}</Text>

      <div className={styles.body}>
        <div className={styles.history}>
          <Table
            columns={columns}
            data={data}
            loading={isFetching}
          />
        </div>
        <div className={styles.footer}>
          <Button
            onClick={() => {
              navigate(appRoutes.balance())
            }}
          >
            {t('Пополнить баланс')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CancellingLessons;
