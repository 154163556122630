import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import cn from 'classnames';

import styles from './NotificationBar.module.scss';
import { FiBell, FiCheck, FiClock, FiInfo } from 'react-icons/fi';
import { useLazyGetNotificationListQuery, useReadAllNotificationsMutation } from '../../../store/apis/notificationsApi';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Title from '../../../components/Title';
import {
  resetUnreadNotificationCount,
  setInitialNotifications,
  useNotifications
} from '../../../store/slicers/notificationsSlice';
import { intlFormatDistance, isAfter, parseISO } from 'date-fns';
import useToggle from '../../../hooks/useToggle';
import EmptySimple from '../../../components/EmptySimple';
import { sort } from 'ramda';

const NotificationBar = () => {
  const [t, i18n] = useTranslation();
  const dispatch = useDispatch();
  const [isBarVisible, showBar, hideBar] = useToggle();
  const containerRef = useRef(null);

  const [getNotifications] = useLazyGetNotificationListQuery();
  const [readAllNotification] = useReadAllNotificationsMutation();

  const notifications = useNotifications();

  useEffect(() => {
    getNotifications({ isRead: false }).unwrap()
      .then((notificationData) => {
        dispatch(setInitialNotifications(notificationData?.results));
      })
      .catch((e) => {
        console.log(JSON.stringify(e.data ?? e, 0, 2));
      });
  }, [dispatch, getNotifications]);

  useEffect(() => {
    document.addEventListener('mousedown', (e) => {
      if (containerRef.current && isBarVisible && !containerRef.current.contains(e.target)) {
        hideBar();
      }
    });
  }, [hideBar, isBarVisible]);

  useEffect(() => {
    if (isBarVisible && notifications.unreadCount > 0) {
      // Без unwrap, потому что предполагается игнорировать ошибки,
      // которые могут прийти с бэка, для более привлекательного UI для пользователя
      readAllNotification();
      dispatch(resetUnreadNotificationCount());
    }
  }, [isBarVisible, notifications.unreadCount, readAllNotification, dispatch]);

  const barClasses = useMemo(() => cn({
    [styles.notificationBar]: true,
    [styles.notificationBarVisible]: isBarVisible,
  }), [isBarVisible]);

  const iconClasses = useCallback((color) => cn({
    [styles.notificationListIcon]: true,
    [styles.notificationListIconPrimary]: color === 'primary',
    [styles.notificationListIconWarning]: color === 'warning',
    [styles.notificationListIconSuccess]: color === 'success',
    [styles.notificationListIconDanger]: color === 'danger',
  }), []);

  const icons = useMemo(() => ({
    GENERAL: {
      color: 'primary',
      icon: <FiInfo />
    },
    PAYMENT: {
      color: 'success',
      icon: <FiCheck />
    },
    SCHEDULE: {
      color: 'warning',
      icon: <FiClock />
    },
  }), []);

  return (
    <div className={styles.notification}>
      <button type="button" className={styles.notificationButton} onClick={showBar}>
        <FiBell />
        {(notifications.unreadCount > 0) && (
          <span className={styles.notificationAlert} />
        )}
      </button>
      <div className={barClasses} ref={containerRef}>
        <div className={styles.notificationBarHeader}>
          <Title level={4}>{t('Уведомления')}</Title>
        </div>
        <div className={styles.notificationList}>
          {
            notifications.list.length > 0
              ? notifications.list.map((notification) => (
                <div className={styles.notificationListItem} key={notification.id}>
                  <div className={iconClasses(icons[notification.type]?.color)}>
                    {icons[notification.type]?.icon}
                  </div>
                  <div className={styles.notificationListBody}>
                    <div className={styles.notificationListTitle}>{notification.title[i18n.language]}</div>
                    <div className={styles.notificationListText}>{notification.text[i18n.language]}</div>
                    <div className={styles.notificationListTime}>{intlFormatDistance(parseISO(notification.sendTime), new Date(), { locale: i18n.language })}</div>
                  </div>
                </div>
              ))
              : (
                <EmptySimple text={t('У вас нет непрочитанных уведомлений')} align="center" />
              )
          }
        </div>
      </div>
    </div>
  );
};

export default NotificationBar;
