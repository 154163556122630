import React, { useMemo } from 'react';
import cn from 'classnames';

import styles from './Modal.module.scss';
import Popup from 'reactjs-popup';
import Title from '../Title';
import Text from '../Text';
import { FiX } from 'react-icons/fi';

/**
 *
 * @param trigger
 * @param title
 * @param closeOnDocumentClick
 * @param {'primary'|'secondary'} variant
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
const Modal = ({
  open,
  onClose,
  trigger,
  title,
  subtitle,
  closeOnDocumentClick = true,
  variant = 'primary',
  children,
}) => {
  const headerClasses = useMemo(() => cn({
    [styles.modalHeader]: true,
    [styles.modalHeaderPrimary]: variant === 'primary',
    [styles.modalHeaderSecondary]: variant === 'secondary',
  }), [variant]);

  const closeButtonClasses = useMemo(() => cn({
    [styles.modalHeaderClose]: true,
    [styles.modalHeaderClosePrimary]: variant === 'primary',
    [styles.modalHeaderCloseSecondary]: variant === 'secondary',
  }), [variant]);

  const bodyClasses = useMemo(() => cn({
    [styles.modalBody]: true,
    [styles.modalBodyPrimary]: variant === 'primary',
    [styles.modalBodySecondary]: variant === 'secondary',
  }), [variant]);

  return (
    <Popup
      trigger={trigger}
      open={open}
      onClose={onClose}
      modal
      nested
      lockScroll
      closeOnDocumentClick={closeOnDocumentClick}
    >
      {(close) => (
        <div className={styles.modal}>
          <div className={headerClasses}>
            <Title level={variant === 'primary' ? 2 : 4}>{title}</Title>
            {subtitle && <div className={styles.modalSubtitle}>{subtitle}</div>}
            <button type="button" className={closeButtonClasses} onClick={close}>
              <FiX />
            </button>
          </div>
          <div className={bodyClasses}>
            {children(close)}
          </div>
        </div>
      )}
    </Popup>
  );
};

export default Modal;
