import React from 'react';
import { Controller } from 'react-hook-form';
import RangeSelect from './RangeSelect';
// import cn from 'classnames';

const SelectInputController = ({
  name,
  control,
  min,
  max,
  step,
  affix,
  ...rest
}) => {

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <RangeSelect
          {...field}
          {...rest}
          min={min}
          max={max}
          step={step}
          affix={affix}
        />
      )}
    />
  );
};

export default SelectInputController;
