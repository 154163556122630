import React, { useEffect } from 'react';

import styles from './CheckPackages.module.scss';
import { useTranslation } from 'react-i18next';
import { useGetPackageListQuery } from '../../../store/apis/tariffs';
import ContentLoader from 'react-content-loader';
import { useBookingData } from '../../../store/slicers/booking';
import BalanceCard from '../../balance/BalanceCard';
import { head } from 'ramda';
import Button from '../../../components/Button';

const CheckPackages = ({
  onConfirmBuyNewPackage,
  onRejectBuyNewPackage,
  isLoading,
}) => {
  const [t] = useTranslation();
  const bookingData = useBookingData();

  const {
    data: packages,
    isLoading: isPackagesLoading,
    isFetching: isPackagesFetching,
  } = useGetPackageListQuery({ page: -1 });

  const tutorPackages = packages?.results.filter((pack) => pack.tutor.id === bookingData.tutorId);
  const headPackage = head(tutorPackages ?? []);

  useEffect(() => {
    if (isPackagesLoading || isPackagesFetching) return;

    if (packages && !headPackage) return onConfirmBuyNewPackage();
  }, [headPackage, isPackagesFetching, isPackagesLoading, onConfirmBuyNewPackage, packages]);

  if (isPackagesLoading || isPackagesFetching) return (
    <ContentLoader
      backgroundColor={'#c8c8c8'}
      foregroundColor={'#eceaea'}
      width="760"
      height="340"
      speed={2}
    >
      <rect x="0" y="0" rx="12" ry="12" width="240px" height="100%" />
      <rect x="260" y="0" rx="12" ry="12" width="240px" height="100%" />
      <rect x="520" y="0" rx="12" ry="12" width="240px" height="100%" />
    </ContentLoader>
  );

  if (!headPackage) {
    return null;
  }

  return (
    <div className={styles.container}>
      <BalanceCard
        subject={headPackage.subjects?.[0]}
        tutor={headPackage.tutor}
        packs={tutorPackages}
        allTutors={[]}
        canDoPackageActions={false}
      />

      <div className={styles.buttons}>
        <div className={styles.buttonsItem}>
          <Button
            size="small"
            color="secondary"
            fullWidth
            onClick={onRejectBuyNewPackage}
            loading={isLoading}
          >
            {t('Только забронировать')}
          </Button>
        </div>
        <div className={styles.buttonsItem}>
          <Button
            size="small"
            color="primary"
            fullWidth
            onClick={onConfirmBuyNewPackage}
          >
            {t('Купить еще один пакет')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CheckPackages;
