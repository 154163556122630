import React from 'react';
import cn from 'classnames';

import styles from './TeacherCardSelectable.module.scss';
import { ReactComponent as ChatUser } from '../../icons/ChatUser.svg';
import { useTranslation } from 'react-i18next';
import { formatNumber } from '../../utils';
import Text from '../Text';
import Title from '../Title';
import Graduation from '../Graduation';
import { head } from 'ramda';
import Button from '../Button';
import { FiCheckCircle } from 'react-icons/fi';

const TeacherCardSelectable = ({
  teacher,
  onSelect,
}) => {
  const [t, i18n] = useTranslation();

  return (
    <div className={styles.card}>
      <div className={styles.cardPhoto}>
        <img src={teacher.photoUrl} alt="Фотография учителя" className={styles.cardPhotoImage} />
      </div>
      <div className={styles.cardBody}>
        <div className={styles.cardBodyTitles}>
          <div>
            <Title
              level={4}
              noMargin
            >
              {teacher.fullName[i18n.language]}
            </Title>
            <div className={styles.cardBodySubject}>
              <div className={styles.cardBodySubjectIcon}>
                <ChatUser />
              </div>
              <div className={styles.cardBodySubjectTitle}>
                {head(teacher.subjects)?.name[i18n.language]}
              </div>
            </div>
          </div>
          <div className={styles.cardBodyPrice}>
            <Title
              level={4}
              noMargin
            >
              {formatNumber(teacher.price)} {t('сум')}
            </Title>
            <Text level={3} color="secondary">{t('за 50 минут')}</Text>
          </div>
        </div>

        <div className={styles.cardBodyAbout}>
          <h5 className={styles.cardBodyAboutTitle}>{t('О преподавателе')}</h5>
          <Text level={3}>{teacher.introductionText?.[i18n.language]}</Text>
        </div>

        <div className={styles.cardBodyAction}>
          <Button
            size="small"
            icon={<FiCheckCircle />}
            iconPosition="start"
            onClick={onSelect}
            color="primary"
          >
            {t('Выбрать')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TeacherCardSelectable;
