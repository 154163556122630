import React, { useCallback, useState } from 'react';
import cn from 'classnames';

import styles from './PaymentMethodCard.module.scss';
import { useTranslation } from 'react-i18next';

import Text from '../../../components/Text';
import Divider from '../../../components/Divider';
import Button from '../../../components/Button';
import { formatNumber } from '../../../utils';
import { toast } from 'react-toastify';

const PaymentMethodCard = ({
  tariff,
  forTrialLesson = false,
  paymentOrder,
  amount,
  methods,
  onSuccess,
}) => {
  const [t, i18n] = useTranslation();
  const [chosenPaymentMethod, setChosenPaymentMethod] = useState(null);

  const chosePayment = useCallback((payment) => () => {
    setChosenPaymentMethod(payment);
  }, []);

  const paymentMethodButtonClasses = useCallback((payment) => cn({
    [styles.paymentMethodsButton]: true,
    [styles.paymentMethodsButtonActive]: chosenPaymentMethod === payment,
  }), [chosenPaymentMethod]);

  const pay = useCallback(() => {
    const chosenPayment = methods.find((m) => m.paymentSystem.alias === chosenPaymentMethod);
    if (chosenPayment && chosenPayment.checkoutUrl) {
      window.open(chosenPayment.checkoutUrl, '_blank');
      onSuccess();
      return;
    }

    toast.warning(t('Не выбрана платежная система или нет ссылки'));
  }, [onSuccess, chosenPaymentMethod, methods, t]);

  return (
    <div className={styles.payment}>
      <div className={styles.paymentTariff}>
        <Text level={3} color="secondary">
          {
            forTrialLesson
              ? t('Вам необходимо пополнить баланс для пробного урока', { n: amount })
              : t('Счет № {{order}} за тарифный план {{tariff}}', { order: paymentOrder, tariff: tariff.name[i18n.language] })
          }
        </Text>
      </div>
      <div className={styles.paymentData}>
        <div className={styles.paymentDataItem}>
          {
            forTrialLesson
              ? t('Пополнение баланса')
              : (
                <Text level={2}>{t('{{lessonCount}} занятий', { count: tariff.lessonCount })}</Text>
              )
          }
        </div>
        <div className={styles.paymentDataItem}>
          {
            forTrialLesson
              ? <Text level={2} bold>{t('{{amount}} сум', { amount: formatNumber(amount) })}</Text>
              : <Text level={2} bold>{t('{{amount}} сум', { amount: formatNumber(tariff.price.totalPrice) })}</Text>
          }
        </div>
      </div>
      {/*<div className={styles.paymentData}>*/}
      {/*  <div className={styles.paymentDataItem}>*/}
      {/*    <Text level={2}>{t('Сумма на балансе')}</Text>*/}
      {/*  </div>*/}
      {/*  <div className={styles.paymentDataItem}>*/}
      {/*    <Text level={2} bold>NO DATA</Text>*/}
      {/*  </div>*/}
      {/*</div>*/}
      <Divider />
      <div className={styles.paymentData}>
        <div className={styles.paymentDataItem}>
          <Text level={2} bold>{t('Сумма к оплате')}</Text>
        </div>
        <div className={styles.paymentDataItem}>
          <Text level={2} bold>{t('{{amount}} сум', { amount: formatNumber(amount) })}</Text>
        </div>
      </div>

      <div className={styles.paymentMethods}>
        <Text level={3} color="secondary">{t('Выберите удобный Вам способ оплаты')}</Text>
        <div className={styles.paymentMethodsWrapper}>
          {
            methods.map((method) => (
              <div className={styles.paymentMethodsItem} key={method.id}>
                <button
                  type="button"
                  className={paymentMethodButtonClasses(method.paymentSystem.alias)}
                  onClick={chosePayment(method.paymentSystem.alias)}
                >
                  <img src={method.paymentSystem.logoUrl} alt="Логотип платежной системы" className={styles.paymentMethodsImage} />
                </button>
              </div>
            ))
          }
        </div>
      </div>

      <div className={styles.paymentFooter}>
        <Button
          size="small"
          color="primary"
          onClick={pay}
          disabled={!chosenPaymentMethod}
          className="Button_GoToThePayment"
        >
          {t('Перейти к оплате')}
        </Button>
      </div>
    </div>
  );
};

export default PaymentMethodCard;
