import React, { useEffect, useState } from 'react';
import cn from 'classnames';

import styles from './CountDownTimer.module.scss';
import { differenceInSeconds } from 'date-fns';
import { formatSecondsToTimer } from '../../utils';

const CountDownTimer = ({
  endDate,
  label,
}) => {
  const now = new Date();
  const [diffSeconds, setDiffSeconds] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setDiffSeconds(differenceInSeconds(endDate, now));
    }, 1000);

    if (diffSeconds < 0) clearInterval(timer);

    return () => {
      clearInterval(timer);
    }
  }, [diffSeconds, endDate]);

  if (diffSeconds <= 0) return null;

  return (
    <span>
      {label}: {formatSecondsToTimer(diffSeconds)}
    </span>
  );
};

export default CountDownTimer;
