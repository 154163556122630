import React, { useCallback, useMemo } from 'react';
import cn from 'classnames';
import { ErrorCode, useDropzone } from 'react-dropzone';
import { PulseLoader } from 'react-spinners';
import styles from './AvatarUploader.module.scss';
import { FiPlus } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import { head } from 'ramda';
import { toast } from 'react-toastify';
import { useUploadMutation } from '../../../store/apis/files';
import { getFileDropError } from '../../../utils';

const AvatarUploader = ({
  label,
  id,
  title,
  descriptions,
  onChange,
  value,
  group = 'student_photos',
}, ref) => {
  const [t] = useTranslation();
  const [uploadFile, { isLoading, isFetching }] = useUploadMutation();

  const handleSelectFileError = useCallback((files) => {
    const firstFile = head(files);
    if (!firstFile || !firstFile.errors) return;
    firstFile.errors.forEach(({ code }) => {
      toast.error(getFileDropError(code));
    });
  }, []);

  const handleOnDropAccepted = useCallback(async (files) => {
    const formData = new FormData();
    const file = head(files);
    formData.append('file', file);
    formData.append('group', group);

    try {
      const data = await uploadFile(formData).unwrap();
      onChange(data);
      toast.success('Файл загружен');
    } catch (e) {
      console.log(JSON.stringify(e.data ?? e, 0, 2));
    }
  }, [group, onChange, uploadFile]);

  const {
    getRootProps,
    getInputProps,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    maxSize: 2097152,
    maxFiles: 1,
    multiple: false,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpg', '.jpeg'],
    },
    onDropRejected: handleSelectFileError,
    onDropAccepted: handleOnDropAccepted,
  });

  const dropzoneContainerClasses = useMemo(() => cn({
    [styles.dropzoneContainer]: true,
    [styles.dropzoneContainerAccept]: isDragAccept,
    [styles.dropzoneContainerReject]: isDragReject,
    dropzone: true,
  }), [isDragAccept, isDragReject]);

  const dropzoneClasses = useMemo(() => cn({
    [styles.dropzone]: true,
    [styles.loading]: isLoading || isFetching,
  }), [isLoading, isFetching]);

  return (
    <div className={dropzoneClasses}>
      <label htmlFor={id} className={styles.dropzoneLabel}>{label}</label>
      <section {...getRootProps({ className: dropzoneContainerClasses })}>
        <input
          {...getInputProps()}
          ref={ref}
          style={{
            position: 'absolute',
            zIndex: 20,
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            opacity: 0,
            cursor: 'pointer',
          }}
        />
        <div className={styles.dropzoneButton}>
          {
            (isLoading || isFetching) && (
                <span className={styles.dropzoneButtonLoader}>
                  <PulseLoader color="#536DFE" />
                </span>
              )
          }
          {
            value ? (
              <span className={styles.dropzoneButtonImage}>
                <img src={value.url} alt="" />
              </span>
            ) : (
              <span className={styles.dropzoneButtonPlus}>
                <FiPlus />
              </span>
            )
          }
        </div>
        <div className={styles.dropzoneHint}>
          <p className={styles.dropzoneHintTitle}>{title}</p>
          {
            descriptions?.map((description, i) => (
              <p className={styles.dropzoneHintDescription} key={i}>{description}</p>
            ))
          }
        </div>
      </section>
    </div>
  );
};

export default React.forwardRef(AvatarUploader);
