import React from 'react';

import styles from './BalanceCard.module.scss';
import { FiFeather } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import UserInfoSmall from '../../../components/UserInfoSmall';
import PostReviewModal from '../PostReviewModal';
import TopupTutorPack from '../TopupTutorPack';
import PackageCard from '../PackageCard';
import { useGetLessonsQuery } from '../../../store/apis/lessons/scheduled';
import Button from '../../../components/Button';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import appRoutes from '../../../app/appRoutes';

const BalanceCard = ({
  subject,
  tutor,
  packs,
  allTutors,
  canDoPackageActions = true,
  withoutCreditRow = false,
}) => {
  const [t, i18n] = useTranslation();

  return (
    <div className={styles.card}>
      <div className={styles.cardSubject}>
        <span className={styles.cardSubjectItem}>
          <FiFeather /> {subject?.name[i18n.language]}
        </span>
      </div>

      <div className={styles.cardTutor}>
        <Link to={appRoutes.tutors(tutor.id)} className={styles.cardTutorLink}>
          <UserInfoSmall
            photoUrl={tutor.photoUrl}
            name={tutor.fullName?.[i18n.language]}
            message={tutor.shortInfo[i18n.language]}
          />
        </Link>
      </div>

      {/*<div className={styles.cardBalance}>*/}
      {/*  <div className={styles.cardBalanceTitle}>{pricePerLesson} {t('{{amount}} сум', { amount: '60 000' })}</div>*/}
      {/*  <div className={styles.cardBalanceText}>{t('за 50 мин урока')}</div>*/}
      {/*</div>*/}

      {
        canDoPackageActions && (
         <>
           <div className={styles.cardActions} style={{ paddingRight: 8 }}>
             <PostReviewModal
               tutorId={tutor.id}
             />
           </div>

           {
             tutor?.isPublished ? (
               <div className={styles.cardActions} style={{ paddingLeft: 8 }}>
                 <TopupTutorPack
                   tutorId={tutor.id}
                   subjects={[subject]}
                 />
               </div>
             ) : (
               <div className={styles.cardActions} style={{ paddingLeft: 8 }}>
                 <div className={styles.cardActionsDisabled}>
                   {t('Учитель неактивен')}
                 </div>
               </div>
             )
           }
         </>
        )
      }

      {
        packs.map((pack) => (
          <PackageCard
            key={pack.id}
            tutor={tutor}
            pack={pack}
            allTutors={allTutors}
            paidTime={pack.paidAtTime}
            packId={pack.id}
            tariffName={pack.tariff.name[i18n.language]}
            lessonCount={pack.lessonCount}
            price={pack.price}
            consumedLessonCount={pack.consumedLessonCount}
            pricePerLesson={pack.pricePerLesson}
            balance={pack.balance}
            canDoPackageActions={canDoPackageActions}
            withoutCreditRow={withoutCreditRow}
          />
        ))
      }
    </div>
  );
};

export default BalanceCard;
