import React, { useMemo } from 'react';
import cn from 'classnames';

import styles from './Container.module.scss';

const Container = ({
  children,
  className,
}) => {
  const containerClasses = useMemo(() => cn({
    [styles.container]: true,
    [className]: className,
  }));

  return (
    <div className={containerClasses}>
      {children}
    </div>
  );
};

export default Container;
