import React, { useCallback } from 'react';
import cn from 'classnames';

import styles from './ChatMessageForm.module.scss';
import { FiSend } from 'react-icons/fi';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { usePostChatMessageMutation } from '../../../store/apis/chats';
import { addNewMessage, useChat } from '../../../store/slicers/chat';
import { useDispatch } from 'react-redux';
import { useHotkeys } from 'react-hotkeys-hook';
import TextareaAutosize from 'react-textarea-autosize';

const ChatMessageForm = () => {
  const [t] = useTranslation();
  const chat = useChat();
  const dispatch = useDispatch();

  const [postMessage, { isLoading, isFetching }] = usePostChatMessageMutation();

  const {
    handleSubmit: submit,
    register,
    formState,
    setValue,
    getValues,
    setFocus,
    reset,
  } = useForm({
    defaultValues: {
      text: null,
    },
  });

  const handleSubmit = useCallback(async (values) => {
    if (!values.text) return;
    try {
      const message = await postMessage({ text: values.text, chatId: chat.activeChatId })
        .unwrap();
      dispatch(addNewMessage({ chatId: chat.activeChatId, message }));
      setValue('text', null, { shouldDirty: true });
      setFocus('text');
    } catch (e) {
      console.log(JSON.stringify(e.data ?? e, 0, 2));
    }
  }, [chat.activeChatId, setFocus, dispatch, postMessage, setValue]);

  const fieldRef = useHotkeys(['enter', 'ctrl+enter', 'meta+enter'], (_, hotkey) => {
    if (!hotkey.ctrl && !hotkey.meta) {
      submit(handleSubmit)();
      return;
    }
    const currentValue = getValues(['text']);
    setValue('text', `${currentValue}\n`);
  }, {
    enableOnFormTags: true,
    preventDefault: true,
  });

  return (
    <form
      onSubmit={submit(handleSubmit)}
      className={styles.windowChatForm}
      ref={fieldRef}
    >
      <TextareaAutosize
        className={styles.windowChatFormInput}
        {...register('text')}
        placeholder={t('Введите сообщение здесь')}
        disabled={isFetching || isLoading}
        maxRows={5}
        minRows={1}
      />
      <button
        type="submit"
        className={styles.windowChatFormSubmit}
        disabled={isFetching || isLoading || !formState.isDirty}
      >
        <FiSend />
      </button>
    </form>
  );
};

export default ChatMessageForm;
