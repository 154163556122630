import React, { useCallback } from 'react';
import styles from './PhoneConfirmation.module.scss';
import { Navigate, useLocation } from 'react-router';
import appRoutes from '../../../app/appRoutes';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Title from '../../../components/Title';
import Text from '../../../components/Text';
import ConfirmationPhoneInputController
  from '../../../components/ConfirmationPhoneInput/ConfirmationPhoneInputController';
import Button from '../../../components/Button';
import { useNavigate } from 'react-router-dom';
import { useVerifyPhoneMutation } from '../../../store/apis/auth';
import { createDataSchema } from '../../../yup';
import { string } from 'yup';
import { trim } from 'ramda';
import { yupResolver } from '@hookform/resolvers/yup';
import localforage from 'localforage';
import useQueryString from '../../../hooks/useQueryString';
import queryString from 'query-string';

const schema = createDataSchema({
  code: string().trim().required().min(6),
});

const PhoneConfirmation = () => {
  const [t] = useTranslation();
  const { state } = useLocation();
  const query = useQueryString();
  const navigate = useNavigate();
  const [verifyPhone, { isLoading, isFetching }] = useVerifyPhoneMutation();

  const {
    control,
    handleSubmit: submit,
    watch,
  } = useForm({
    defaultValues: state,
    resolver: yupResolver(schema),
  });

  const handleSubmit = useCallback(async (values) => {
    try {
      const credentials = await verifyPhone(values).unwrap();
      await localforage.setItem('credentials', { accessToken: credentials.token });
      navigate({
        pathname: appRoutes.setPassword(),
        search: queryString.stringify(query),
      });
    } catch (e) {
      console.log(JSON.stringify(e.data ?? e, 0, 2));
    }
  }, [navigate, verifyPhone]);

  if (!state) return <Navigate to={appRoutes.register()} replace/>;
  const hiddenPhoneNumber = state.phoneNumber.replace(/\d(?=(?:\D*\d){4})/g, '*');

  return (
    <div className={styles.confirmation}>
      <form onSubmit={submit(handleSubmit)}>
        <div className={styles.confirmationHeader}>
          <Title level={3}>{t('Подтверждение номера телефона')}</Title>
          <Text level={2} color="secondary">{t('Код был отправлена на номер +{{phone}}', { phone: hiddenPhoneNumber })}</Text>
        </div>
        <div className={styles.confirmationBody}>
          <ConfirmationPhoneInputController control={control} name="code" />
          <Button
            type="submit"
            fullWidth
            disabled={
              !watch('code') || trim(watch('code')).length < 6
            }
            loading={isLoading || isFetching}
            className="Button_button__JBBzO_Keep"
          >
            {t('Продолжить')}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default PhoneConfirmation;
