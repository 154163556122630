import apiRoutes from '../../../apiRoutes';
import { mainApi } from '../index';

mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getLanguages: builder.query({
      query: (params) => ({
        url: apiRoutes.languages(),
        params
      }),
    })
  })
});

export const {
  useGetLanguagesQuery,
} = mainApi;
