import React, { useCallback, useMemo, useState } from 'react';

import styles from './BuyPackageModal.module.scss';
import { FiCheckCircle } from 'react-icons/fi';
import Button from '../../../components/Button';
import { useTranslation } from 'react-i18next';
import useToggle from '../../../hooks/useToggle';
import Modal from '../../../components/Modal';
import {
  useBookWithoutBuyMutation,
  useBuyPackageMutation,
  useCheckScheduleMutation
} from '../../../store/apis/tariffs';
import Condition from '../../../components/Condition';
import PaymentMethodCard from '../PaymentMethodCard';
import { isEmpty, isNotNil, not, omit, pluck } from 'ramda';
import { clearBooking, setTariff, useBookingData } from '../../../store/slicers/booking';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import TariffChoiceList from '../TariffChoiceList';
import Title from '../../../components/Title';
import BookScheduleConfirm from '../BookScheduleConfirm';
import CheckPackages from '../CheckPackages';

const BuyPackageModal = ({ tutorPrice, tutorId }) => {
  const [t] = useTranslation();
  const [isOpen, show, hide] = useToggle();
  const dispatch = useDispatch();

  const [tab, setTab] = useState('confirm');
  const [chosenTariff, setChosenTariff] = useState(null);
  const [payment, setPayment] = useState(null);

  const bookingData = useBookingData();

  const [
    buyPackage,
    {
      isLoading: isBuyPackageLoading,
      isFetching: isBuyPackageFetching,
    }
  ] = useBuyPackageMutation();

  const [
    checkSchedule,
    {
      isLoading: isScheduleChecking,
    }
  ] = useCheckScheduleMutation();

  const [
    bookWithoutBuy,
    {
      isLoading: isBookWithoutBuyLoading,
      isFetching: isBookWithoutBuyFetching,
    }
  ] = useBookWithoutBuyMutation();

  const _generateBody = (appendToBody = {}) => {
    const rawSchedule = {
      type: bookingData.scheduleType,
      subject: bookingData.subject,
      startTime: bookingData.startLesson,
      lessonsSpan: bookingData.lessonsSpan,
      lessonsTime: pluck('value', bookingData.lessonsTime),
    };

    return {
      ...appendToBody,
      tutorId: bookingData.tutorId,
      schedule: bookingData.scheduleType === 1
        ? omit(['lessonsTime'], rawSchedule)
        : omit(['lessonsSpan', 'startTime'], rawSchedule),
    };
  };

  const handleClickBooking = useCallback(() => {
    if (!bookingData.scheduleType) {
      toast.warning(t('Сначала выберите расписание'));
      return;
    }

    if (bookingData.scheduleType === 2 && (!bookingData.lessonsTime || bookingData.lessonsTime.length === 0)) {
      toast.warning(t('Сначала выберите время уроков'));
      return;
    }

    if (bookingData.scheduleType === 1 && !bookingData.startLesson) {
      toast.warning(t('Вам нужно выбрать дату с которой вы хотите начать обучаться по недельному расписанию'));
      return;
    }
    if (!tutorPrice) {
      toast.warning(t('Похоже что этого учителя нельзя забронировать, так как у него не установлена цена'));
      return;
    }
    show();
  }, [bookingData, show, t, tutorPrice]);


  const chooseTariff = useCallback(async (tariff) => {
    setChosenTariff(tariff);
    dispatch(setTariff(tariff.id));

    const body = _generateBody({ tariff: tariff.id });

    try {
      const data = await buyPackage(body).unwrap();
      setPayment(data);
      setTab('paymentMethod');
      dispatch(clearBooking());
    } catch (e) {
      console.log(JSON.stringify(e.data ?? e, 0, 2));
    }
  }, [bookingData, buyPackage, dispatch]);

  const modalTitles = useMemo(() => ({
    confirm: {
      title: t('Подтверждение расписания'),
      subtitle: t('Проверьте выбранное Вами расписание'),
    },
    checkPackages: {
      title: t('Активные купленные пакеты'),
      subtitle: t('У Вас уже есть купленный пакет для учителя Вы хотите купить еще один пакет?'),
    },
    tariff: {
      title: t('Тарифный план'),
      subtitle: t('Выберите план, который соответствует Вашим потребностям'),
    },
    paymentMethod: {
      title: t('Способ оплаты'),
      subtitle: null,
    },
  }), [t]);

  const handleCloseModal = useCallback(() => {
    setTab('confirm');
    dispatch(clearBooking());
    hide();
  }, [hide]);

  const handleConfirmBookingData = useCallback(async () => {
    try {
      const body = _generateBody();
      await checkSchedule(body).unwrap();
      setTab('checkPackages');
      window.fbq('trackCustom', 'BookLesson');
    } catch (e) {
      console.log(JSON.stringify(e.data ?? e, 0, 2));
    }
  }, [bookingData]);

  const handleCancelConfirmation = useCallback(() => {
    dispatch(clearBooking());
    handleCloseModal();
  }, [dispatch, handleCloseModal]);

  const handleBuyNewPackage = useCallback(() => {
    setTab('tariff');
  }, []);

  const handleBookScheduleWithoutBuy = useCallback(async () => {
    const body = _generateBody();
    try {
      await bookWithoutBuy(body).unwrap();
      handleCloseModal();
      toast.success(t('Время учителя было забронировано, следите за расписанием'));
    } catch (e) {
      console.log(JSON.stringify(e.data ?? e, 0, 2));
    }
  }, [bookWithoutBuy, bookingData.lessonsSpan, bookingData.lessonsTime, bookingData.scheduleType, bookingData.startLesson, bookingData.subject, bookingData.tutorId, handleCloseModal, t]);

  return (
    <div>
      <Button
        size="small"
        fullWidth
        icon={<FiCheckCircle />}
        iconPosition="start"
        onClick={handleClickBooking}
        className="Button_BookPaidLesson"
      >
        {t('Забронировать')}
      </Button>
      <Modal
        title={modalTitles[tab].title}
        open={isOpen}
        onClose={handleCloseModal}
        closeOnDocumentClick={false}
        subtitle={modalTitles[tab].subtitle}
        variant="secondary"
      >
        {(close) => (
          <div>
            <Condition when={tab} is="confirm">
              <BookScheduleConfirm
                onConfirm={handleConfirmBookingData}
                onCancel={handleCancelConfirmation}
                confirmLoading={isScheduleChecking}
              />
            </Condition>
            <Condition when={tab} is="checkPackages">
              <CheckPackages
                onConfirmBuyNewPackage={handleBuyNewPackage}
                onRejectBuyNewPackage={handleBookScheduleWithoutBuy}
                isLoading={isBookWithoutBuyLoading || isBookWithoutBuyFetching}
              />
            </Condition>
            <Condition when={tab} is="tariff">
              <TariffChoiceList
                tutorPrice={tutorPrice}
                tutorId={tutorId}
                onChoose={chooseTariff}
                btnLoading={isBuyPackageLoading}
              />
            </Condition>
            <Condition when={tab} is="paymentMethod">
              <Condition when={isNotNil(chosenTariff) && not(isEmpty(chosenTariff))} is={true}>
                {
                  isBuyPackageLoading || isBuyPackageFetching
                    ? (
                      <Title level={4}>
                        {t('Получаю данные оплаты')}. {t('Пожалуйста, подождите')}
                      </Title>
                    ) : (
                      <div className={styles.paymentWrapper}>
                        <PaymentMethodCard
                          tariff={chosenTariff}
                          paymentOrder={payment?.orderId}
                          amount={payment?.amount}
                          methods={payment?.paymentWays}
                          onSuccess={() => {
                            window.fbq('trackCustom', 'Buy');
                            close();
                          }}
                        />
                      </div>
                    )
                }
              </Condition>
            </Condition>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default BuyPackageModal;
