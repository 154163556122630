import apiRoutes from '../../../apiRoutes';
import { invalidateOn } from '../../utils';
import { mainApi } from '../index';

mainApi.injectEndpoints({
  endpoints: (build) => ({
    getScheduledLessons: build.query({
      query: (params) => ({
        url: apiRoutes.scheduledLessons(),
        params,
      }),
      providesTags: ['SCHEDULES'],
    }),
    getLessonSchedules: build.query({
      query: (params) => ({
        url: apiRoutes.lessonSchedules(),
        params,
      }),
      providesTags: ['LESSON_SCHEDULES'],
    }),
    getRescheduledLessonList: build.query({
      query: (params) => ({
        url: apiRoutes.rescheduledLessonList(),
        params,
      }),
      providesTags: ['SCHEDULES'],
    }),
    getCancelledList: build.query({
      query: (params) => ({
        url: apiRoutes.cancelledLessonList(),
        params,
      }),
      providesTags: ['SCHEDULES'],
    }),

    cancelLesson: build.mutation({
      query: ({ scheduleId, body }) => ({
        url: apiRoutes.cancelLesson(scheduleId),
        method: 'POST',
        body,
      }),
      invalidatesTags: invalidateOn({ success: ['SCHEDULES'], error: ['SCHEDULES'] }),
    }),

    rescheduleLesson: build.mutation({
      query: ({ scheduleId, body }) => ({
        url: apiRoutes.rescheduleLesson(scheduleId),
        method: 'POST',
        body,
      }),
      invalidatesTags: invalidateOn({ success: ['SCHEDULES'], error: ['SCHEDULES'] }),
    }),

    approveReschedule: build.mutation({
      query: (rescheduleId) => ({
        url: apiRoutes.approveReschedule(rescheduleId),
        method: 'POST',
      }),
      invalidatesTags: invalidateOn({ success: ['SCHEDULES']}),
    }),
    rejectReschedule: build.mutation({
      query: (rescheduleId) => ({
        url: apiRoutes.rejectReschedule(rescheduleId),
        method: 'POST',
      }),
      invalidatesTags: invalidateOn({ success: ['SCHEDULES']}),
    }),

    getLessons: build.query({
      query: (params) => ({
        url: apiRoutes.lessons(),
        params,
      }),
      providesTags: ['LESSONS'],
    }),

    getTrialLessons: build.query({
      query: (params) => ({
        url: apiRoutes.availableTrialLesson(),
        params,
      }),
      providesTags: ['TRIAL_LESSONS'],
    }),
    bookTrialLesson: build.mutation({
      query: (body) => ({
        url: apiRoutes.bookTrialLesson(),
        method: 'POST',
        body,
      }),
      // invalidatesTags: invalidateOn({ success: ['']}),
    }),
  }),
});

export const {
  useGetScheduledLessonsQuery,
  useGetLessonSchedulesQuery,
  useLazyGetScheduledLessonsQuery,
  useCancelLessonMutation,
  useRescheduleLessonMutation,
  useGetRescheduledLessonListQuery,
  useApproveRescheduleMutation,
  useRejectRescheduleMutation,
  useGetCancelledListQuery,
  useLazyGetCancelledListQuery,
  useGetLessonsQuery,
  useGetTrialLessonsQuery,
  useBookTrialLessonMutation,
} = mainApi;

