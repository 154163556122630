import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import localforage from 'localforage';
import appRoutes from '../app/appRoutes';

const ProtectRoute = ({ children }) => {
  const navigate = useNavigate();
  const [authenticated, setAuthenticated] = useState(null);

  useEffect(() => {
    localforage.getItem('credentials')
      .then((credentials) => {
        if (!credentials || !credentials.accessToken) {
          navigate(appRoutes.login(), { replace: true });
        } else {
          setAuthenticated(true);
        }
      })
      .catch((err) => {
        console.log(err);
        navigate(appRoutes.login(), { replace: true });
      });
  }, [navigate]);

  if (authenticated === null) return null;
  return authenticated ? children : null;
};

export default ProtectRoute;
