import React, { useCallback, useMemo, useState } from 'react';

import Text from '../../../components/Text';

import styles from './TakeTrialLesson.module.scss';
import { useTranslation } from 'react-i18next';
import { FiPocket } from 'react-icons/fi';
import Button from '../../../components/Button';
import Modal from '../../../components/Modal';
import TutorScheduleSingleLesson from '../TutorScheduleSingleLesson';
import { getHeadIfExists } from '../../../utils';
import { useBookTrialLessonMutation, useGetTrialLessonsQuery } from '../../../store/apis/lessons/scheduled';
import { toast } from 'react-toastify';
import { head, includes, pluck } from 'ramda';
import { addHours, addMinutes, formatISO, parseISO } from 'date-fns';
import Condition from '../../../components/Condition';
import PaymentMethodCard from '../PaymentMethodCard';
import { useDispatch } from 'react-redux';
import { mainApi } from '../../../store/apis';
import { useGetServiceConfigsQuery } from '../../../store/apis/serviceConfigs';

const TakeTrialLesson = ({
  tutorId,
  subject,
}) => {
  const [t, i18n] = useTranslation();
  const [selectedSlots, setSelectedSlots] = useState([]);
  const [step, setStep] = useState('schedule');
  const [invoiceInfo, setInvoiceInfo] = useState({});
  const dispatch = useDispatch();

  const [
    bookTrial,
    { isLoading, isFetching },
  ] = useBookTrialLessonMutation();

  const {
    data: serviceConfig,
    isFetching: isServiceConfigFetching,
  } = useGetServiceConfigsQuery();

  const MINUTES_ADD_TO_SCHEDULE = serviceConfig ? serviceConfig.newScheduleMinutesBeforeStart : 24 * 60;

  const today = useMemo(() =>
      addMinutes(
        new Date(),
        MINUTES_ADD_TO_SCHEDULE,
      ),
    [MINUTES_ADD_TO_SCHEDULE]);

  const {
    data: availableTrialLessons,
    isLoading: isAvailableTrialLessonsLoading,
    isFetching: isAvailableTrialLessonsFetching,
  } = useGetTrialLessonsQuery({ page: -1 });

  const selectTimeSlot = useCallback((slots) => {
    setSelectedSlots(slots);
  }, []);

  const bookTrialLesson = useCallback((close) => async () => {
    const headSlot = getHeadIfExists(selectedSlots);

    if (!headSlot) {
      toast.warning(t('Выберите дату начала первого урока'));
      return;
    }
    const body = {
      subjectId: subject?.id,
      tutorId: tutorId,
      lessonTime: formatISO(parseISO(headSlot.value)),
    };
    try {
      const invoice = await bookTrial(body).unwrap();
      setInvoiceInfo(invoice);
      setStep('payment');
      // toast.success(t('Пробный урок забронирован, следите за расписанием'));
      // close();
    } catch (e) {
      console.log(JSON.stringify(e.data ?? e, 0, 2));
    }
  }, [bookTrial, selectedSlots, subject?.id, t, tutorId]);

  if (isAvailableTrialLessonsLoading || isAvailableTrialLessonsFetching) return null;

  const canBookTrialLesson = availableTrialLessons?.results
    ? includes(subject?.id, pluck('id', availableTrialLessons.results))
    : false;

  if (!subject) return null;
  if (!canBookTrialLesson) return null;

  return (
    <div className={styles.trialLesson}>
      <div className={styles.wrapper}>
        <Text level={3}>{t('У Вас есть пробный урок по предмету {{subject}}', { subject: subject?.name[i18n.language] })}</Text>
        <Text level={3}>{t('Хотите попробовать?')}</Text>

        <Modal
          trigger={
            <Button
              size="small"
              fullWidth
              icon={<FiPocket />}
              iconPosition="start"
              color="warning"
              className="Button_GetTrialLesson"
            >
              {t('Получить пробный урок')}
            </Button>
          }
          onClose={() => {
            dispatch(mainApi.util.invalidateTags(['TRIAL_LESSONS', 'SCHEDULES', 'UNPAID_INVOICES']))
          }}
          title={t('Забронировать пробный урок')}
          subtitle={t('Выберите дату и время пробного урока у этого преподавателя')}
          variant="secondary"
        >
          {(close) => (
            <div className={styles.modal}>
              <Condition when={step} is="schedule">
                <TutorScheduleSingleLesson
                  tutorId={tutorId}
                  multiple={false}
                  onSelectedTimeSlotsChange={selectTimeSlot}
                  selectedTimeSlots={selectedSlots}
                  defaultSelectedDate={today}
                />
                <div className={styles.modalFooter}>
                  <Button
                    onClick={bookTrialLesson(close)}
                    disabled={!selectedSlots || !subject}
                    loading={isLoading || isFetching}
                    className="Button_LessonBook"
                  >
                    {t('Забронировать')}
                  </Button>
                </div>
              </Condition>

              <Condition when={step} is="payment">
                <PaymentMethodCard
                  tariff={null}
                  forTrialLesson
                  paymentOrder={invoiceInfo?.orderId}
                  amount={invoiceInfo?.amount}
                  methods={invoiceInfo?.paymentWays}
                  onSuccess={() => {
                    setStep('schedule');
                    toast.success(t('Пробный урок забронирован, следите за расписанием'));
                    window.fbq('trackCustom', 'Trial');
                    close();
                  }}
                />
              </Condition>
            </div>
          )}
        </Modal>
      </div>
    </div>
  );
};

export default TakeTrialLesson;
