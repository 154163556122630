import apiRoutes from '../../apiRoutes';
import { mainApi } from './index';

mainApi.injectEndpoints({
  endpoints: (build) => ({
    getServiceConfigs: build.query({
      query: (params) => ({
        url: apiRoutes.serviceConfig(),
        params,
      }),
    }),
    getUserScheduleLimit: build.query({
      query: (params) => ({
        url: apiRoutes.userScheduleLimit(),
        params,
      }),
      providesTags: ['USER_SCHEDULE_LIMIT'],
    }),
  }),
})

export const {
  useGetServiceConfigsQuery,
  useGetUserScheduleLimitQuery,
} = mainApi;
