import React from 'react';
import cn from 'classnames';

import styles from './LessonDates.module.scss';
import { FiCalendar, FiClock } from 'react-icons/fi';

const LessonDates = ({ date, time }) => {

  return (
    <div className={styles.dates}>
      <span className={styles.datesItem}>
        <FiCalendar /> {date}
      </span>
      <span className={styles.datesItem}>
        <FiClock /> {time}
      </span>
    </div>
  );
};

export default LessonDates;
