import { useEffect } from 'react';
import { socket } from '../socket';
import { useDispatch } from 'react-redux';
import useSound from 'use-sound';
import {
  addNewFile,
  addNewMessage,
  incrementUnreadFile,
  incrementUnreadMessage,
  setLastMessage,
  useChat
} from '../store/slicers/chat';
import { useReadAllFilesMutation, useReadAllMessagesMutation } from '../store/apis/chats';
import chatSound from '../sounds/chat-sound.wav';
import { addNotification } from '../store/slicers/notificationsSlice';
import { useLazyGetUserProfileQuery } from '../store/apis/profile';

export default () => {
  const dispatch = useDispatch();
  const chat = useChat();
  const [play] = useSound(chatSound);

  const [readAllMessages] = useReadAllMessagesMutation();
  const [readAllFiles] = useReadAllFilesMutation();
  const [getProfile] = useLazyGetUserProfileQuery();


  useEffect(() => {
    // socket.connect();
    const onTextMessage = (message) => {
      const { chatId } = message;
      dispatch(addNewMessage({ chatId, message }));
      dispatch(setLastMessage({ chatId, message }));
      if (chat.activeChatId === chatId && chat.activeTabId === 1) {
        readAllMessages(chatId);
        return;
      }
      dispatch(incrementUnreadMessage(chatId));
      play();
    };

    const onFileMessage = (file) => {
      const { chatId } = file;
      dispatch(addNewFile({ chatId, file }));
      if (chat.activeChatId === chatId && chat.activeTabId === 2) {
        readAllFiles(chatId);
        return;
      }
      dispatch(incrementUnreadFile(chatId));
      play();
    };

    const onSystemNotification = (notification) => {
      dispatch(addNotification(notification));
    };

    socket.on('textMessage', onTextMessage);
    socket.on('fileMessage', onFileMessage);
    socket.on('SystemNotification', onSystemNotification);

    const handleConnectionError = async (err) => {
      console.log('Ошибка подключения сокета: ', err);
      await getProfile();
      setTimeout(() => {
        console.log('try to reconnect socket');
        socket.disconnect();
        socket.connect();
      }, 500)
    };

    const handleConnect = () => {
      console.log('socket connected');
    };

    const handleDisconnect = () => {
      console.log('socket disconnected');
    };

    socket.on('connect_error', handleConnectionError);
    socket.on('connect', handleConnect);
    socket.on('disconnect', handleDisconnect);

    return () => {
      socket.off('textMessage', onTextMessage);
      socket.off('fileMessage', onFileMessage);
      socket.off('SystemNotification', onSystemNotification);

      socket.off('connect_error', handleConnectionError);
      socket.off('connect', handleConnect);
      socket.off('disconnect', handleDisconnect);
    }
  }, [
    play,
    chat.activeChatId,
    chat.activeTabId,
    dispatch,
    readAllMessages,
    readAllFiles,
  ]);

  return socket;
};
