import React from 'react';
import cn from 'classnames';

import styles from './LanguageSkillTag.module.scss';
import Popup from 'reactjs-popup';

const LanguageSkillTag = ({
  language,
  level,
  description,
}) => {

  return (
    <div className={styles.language}>
      <div className={styles.languageName}>{language}</div>
      <Popup
        trigger={
          <div className={styles.languageLevel}>{level}</div>
        }
        position="top center"
        closeOnDocumentClick
        on={['hover', 'click']}
      >
        <div className={styles.languageDescription}>
          {description}
        </div>
      </Popup>
    </div>
  );
};

export default LanguageSkillTag;
