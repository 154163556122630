import React, { useCallback, useMemo, useState } from 'react';

import styles from './TutorScheduleSingleLesson.module.scss';
import { addHours, addMonths, eachDayOfInterval, endOfDay, isSameDay, parseISO, startOfDay } from 'date-fns';
import { dateToISO, formatTime } from '../../../utils';
import { useGetTutorsSingleSchedulesQuery } from '../../../store/apis/tutors/tutorListApi';
import CalendarSwiperDays from '../../../components/CalendarSwiperDays';
import SlotSelect from '../../../components/SlotSelect';
import { uniq } from 'ramda';

const TutorScheduleSingleLesson = ({
  defaultSelectedDate,
  tutorId,
  onSelectedTimeSlotsChange,
  selectedTimeSlots = [],
  multiple = true,
  max = 99,
  disabled,
  onTryClick,
}) => {
  const today = useMemo(() => defaultSelectedDate ?? new Date(), [defaultSelectedDate]);
  const intervalDays = useMemo(() =>
    eachDayOfInterval({ start: today, end: addMonths(today, 2) }),
    [today],
  );
  const [selectedDay, setSelectedDay] = useState({
    fromTime: dateToISO(addHours(today, 0), true),
    toTime: dateToISO(endOfDay(today), true),
    date: today,
  });

  const {
    data: tutorSchedule,
    isLoading: isScheduleLoading,
    isFetching: isScheduleFetching,
    error: tutorScheduleError,
  } = useGetTutorsSingleSchedulesQuery({
    tutorId,
    fromTime: selectedDay.fromTime,
    toTime: selectedDay.toTime,
  });

  const selectDay = useCallback((newSelectedDate) => {
    const isSelectedDaySameToday = isSameDay(newSelectedDate, today);
    const fromTime = isSelectedDaySameToday
      ? dateToISO(addHours(today, 0), true)
      : dateToISO(startOfDay(newSelectedDate), true);
    const toTime = dateToISO(endOfDay(newSelectedDate), true);

    setSelectedDay({ fromTime, toTime, date: newSelectedDate });
  }, [today]);

  const slots = tutorSchedule
    ? tutorSchedule.map((dateISO) => ({
        value: dateISO,
        label: formatTime(dateISO),
      }))
    : [];

  return (
    <div className={styles.schedule}>
      <div className={styles.scheduleDays}>
        <CalendarSwiperDays
          days={intervalDays}
          onChange={selectDay}
          value={selectedDay.date}
        />
        <SlotSelect
          isLoading={isScheduleLoading || isScheduleFetching}
          slots={uniq(slots)}
          onChange={onSelectedTimeSlotsChange}
          value={selectedTimeSlots}
          multiple={multiple}
          checkedSlotDisabled={false}
          uncheckedSlotDisabled={selectedTimeSlots.length >= max}
          disabled={disabled}
          onTryClick={onTryClick}
        />
      </div>
    </div>
  );
};

export default TutorScheduleSingleLesson;
