import React, { useCallback } from 'react';

import styles from './TutorDetails.module.scss';
import Container from '../../../components/Container';
import TutorDetailCard from '../../../containers/tutor/TutorDetailCard';
import { useTranslation } from 'react-i18next';
import { useGetTutorDetailsQuery } from '../../../store/apis/tutors/tutorListApi';
import { useParams } from 'react-router';
import ContentLoader from 'react-content-loader';
import { getHeadIfExists } from '../../../utils';
import TutorDetailGoals from '../../../containers/tutor/TutorDetailGoals';
import TutorAbout from '../../../containers/tutor/TutorAbout';
import TutorBioTabs from '../../../containers/tutor/TutorBioTabs';
import Divider from '../../../components/Divider';
import TutorReviewsList from '../../../containers/tutor/TutorReviewsList';
import StickyBox from 'react-sticky-box';
import ReactPlayer from 'react-player';
import TutorActions from '../../../containers/tutor/TutorActions';
import { useDispatch } from 'react-redux';
import BuyPackageModal from '../../../containers/tutor/BuyPackageModal';
import Button from '../../../components/Button';
import { FiSend } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { useStartDialogMutation } from '../../../store/apis/chats';
import { openChat } from '../../../store/slicers/chat';
import appRoutes from '../../../app/appRoutes';
import TutorSchedule from '../../../containers/tutor/TutorSchedule';
import TakeTrialLesson from '../../../containers/tutor/TakeTrialLesson';
import { useGetTrialLessonsQuery } from '../../../store/apis/lessons/scheduled';
import { includes, pluck } from 'ramda';
import { toast } from 'react-toastify';

const TutorDetails = () => {
  const [t, i18n] = useTranslation();
  const { id: tutorId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    data: tutor,
    isLoading: isTutorLoading,
    isFetching: isTutorFetching,
    error: tutorError,
  } = useGetTutorDetailsQuery(tutorId);

  const [
    startDialog,
    {
      isLoading: isStartDialogLoading,
      isFetching: isStartDialogFetching,
    },
  ] = useStartDialogMutation();

  const {
    data: availableTrialLessons,
    isLoading: isAvailableTrialLessonsLoading,
    isFetching: isAvailableTrialLessonsFetching,
  } = useGetTrialLessonsQuery({ page: -1 });


  const canBookTrialLesson = availableTrialLessons?.results
    ? includes(getHeadIfExists(tutor?.subjects)?.id, pluck('id', availableTrialLessons.results))
    : false;


  const openTutorChat = useCallback(async () => {
    try {
      const chat = await startDialog({ userId: tutorId, userType: 'tutor' }).unwrap();
      dispatch(openChat(chat.id));
      navigate(appRoutes.chats());
    } catch (e) {
      console.log(JSON.stringify(e.data ?? e, 0, 2));
    }
  }, [dispatch, navigate, startDialog, tutorId]);

  if (tutorError) return <pre>ERROR {JSON.stringify(tutorError, 0, 2)}</pre>
  if (isTutorFetching || isTutorLoading || isAvailableTrialLessonsLoading || isAvailableTrialLessonsFetching) return (
    <Container>
      <div className={styles.tutor}>
        <ContentLoader
          speed={2}
          width="100%"
          height={700}
          backgroundColor={'#c8c8c8'}
          foregroundColor={'#eceaea'}
        >
          <rect x="0" y="0" rx="12" ry="12" width="64%" height="100%" />
          <rect x="66%" y="0" rx="12" ry="12" width="33%" height="300" />

        </ContentLoader>
      </div>
    </Container>
  );

  return (
    <Container>
      <div className={styles.tutor}>
        <section className={styles.tutorInfo}>
          <div className={styles.tutorContent}>
            <TutorDetailCard
              price={tutor.price}
              photo={tutor.photoUrl}
              video={tutor.introductionVideoUrl}
              fullName={tutor.fullName?.[i18n.language]}
              age={tutor.age}
              subject={getHeadIfExists(tutor.subjects)?.shortName[i18n.language]}
              yearsOfWorkExperience={tutor.yearsOfWorkExperience}
              completedLessonCount={tutor.completedLessonCount}
              languageSkills={tutor.languageSkills}
            />
            <TutorDetailGoals goals={tutor.learningGoals} />
            <TutorAbout text={tutor.introductionText?.[i18n.language]} />
            <div className={styles.tutorVideoInCard}>
              <ReactPlayer
                controls
                url={tutor.introductionVideoUrl}
                width="100%"
                height="300px"
              />
            </div>
            <TutorBioTabs
              educations={tutor.educations}
              certificates={tutor.certificates}
              workExperiences={tutor.workExperiences}
            />
            <Divider mt={50} mb={30} />

            <TutorSchedule
              tutorId={tutorId}
              tutorSubjects={tutor.subjects}
              disabled={canBookTrialLesson}
              onTryClick={() => {
                toast.info(t('Для начала Вам нужно забронировать пробный урок по данному предмету'), { toastId: 'not-allow-book' })
              }}
            />

            <div className={styles.tutorActionsSmall}>
              {
                !canBookTrialLesson && (
                  <BuyPackageModal tutorPrice={tutor.price} tutorId={tutorId}/>
                )
              }
              <Button
                size="small"
                fullWidth
                color="light"
                icon={<FiSend />}
                iconPosition="start"
                onClick={openTutorChat}
                className="Button_WriteToTheTeacher"
                loading={isStartDialogLoading}
              >
                {t('Написать учителю')}
              </Button>
              <TakeTrialLesson
                tutorId={tutorId}
                subject={getHeadIfExists(tutor.subjects)}
              />
            </div>

            <TutorReviewsList rating={tutor.rating} reviewCount={tutor.reviewCount} />
          </div>
        </section>
        <StickyBox
          className={styles.tutorActions}
          offsetTop={20}
        >
          <TutorActions
            onWriteToTutor={openTutorChat}
            rating={tutor.rating}
            reviewCount={tutor.reviewCount}
            price={tutor.price}
            video={tutor.introductionVideoUrl}
            canBookTrialLesson={canBookTrialLesson}
            tutorId={tutorId}
            onWriteToTutorLoading={isStartDialogLoading}
          />
          <TakeTrialLesson
            tutorId={tutorId}
            subject={getHeadIfExists(tutor.subjects)}
          />
        </StickyBox>
      </div>
      {/*<pre>{JSON.stringify(tutor, 0, 2)}</pre>*/}
    </Container>
  );
};

export default TutorDetails;
