import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import useQueryString from '../hooks/useQueryString';
import localforage from 'localforage';
import appRoutes from '../app/appRoutes';
import { Navigate } from 'react-router-dom';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import LoaderFullContent from '../components/LoaderFullContent';

const routesMap = {

};

const getNextRoute = (next) => {

  if (!next) return appRoutes.tutors();

  const nextParsed = JSON.parse(next);

  switch(nextParsed.type) {
    case 'tutorChat': return appRoutes.tutors(nextParsed.tutorId);
    case 'tutorSchedule': return `${appRoutes.tutors(nextParsed.tutorId)}?section=schedule`;
    case 'register': return appRoutes.register();
    case 'entry': return appRoutes.entry();
    default: return appRoutes.tutors();
  }
};

const ProxyRoute = ({ children }) => {
  const [, i18n] = useTranslation();
  const navigate = useNavigate();
  const query = useQueryString();
  const [hasCredentials, setHasCredentials] = useState(null);
  const [checkingCredentials, setCheckingCredentials] = useState(true);

  useEffect(() => {
    const lang = query?.lang;
    const currentUserLang = localStorage.getItem('userLanguage');

    if (lang) {
      i18n.changeLanguage(lang);
      localStorage.setItem('userLanguage', lang);
      return;
    }

    if (currentUserLang) {
      i18n.changeLanguage(currentUserLang);
      return;
    }
  }, []);

  useEffect(() => {
    setCheckingCredentials(true);
    localforage.getItem('credentials')
      .then((credentials) => {
        if (!credentials || !credentials.accessToken) {
          setCheckingCredentials(false);
          setHasCredentials(false);
          return;
        }
        setCheckingCredentials(false);
        setHasCredentials(true);
      })
      .catch((err) => {

        setHasCredentials(false);
        setCheckingCredentials(false);
      })
  }, [navigate]);

  const loading = (hasCredentials === null) || checkingCredentials;

  if (loading) return (
    <div>
      <LoaderFullContent />
    </div>
  );

  const nextParsed = query?.next ? JSON.parse(query.next) : {};

  if (!hasCredentials) {
    if (!query?.next) {
      return <Navigate to={{ pathname: appRoutes.login() }} replace />;
    }
    if (nextParsed.type === 'entry' || nextParsed.type === 'register') {
      const nextRoute = getNextRoute(query.next);
      return <Navigate to={nextRoute} replace />;
    }

    return <Navigate
      to={{
        pathname: appRoutes.login(),
        search: queryString.stringify(query),
      }}
      replace
    />;
  }

  if (nextParsed.type === 'entry' || nextParsed.type === 'register') {
    return <Navigate to={appRoutes.profile()} replace />;
  }

  return <Navigate to={getNextRoute(query?.next)} replace />;
};

export default ProxyRoute;
