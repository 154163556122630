import styles from './RoomWarning.module.scss';
import { FiAlertTriangle } from 'react-icons/fi';
import Button from '../../../components/Button';
import { useDispatch, useSelector } from 'react-redux';
import { setUnexpectedBehaviour, useUnexpectedBehaviourState } from '../../../store/slicers/room';
import { useTranslation } from 'react-i18next';

const UnexpectedBehaviourModal = () => {
  const [t] = useTranslation();
  const unexpectedBehaviour = useUnexpectedBehaviourState();
  const dispatch = useDispatch();

  const causes = {
    NO_MEDIA_DEVICE_STREAM: t('Мы не смогли получить видео или аудио с Вашего устройства'),
    SHARE_SCREEN_NOT_SUPPORTED: t('Демонстрация экрана не поддерживается Вашим браузером'),
  };

  if (!unexpectedBehaviour.show) return null;

  return (
    <div className={styles.warning}>
      <div className={styles.warningWrapper}>
        <div className={styles.warningIcon}>
          <FiAlertTriangle/>
        </div>
        <h4 className={styles.warningTitle}>
          {causes[unexpectedBehaviour.cause]}
        </h4>
        <p
          className={styles.warningText}
        >
          {t('Попробуйте обновить страницу')}
        </p>
        <div className={styles.warningActions}>
          <Button
            size="small"
            color="secondary"
            onClick={() => {
              if (unexpectedBehaviour.canReload) {
                window.location.reload();
                return;
              }
              if (unexpectedBehaviour.canClose) {
                dispatch(setUnexpectedBehaviour({
                  show: false,
                  cause: null,
                  canReload: true,
                  canClose: true
                }));
              }
            }}
          >
            {
              unexpectedBehaviour.canReload
                ? t('Обновить страницу')
                : t('Закрыть')
            }
          </Button>
        </div>
      </div>
    </div>
  );
};

export default UnexpectedBehaviourModal;
