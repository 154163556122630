import { createApi } from '@reduxjs/toolkit/query/react';
import apiRoutes  from '../../apiRoutes';
import { baseQuery } from '../utils';


export const authApi = createApi({
  reducerPath: 'auth',
  baseQuery,
  tagTypes: [],
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (body) => ({
        url: apiRoutes.login(),
        method: 'POST',
        body,
      }),
    }),

    signupPhoneConfirmation: builder.mutation({
      query: (body) => ({
        url: apiRoutes.signupPhoneConfirmation(),
        method: 'POST',
        body,
      })
    }),
    verifyPhone: builder.mutation({
      query: (body) => ({
        url: apiRoutes.verifyPhone(),
        method: 'POST',
        body,
      })
    }),
    setPassword: builder.mutation({
      query: (body) => ({
        url: apiRoutes.setPassword(),
        method: 'POST',
        body,
      })
    }),

    requestRecoveryCode: builder.mutation({
      query: (body) => ({
        url: apiRoutes.requestRecoveryCode(),
        method: 'POST',
        body,
      })
    }),
    recoverPassword: builder.mutation({
      query: (body) => ({
        url: apiRoutes.recoveryPassword(),
        method: 'POST',
        body,
      })
    }),
  })
});

export const {
  useLoginMutation,
  useSignupPhoneConfirmationMutation,
  useVerifyPhoneMutation,
  useSetPasswordMutation,
  useRequestRecoveryCodeMutation,
  useRecoverPasswordMutation,
} = authApi;
