import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import ru from 'date-fns/locale/ru';
import uz from 'date-fns/locale/uz';
import en from 'date-fns/locale/en-US';

import EN from './en/translation.json';
import UZ from './uz/translation.json';
import RU from './ru/translation.json';

export const dateLocales = {
  ru: ru,
  uz: uz,
  en: en,
};

i18n
  .use(initReactI18next)
  .use({
    type: 'logger',
    log: (args) => {
      const [first, , , name, value] = args;
      if (first.split(' ')[1] === 'missingKey') {
        console.log(`"${name}": "${value}",`);
      }
    },
  })
  .init({
    // lng: 'kir',
    fallbackLng: 'ru',
    // debug: !(process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'test'),
    debug: false,
    resources: {
      ru: {
        translations: RU,
      },
      uz: {
        translations: UZ,
      },
      en: {
        translations: EN,
      },
    },

    ns: ['translations'],
    defaultNS: 'translations',

    interpolation: {
      escapeValue: false,
    },
    react: {
      wait: true,
    },
  });

export default i18n;
