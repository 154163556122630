import { mainApi } from './index';
import apiRoutes from '../../apiRoutes';

mainApi.injectEndpoints({
  endpoints: (build) => ({
    getRoomInfo: build.mutation({
      query: ({ tutorId, roomId }) => ({
        url: apiRoutes.roomInfo(roomId),
        method: 'PUT',
        body: { tutorId },
      }),
    }),

    addIceCandidate: build.mutation({
      query: ({ roomId, body }) => ({
        url: apiRoutes.addIceCandidate(roomId),
        body,
        method: 'POST',
      }),
    }),

    setAnswer: build.mutation({
      query: ({ roomId, body }) => ({
        url: apiRoutes.setAnswer(roomId),
        body,
        method: 'PUT',
      }),
    }),

    sendWainSignal: build.mutation({
      query: (roomId) => ({
        url: apiRoutes.sendWaitSignal(roomId),
        method: 'PUT',
      }),
    }),
  }),
});

export const {
  useGetRoomInfoMutation,
  useAddIceCandidateMutation,
  useSetAnswerMutation,
  useSendWainSignalMutation,
} = mainApi;
