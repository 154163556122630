import React, { useCallback } from 'react';

import styles from './SlotSelect.module.scss';
import Text from '../Text';
import LoaderFullContent from '../LoaderFullContent';
import Slot from '../Slot';
import { includes, without } from 'ramda';
import EmptySimple from '../EmptySimple';
import { useTranslation } from 'react-i18next';
import { useUserTimezone } from '../../store/slicers/user';

const SlotSelect = ({
  isLoading,
  slots = [],
  onChange,
  value,
  multiple,
  checkedSlotDisabled,
  uncheckedSlotDisabled,
  disabled,
  onTryClick,
}) => {
  const [t] = useTranslation();
  const { hourShift, timezone } = useUserTimezone();

  const handleClickTimeSlot = useCallback((slot) => () => {
    if (multiple) {
      const newSelectedTimeSlots = includes(slot, value)
        ? without([slot], value)
        : [...value, slot];

      onChange(newSelectedTimeSlots);
      return;
    }

    onChange([slot]);
  }, [multiple, onChange, value]);

  return (
    <div className={styles.times}>
      <Text level={3} color="secondary">
        {t('Часовой пояс')} - {timezone} {hourShift}
      </Text>
      <div className={styles.timesList}>
        {isLoading && <LoaderFullContent />}
        {
          (slots.length > 0)
            ? slots.map((slot) => (
              <Slot
                key={slot.value}
                label={slot.label}
                onClick={disabled ? onTryClick : handleClickTimeSlot(slot)}
                isActive={includes(slot, value)}
                disabled={
                  includes(slot, value)
                    ? checkedSlotDisabled
                    : uncheckedSlotDisabled
                }
              />
            ))
            : (
              <EmptySimple
                title={t('В этот день у учителя нет свободного времени')}
                text={t('Попробуйте выбрать другой день')}
                align="center"
              />
            )
        }
      </div>
    </div>
  );
};

export default SlotSelect;
