// import cn from 'classnames';

import styles from './HelpCardItem.module.scss';
import Text from '../Text';
import Title from '../Title';

const HelpCardItem = ({
  icon,
  link,
  label,
  value
}) => {

  return (
    <a className={styles.card} href={link}>
      <div className={styles.cardContainer}>
        <div className={styles.cardIcon}>
          {icon}
        </div>
        <div className={styles.cardContent}>
          <Text level={2} color="secondary">{label}</Text>
          <Title level={4} noMargin>{value}</Title>
        </div>
      </div>
    </a>
  );
};

export default HelpCardItem;
