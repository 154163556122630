import React, { useCallback } from 'react';

import styles from './PackageCancellationRequestModal.module.scss';
import { useTranslation } from 'react-i18next';
import Modal from '../../../components/Modal';
import { useForm } from 'react-hook-form';
import SelectInputController from '../../../components/ComplexSelectInput/SelectInputController';
import { dictionaryToOptions } from '../../../utils';
import { useGetPackageCancellationReasonsQuery } from '../../../store/apis/dictionaries/packageCancellationReasons';
import TextareaInputController from '../../../components/TextareaInput/TextareaInputController';
import Button from '../../../components/Button';
import { useRequestCancellationPackageMutation } from '../../../store/apis/tariffs';
import { toast } from 'react-toastify';
import { createDataSchema } from '../../../yup';
import { number } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const schema = createDataSchema({
  reason: number().required(),
});

const PackageCancellationRequestModal = ({
  packId,
  open,
  onClose,
}) => {
  const [t] = useTranslation();

  const {
    data: reasons,
    isLoading: isReasonsLoading,
    isFetching: isReasonsFetching,
  } = useGetPackageCancellationReasonsQuery({ page: -1 });

  const [
    requestPackageCancellation,
    {
      isLoading: isRequestCancellationPackageLoading,
      isFetching: isRequestCancellationPackageFetching,
    }
  ] = useRequestCancellationPackageMutation();

  const {
    control,
    handleSubmit: submit,

  } = useForm({
    defaultValues: {
      packageId: packId,
    },
    resolver: yupResolver(schema),
  });

  const handleSubmit = useCallback((close) => async (values) => {
    if (!values.packageId) {
      toast.warn(t('Запрос не может быть отправлен, попробуйте перезагрузить страницу'));
      return;
    }
    try {
      await requestPackageCancellation(values).unwrap();
      toast.success(t('Запрос отправлен'));
      close();
    } catch (e) {
      console.log(JSON.stringify(e.data ?? e, 0, 2));
    }
  }, [requestPackageCancellation, t]);

  return (
    <>
      <Modal
        title={t('Запросить возврат средств')}
        subtitle={t('Внимание! После подтверждения возврата средств Ваше расписание будет отменено, а пакет заморожен')}
        variant="secondary"
        closeOnDocumentClick={false}
        open={open}
        onClose={onClose}
      >
        {(close) => (
          <form onSubmit={submit(handleSubmit(close))} className={styles.form}>
            <div className={styles.formItem}>
              <SelectInputController
                name="reason"
                control={control}
                placeholder={t('Укажите причину')}
                label={t('Причина запроса')}
                options={dictionaryToOptions(reasons)}
                disabled={isReasonsLoading || isReasonsFetching}
              />
            </div>
            <div className={styles.formItem}>
              <TextareaInputController
                name="studentComment"
                control={control}
                placeholder={t('Напишите комментарий')}
                variant="secondary"
              />
            </div>
            <div className={styles.formItem}>
              <div className={styles.formFooter}>
                <Button type="button" size="medium" onClick={close} color="secondary">
                  {t('Отменить')}
                </Button>
                <Button
                  type="submit"
                  size="medium"
                  loading={isRequestCancellationPackageLoading || isRequestCancellationPackageFetching}
                >
                  {t('Запросить возврат')}
                </Button>
              </div>
            </div>
          </form>
        )}
      </Modal>
    </>
  );
};

export default PackageCancellationRequestModal;
