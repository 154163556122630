import React, { useCallback, useState } from 'react';

import styles from './TutorScheduleWeeklyLesson.module.scss';
import { useGetTutorsWeeklySchedulesQuery } from '../../../store/apis/tutors/tutorListApi';
import ScheduleWeeklyStartDateInput from '../../../components/ScheduleWeeklyStartDateInput';
import ScheduleWeeklyTimeSlots from '../../../components/ScheduleWeeklyTimeSlots';

const TutorScheduleWeeklyLesson = ({
  tutorId,
  onSelectedTimeSlotChange,
  selectedTimeSlots,
  onChangeStartLessonDate,
  startLessonValue,
  disabled,
  onTryClick,
}) => {

  const {
    data: scheduleList,
    isLoading: isTutorWeeklyScheduleLoading,
    isFetching: isTutorWeeklyScheduleFetching,
  } = useGetTutorsWeeklySchedulesQuery({ tutorId });


  const changeStartLessonDate = useCallback((date) => {
    onChangeStartLessonDate(date);
  }, [onChangeStartLessonDate]);

  return (
    <div className={styles.schedule}>
      {
        disabled
          ? null
          : (
            <ScheduleWeeklyStartDateInput
              isLoading={isTutorWeeklyScheduleLoading || isTutorWeeklyScheduleFetching}
              schedules={scheduleList}
              onChange={changeStartLessonDate}
              value={startLessonValue}
            />
          )
      }
      <ScheduleWeeklyTimeSlots
        schedules={scheduleList}
        selectedTimeSlots={selectedTimeSlots}
        onSelectedTimeSlotChange={onSelectedTimeSlotChange}
        disabled={disabled}
        onTryClick={onTryClick}
      />
    </div>
  );
};

export default TutorScheduleWeeklyLesson;
