import React from 'react';
import cn from 'classnames';

import styles from './Pagination.module.scss';
import ReactPaginate from 'react-paginate';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';

const Pagination = ({
  count,
  currentPage,
  onPageChange
}) => {

  return (
    <ReactPaginate
      pageCount={count}
      pageRangeDisplayed={3}
      forcePage={currentPage - 1}
      onPageChange={({ selected }) => onPageChange(selected + 1)}
      nextLabel={<FiChevronRight />}
      previousLabel={<FiChevronLeft />}
      breakClassName={styles.break}
      containerClassName={styles.container}
      pageClassName={styles.page}
      activeClassName={styles.active}
      previousClassName={styles.prev}
      nextClassName={styles.next}
      disabledClassName={styles.disabled}
    />
  );
};

export default Pagination;
