import React, { useCallback, useMemo } from 'react';
import cn from 'classnames';

import styles from './TeacherCard.module.scss';
import { FiCheckCircle, FiHeart, FiSend } from 'react-icons/fi';
import { MdVerified } from 'react-icons/md';
import Title from '../Title';
import Rating from '../Rating';
import Text from '../Text';
import { ReactComponent as ChatUser } from '../../icons/ChatUser.svg';
import { useTranslation } from 'react-i18next';
import Graduation from '../Graduation';
import Button from '../Button';
import { Link } from 'react-router-dom';
import appRoutes from '../../app/appRoutes';
import { head, map, split } from 'ramda';
import { formatNumber } from '../../utils';

const TeacherCard = ({
  id,
  photo,
  name,
  firstName,
  lastName,
  secondName,
  rating,
  price,
  tags,
  education,
  subject,
  description,
  isLiked,
  onLike,
  onDislike,
  onWrite,
  onWriteLoading,
  onSchedule,
}) => {
  const [t] = useTranslation();
  const likeClasses = useMemo(() => cn({
    [styles.cardLike]: true,
    [styles.cardLikeActive]: isLiked,
  }), [isLiked])

  const bodyClasses = useCallback((isInner) => cn({
    [styles.cardBody]: true,
    [styles.cardBodyInner]: isInner,
    [styles.cardBodyOuter]: !isInner,
  }), []);

  const userInitials = useMemo(() =>
      name ? map((nameItem) => head(nameItem), split(' ', name)) : ['🫠', null],
    [name]
  );

  const renderBody = (inner) => (
    <div className={bodyClasses(inner)}>
      <span className={styles.cardContentTitleTagsTitle}>{t('О преподавателе')}</span>
      <Text level={3}>{description}</Text>
      <div className={styles.cardBodyActions}>
        <Button
          type="button"
          color="secondary"
          size="small"
          icon={<FiSend />}
          iconPosition="start"
          onClick={onWrite}
          loading={onWriteLoading}
          className="Button_WriteToTheTeacher"
        >
          {t('Написать')}
        </Button>
        <Button
          type="button"
          color="primary"
          size="small"
          icon={<FiCheckCircle />}
          iconPosition="start"
          onClick={onSchedule}
          className="Button_TeachersSchedule"
        >
          {t('Расписание')}
        </Button>
      </div>
    </div>
  );

  return (
    <div className={styles.card}>
      <div className={styles.cardPhoto}>
        <button
          type="button"
          className={likeClasses}
          onClick={isLiked ? onDislike : onLike}
        >
          <FiHeart />
        </button>
        {
          photo
            ? <img src={photo} alt="Фотография учителя" />
            : <div className={styles.cardPhotoPlaceholder}>{userInitials[0]}{userInitials[1]}</div>
        }
      </div>
      <div className={styles.cardContent}>
        <div className={styles.cardContentHeader}>

          <div className={styles.cardContentTitle}>
            <div className={styles.cardContentTitleName}>
              <Link to={appRoutes.tutors(id)} className={styles.cardContentTitleLink}>
                <Title
                  level={4}
                  noMargin
                  className={styles.cardContentTitleText}
                >
                  {lastName}
                  {' '}
                  {firstName}
                  {' '}
                  <span style={{ whiteSpace: 'nowrap' }}>
                    {secondName}
                    {' '}
                    <span className={styles.cardContentTitleVerified}>
                      <MdVerified />
                    </span>
                    <span className={styles.cardContentTitleRating}>
                      <Rating rating={rating} />
                    </span>
                  </span>
                </Title>
              </Link>

            </div>
            <div className={styles.cardContentSubject}>
              <div className={styles.cardContentSubjectIcon}>
                <ChatUser />
              </div>
              <div className={styles.cardContentSubjectText}>
                <Title level={6} bold noMargin noPadding>{subject}</Title>
              </div>
            </div>
            {
              (tags.length > 0) && (
                <div className={styles.cardContentTitleTags}>
                  <span className={styles.cardContentTitleTagsTitle}>{t('Направления обучения')}:</span>
                  {tags.map((tag) => (
                    <span
                      key={tag.id}
                      className={styles.cardContentTitleTagsItem}
                    >
                      {tag.name}
                    </span>
                  ))}
                </div>
              )
            }
            <div className={styles.cardContentTitleGraduation}>
              {education ? <Graduation text={education} color="primary" size="small" noMargin/> : null}
            </div>
          </div>

          <div className={styles.cardContentPrice}>
            <Title level={4} noMargin>{formatNumber(price)} {t('сум')}</Title>
            <Text level={3} color="secondary">{t('за 50 минут')}</Text>
          </div>
        </div>
        {renderBody(true)}
      </div>
      {renderBody(false)}
    </div>
  );
};

export default TeacherCard;
