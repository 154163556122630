import React, { useEffect } from 'react';

import styles from './UnpaidInvoiceAlert.module.scss';
import Container from '../../../components/Container';
import { Link, useLocation } from 'react-router-dom';
import appRoutes from '../../../app/appRoutes';
import { useGetUnpaidInvoicesQuery } from '../../../store/apis/tariffs';
import { useGetServiceConfigsQuery } from '../../../store/apis/serviceConfigs';
import { useTranslation } from 'react-i18next';

const UnpaidInvoiceAlert = () => {
  const [t] = useTranslation();
  const location = useLocation();

  const {
    data: invoiceList,
    isLoading: isInvoiceListLoading,
    isFetching: isInvoiceListFetching,
    refetch,
  } = useGetUnpaidInvoicesQuery({ page: -1 });

  const {
    data: serviceConfig,
    isFetching: isServiceConfigFetching,
  } = useGetServiceConfigsQuery();

  useEffect(() => {
    refetch();
  }, [location.pathname, location.search, refetch]);

  if (!invoiceList || invoiceList?.results.length === 0) return null;
  // if (isInvoiceListLoading || isInvoiceListFetching) return null;
  // if (isServiceConfigFetching) return null;

  return (
    <Container>
      <div className={styles.alert}>
        <h3 className={styles.alertTitle}>
          {t('У Вас есть неоплаченных счета', { count: invoiceList.count })}
        </h3>
        <p className={styles.alertText}>
          {t('Если Вы не оплатите счет в течение {{count}} минут, то Ваша бронь будет аннулирована', { count: serviceConfig?.autoDeleteNotPaidScheduleAfterMinutes })}
        </p>
        <div className={styles.alertActions}>
          <Link
            to={{
              pathname: appRoutes.balance(),
              search: `?tab=unpaidInvoices`
            }}
            className={styles.alertLink }
          >
            {t('Посмотреть неоплаченные счета')}
          </Link>
        </div>
      </div>
    </Container>
  );
};

export default UnpaidInvoiceAlert;
