import React, { useCallback, useMemo, useState } from 'react';
import cn from 'classnames';

import styles from './SelectInput.module.scss';
import Select, { components } from 'react-select';
import { FaCaretDown } from 'react-icons/fa';
import { find, propEq } from 'ramda';

const SelectInput = ({
  name,
  options,
  label,
  onChange,
  selected,
  disabled,
  placeholder,
  error,
}, ref) => {
  const selectClasses = useMemo(() => cn({
    [styles.select]: true,
    [styles.selectDisabled]: disabled,
    [styles.selectError]: error,
  }), [error, disabled]);

  const currentValue = useMemo(() => {
    if (!selected) return null;
    return find(propEq(selected, 'value'))(options);
  }, [options, selected]);

  const handleChange = useCallback((option) => {
    if (!option) return onChange(undefined);
    if (onChange) onChange(option.value);
  }, [onChange]);

  return (
    <div className={selectClasses}>
      <label htmlFor={name}>{error || label}</label>
      <Select
        instanceId={name}
        inputId={name}
        ref={ref}
        placeholder={placeholder}
        isDisabled={disabled}
        onChange={handleChange}
        isSearchable={false}
        value={currentValue}
        options={options}
        openMenuOnFocus
        styles={{
          control: (styles) => ({
            ...styles,
            border: 'none',
            boxShadow: 'none',
            width: '100%',
            backgroundColor: 'transparent',
            minHeight: 24,
            paddingBottom: '.6em',
            position: 'relative',
            top: label ? 0 : '-3px',
          }),
          indicatorSeparator: () => ({ display: 'none' }),
          menu: (styles) => ({
            ...styles,
            boxShadow: '2px 0px 6px 5px rgba(235,228,228,0.75)',
            borderRadius: 12,
            overflow: 'hidden',
            zIndex: 3,
          }),
          option: (styles, state) => ({
            ...styles,
            backgroundColor: state.isSelected ? '#536DFE' : 'none',
          }),
          valueContainer: (styles) => ({
            ...styles,
            paddingLeft: '.6em',
            zIndex: 2,
          }),
          dropdownIndicator: () => ({ display: 'none' }),
          placeholder: (styles) => ({ ...styles, color: '#737C92' })
        }}
      />
      <span className={styles.selectIndicator}>
        <FaCaretDown />
      </span>
    </div>
  );
};

export default React.forwardRef(SelectInput);
