import React, { useCallback } from 'react';
import { setActiveChatTab, useChat, useChatUnreadFilesAndMessages } from '../../../store/slicers/chat';
import { FiFile, FiMessageCircle } from 'react-icons/fi';
import Tabs from '../../../components/Tabs';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

const ChatTabsContainer = () => {
  const [t] = useTranslation();
  const chat = useChat();
  const dispatch = useDispatch();


  const [messagesUnreadCount, filesUnreadCount] = useChatUnreadFilesAndMessages(chat.activeChatId);

  const toggleChatTab = useCallback(async (tabId) => {
    dispatch(setActiveChatTab(tabId));
  }, [dispatch]);

  return (
    <Tabs
      items={[
        {
          id: 1,
          name: t('Чат'),
          icon: <FiMessageCircle />,
          badge: messagesUnreadCount,
        },
        {
          id: 2,
          name: t('Файлы'),
          icon: <FiFile />,
          badge: filesUnreadCount,
        },
      ]}
      activeItem={chat.activeTabId}
      onChange={toggleChatTab}
    />
  );
};

export default ChatTabsContainer;
