import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import styles from './TutorSchedule.module.scss';
import Tabs from '../../../components/Tabs';
import Text from '../../../components/Text';
import Condition from '../../../components/Condition';
import TutorScheduleSingleLesson from '../TutorScheduleSingleLesson';
import TutorScheduleWeeklyLesson from '../TutorScheduleWeeklyLesson';
import { useTranslation } from 'react-i18next';
import { clearBooking, setSchedule, useBookingData } from '../../../store/slicers/booking';
import { useDispatch } from 'react-redux';
import useQueryString from '../../../hooks/useQueryString';
import { getHeadIfExists } from '../../../utils';
import { addHours, addMinutes } from 'date-fns';
import { useGetServiceConfigsQuery, useGetUserScheduleLimitQuery } from '../../../store/apis/serviceConfigs';
import ContentLoader from 'react-content-loader';

const TutorSchedule = ({
  tutorId,
  tutorSubjects,
  disabled = false,
  onTryClick,
}) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const { section } = useQueryString();

  const {
    data: serviceConfig,
    isFetching: isServiceConfigFetching,
  } = useGetServiceConfigsQuery();

  const {
    data: userLimit,
    isFetching: isUserLimitFetching,
  } = useGetUserScheduleLimitQuery();

  const MINUTES_ADD_TO_SCHEDULE = serviceConfig ? serviceConfig.newScheduleMinutesBeforeStart : 24 * 60;
  const SCHEDULE_DAY_LIMIT = userLimit ? userLimit.value : 0;
  // const SCHEDULE_DAY_LIMIT = 100;

  const bookingData = useBookingData();

  const today = useMemo(() =>
    addMinutes(
      new Date(),
      MINUTES_ADD_TO_SCHEDULE,
    ),
    [MINUTES_ADD_TO_SCHEDULE]);

  const [activeLessonTypeTab, setActiveLessonTypeTab] = useState(2);

  const changeLessonTypeTab = useCallback((tab) => {
    setActiveLessonTypeTab(tab);
    dispatch(clearBooking());
  }, [dispatch]);

  const lessonTypeTabs = useMemo(() => [
    {
      name: t('Разовые занятия'),
      id: 2,
    },
    {
      name: t('Еженедельные занятия'),
      id: 1,
    },
  ], [t]);

  const scheduleBlockRef = useRef(null);

  const handleChangeSelectedTimeSlot = useCallback((timeSlots) => {
    const scheduleType = activeLessonTypeTab;

    dispatch(setSchedule({
      scheduleType,
      tutorId,
      subject: getHeadIfExists(tutorSubjects).id,
      lessonsTime: timeSlots,
      lessonsSpan: timeSlots,
    }));
  }, [activeLessonTypeTab, dispatch, tutorSubjects, tutorId]);

  const changeStartLessonDate = useCallback((date) => {
    dispatch(setSchedule({ startLesson: date }));
  }, [dispatch]);

  useEffect(() => {
    if (!tutorId) return;

    if (scheduleBlockRef && scheduleBlockRef.current && section) {
      scheduleBlockRef.current.scrollIntoView();
    }
  }, [tutorId, section]);

  const scroll = useCallback((node) => {
    if (node !== null && section) {
      window.scrollTo({
        top: node.getBoundingClientRect().top,
        behavior: "smooth"
      })
    }
  }, [section]);

  if (isServiceConfigFetching || isUserLimitFetching) {
    return (
      <ContentLoader
        speed={2}
        width="100%"
        height={300}
        backgroundColor={'#c8c8c8'}
        foregroundColor={'#eceaea'}
      >
        <rect x="0" y="0" rx="12" ry="12" width="100%" height="40" />
        <rect x="0" y="60" rx="12" ry="12" width="100%" height="240" />
      </ContentLoader>
    );
  }

  return (
    <>
      <div className={styles.tabs} ref={scroll}>
        <Tabs
          items={lessonTypeTabs}
          activeItem={activeLessonTypeTab}
          onChange={changeLessonTypeTab}
        />
      </div>
      <div ref={scheduleBlockRef}>
        <Condition when={activeLessonTypeTab} is={2}>
          <TutorScheduleSingleLesson
            tutorId={tutorId}
            onSelectedTimeSlotsChange={handleChangeSelectedTimeSlot}
            selectedTimeSlots={bookingData.lessonsTime}
            defaultSelectedDate={today}
            max={SCHEDULE_DAY_LIMIT}
            disabled={disabled}
            onTryClick={onTryClick}
          />
          <div className={styles.tip}>
            <Text level={3} color="secondary">
              {
                SCHEDULE_DAY_LIMIT > 0
                  ? t('Сегодня вы можете забронировать еще {{count}}', { count: SCHEDULE_DAY_LIMIT })
                  : t('Вы больше не можете забронировать слот у преподавателя, попробуйте завтра')
              }
            </Text>
          </div>
        </Condition>
        <Condition when={activeLessonTypeTab} is={1}>
          <TutorScheduleWeeklyLesson
            tutorId={tutorId}
            onSelectedTimeSlotChange={handleChangeSelectedTimeSlot}
            selectedTimeSlots={bookingData.lessonsSpan}
            onChangeStartLessonDate={changeStartLessonDate}
            startLessonValue={bookingData.startLesson}
            disabled={disabled}
            onTryClick={onTryClick}
          />
        </Condition>
      </div>
    </>
  );
};

export default TutorSchedule;
