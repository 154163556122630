import React, { useCallback, useState } from 'react';
import cn from 'classnames';

import styles from './ScheduleWeeklyTimeSlots.module.scss';
import { includes, keys, pluck, uniq, without } from 'ramda';
import { FiCheckSquare, FiChevronDown, FiChevronRight, FiSquare } from 'react-icons/fi';
import { getHourLabelById } from '../../utils';
import ScheduleTimeSlots from '../SlotSelect';
import useTranslatableConstants from '../../hooks/useTranslatableConstants';

const ScheduleWeeklyTimeSlots = ({
  schedules = [],
  selectedTimeSlots = [],
  onSelectedTimeSlotChange,
  disabled,
  onTryClick,
}) => {
  const [visibleTimes, setVisibleTimes] = useState([]);
  const { weekDayNames } = useTranslatableConstants();

  const toggleVisibleItems = useCallback((weekDay) => () => {
    const newVisibleTimes = includes(weekDay, visibleTimes)
      ? without([weekDay], visibleTimes)
      : [...visibleTimes, weekDay]

    setVisibleTimes(newVisibleTimes);
  }, [visibleTimes]);

  const bodyClasses = useCallback((weekDay) => cn({
    [styles.scheduleItemBody]: true,
    [styles.scheduleItemBodyVisible]: includes(weekDay, visibleTimes),
  }), [visibleTimes]);

  const checkButtonClasses = useCallback((isActive) => cn({
    [styles.scheduleItemHeaderCheck]: true,
    [styles.scheduleItemHeaderCheckActive]: isActive,
  }), []);

  const timeSlotsByWeekDay = schedules
    ? schedules.reduce((acc, slotData) => {
      const currentWeekDay = slotData.weekDayId;
      const preparedSlot = {
        ...slotData,
        label: getHourLabelById(slotData.hourId), value: slotData.id,
      };

      const newWeekDaySlots = acc[currentWeekDay]
        ? [ ...acc[currentWeekDay], preparedSlot]
        : [preparedSlot];

      return { ...acc, [currentWeekDay]: uniq(newWeekDaySlots) };
    }, {})
    : {};

  const checkSelectedWeek = useCallback((weekDay) => {
    return includes(Number(weekDay), pluck('weekDayId', selectedTimeSlots));
  }, [selectedTimeSlots]);

  return keys(timeSlotsByWeekDay).map((weekDay) => (
    <div className={styles.scheduleItem} key={weekDay}>
      <div className={styles.scheduleItemHeader}>
        <div className={checkButtonClasses(checkSelectedWeek(weekDay))}>
          {checkSelectedWeek(weekDay) ? <FiCheckSquare /> : <FiSquare />}
        </div>
        <button
          type="button"
          className={styles.scheduleItemHeaderButton}
          onClick={toggleVisibleItems(weekDay)}
        >
          <div className={styles.scheduleItemHeaderTitle}>
            {weekDayNames[weekDay]}
          </div>
          <div className={styles.scheduleItemHeaderChevron}>
            {
              includes(weekDay, visibleTimes)
                ? <FiChevronDown />
                : <FiChevronRight />
            }
          </div>
        </button>
      </div>

      <div className={bodyClasses(weekDay)}>
        <ScheduleTimeSlots
          isLoading={false}
          slots={timeSlotsByWeekDay[weekDay]}
          onChange={onSelectedTimeSlotChange}
          value={selectedTimeSlots}
          multiple={true}
          disabled={disabled}
          onTryClick={onTryClick}
        />
      </div>
    </div>
  ));
};

export default ScheduleWeeklyTimeSlots;
