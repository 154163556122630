import React, { useMemo } from 'react';
import cn from 'classnames';

import styles from './ChatMessage.module.scss';
import { head, map, split } from 'ramda';
import { intlFormatDate } from '../../utils';

const ChatMessage = ({
  type,
  message,
  date,
  avatarUrl,
  name,
}) => {
  const userInitials = useMemo(
    () => name ? map((nameItem) => head(nameItem), split(' ', name)) : ['🫠', null],
    [name],
  );

  const parsedMessage = typeof message === 'string'
    ? split('\n', message)
      .map((text, i) => (
        (i === 0)
          ? text
          : <React.Fragment key={i}><br />{text}</React.Fragment>
      ))
    : message;

  return type === 'self'
    ? (
      <div className={styles.chatMessageSelf}>
        <div className={styles.chatMessageText}>
          <span>
            {parsedMessage}
          </span>
          <span className={styles.chatMessageTextTime}>{intlFormatDate(date)}</span>
        </div>
      </div>
    ) : (
      <div className={styles.chatMessageRemote}>
        <div className={styles.chatMessageRemoteWrapper}>
          <div className={styles.chatMessageRemoteAvatar}>
            {
              avatarUrl
                ? (
                  <img src={avatarUrl} alt="" className={styles.chatMessageRemoteAvatarImage} />
                ) : (
                  <span className={styles.chatMessageRemoteAvatarPlaceholder}>
                    {userInitials[0]}{userInitials[1]}
                  </span>
                )
            }
          </div>
          <div className={styles.chatMessageRemoteBox}>
            <div className={styles.chatMessageRemoteBoxName}>
              {name}
            </div>
            <div className={styles.chatMessageRemoteBoxText}>
              {parsedMessage}
            </div>
            <span className={styles.chatMessageRemoteBoxTime}>{intlFormatDate(date)}</span>
          </div>
        </div>
      </div>
  );
};

export default ChatMessage;
