import React from 'react';
import cn from 'classnames';

import styles from './ErrorBoundary.module.scss';
import { useRouteError } from 'react-router';
import { useTranslation } from 'react-i18next';
import { FiAlertTriangle } from 'react-icons/fi';

const ErrorBoundary = () => {
  const error = useRouteError();
  const [t] = useTranslation();

  return (
    <div className={styles.error}>
      <div className={styles.errorWrapper}>
        <span className={styles.errorIcon}>
          <FiAlertTriangle />
        </span>
        <h4 className={styles.errorTitle}>{t('somethingBadHappens')}</h4>
        <p className={styles.errorText}>{t('pleaseNotifyUs')}</p>
        <div className={styles.errorStack}>
          <h5 className={styles.errorStackTitle}>
            {error.message}
          </h5>
          <pre className={styles.errorStackTrace}>
            {error.stack}
          </pre>
        </div>
      </div>
    </div>
  );
};

export default ErrorBoundary;
