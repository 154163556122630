import React, { useEffect, useState } from 'react';
import cn from 'classnames';

import styles from './GoogleTranslateWarningModal.module.scss';
import { useTranslation } from 'react-i18next';
import Modal from '../../../components/Modal';
import useHtmlClassObserver from '../../../hooks/useHtmlClassObserver';
import { FiAlertTriangle } from 'react-icons/fi';
import Button from '../../../components/Button';

const GoogleTranslateWarningModal = () => {
  const [t, i18n] = useTranslation();

  const htmlElementClassNames = useHtmlClassObserver(document.documentElement);

  const [isOpen, setIsOpen] = useState(false);
  const [hasModalBeenSeen, setHasModalBeenSeen] = useState(false);

  useEffect(() => {
    console.log(htmlElementClassNames.some((className) => className.startsWith('translated')));
    if (htmlElementClassNames.some((className) => className.startsWith('translated'))) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
      setHasModalBeenSeen(false);
    }
  }, [htmlElementClassNames]);

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    localStorage.setItem('userLanguage', language);
  }

  return (
    <Modal
      title="Ой! Ooops! Voooy!"
      open={isOpen && !hasModalBeenSeen}
      onClose={() => {
        setHasModalBeenSeen(true);
        setIsOpen(false);
      }}
      closeOnDocumentClick={false}
      variant="secondary"
    >
      {(close) => (
        <div className={styles.warning}>
          <div className={styles.warningMessages}>
            <div className={styles.warningMessagesItem}>
              <div className={styles.warningMessagesLangIcon}>
                🇺🇿
              </div>
              <h4 className={styles.warningMessagesTitle}>
                Siz saytni tarjima qilmoqchisiz
              </h4>
              <p className={styles.warningMessagesText}>
                Hozirda Google Chrome brauzeridagi maʼlum xatolik tufayli
                ilovamiz Google Tarjimonning avtomatik tarjima funksiyasini
                qoʻllab-quvvatlamaydi. Noqulaylik uchun uzr so'raymiz va bu
                muammoni hal qilish ustida ishlayapmiz.
              </p>
              <p className={styles.warningMessagesText}>
                Ilovamiz uchta tilni qo'llab-quvvatlaydi - o'zbek, rus va
                Ingliz. Yuqorida siz o'zingiz yoqtirgan tilga o'tishingiz mumkin
                menyu
              </p>
              <div className={styles.warningMessagesActions}>
                <Button
                  size="small"
                  type="button"
                  onClick={() => {
                    changeLanguage('uz');
                    close();
                  }}
                  fullWidth
                >
                  Oʻzbek tiliga oʻtish
                </Button>
              </div>
            </div>
            <div className={styles.warningMessagesItem}>
              <div className={styles.warningMessagesLangIcon}>
                🇷🇺
              </div>
              <h4 className={styles.warningMessagesTitle}>
                Похоже Вы хотите перевести сайт
              </h4>
              <p className={styles.warningMessagesText}>
                В настоящее время, из-за известной ошибки в Google Chrome,
                наше приложение не поддерживает функцию автоматического перевода
                Google Translate. Приносим извинения за неудобства и работаем
                над решением этой проблемы
              </p>
              <p className={styles.warningMessagesText}>
                Наше приложение поддерживает три языка - Узбекский, Русский и
                Английский. Вы можете переключить на предпочитаемый язык в верхнем
                меню
              </p>
              <div className={styles.warningMessagesActions}>
                <Button
                  size="small"
                  type="button"
                  onClick={() => {
                    changeLanguage('ru');
                    close();
                  }}
                  fullWidth
                >
                  Переключить на русский язык
                </Button>
              </div>
            </div>
            <div className={styles.warningMessagesItem}>
              <div className={styles.warningMessagesLangIcon}>
                🇬🇧
              </div>
              <h4 className={styles.warningMessagesTitle}>
                It looks like you want to translate the site
              </h4>
              <p className={styles.warningMessagesText}>
                Currently, due to a known bug in Google Chrome, our application
                does not support Google Translate's automatic translation feature.
                We apologize for the inconvenience and are working to resolve
                this issue.
              </p>
              <p className={styles.warningMessagesText}>
                Our application supports three languages - Uzbek, Russian and
                English. You can switch to your preferred language at the top
                menu
              </p>
              <div className={styles.warningMessagesActions}>
                <Button
                  size="small"
                  type="button"
                  onClick={() => {
                    changeLanguage('en');
                    close();
                  }}
                  fullWidth
                >
                  Switch to English language
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default GoogleTranslateWarningModal;
