import React, { useCallback, useMemo, useState } from 'react';
import cn from 'classnames';

import styles from './TutorBioTabs.module.scss';
import Tabs from '../../../components/Tabs';
import { useTranslation } from 'react-i18next';
import Condition from '../../../components/Condition';
import { ReactComponent as AcademicCap } from '../../../icons/AcademicCap.svg';
import { ReactComponent as Briefcase } from '../../../icons/Briefcase.svg';
import { ReactComponent as Diploma } from '../../../icons/Diploma.svg';
import BioItem from '../../../components/BioItem';
import { joinExisting } from '../../../utils';

const TutorBioTabs = ({
  educations,
  workExperiences,
  certificates,
}) => {
  const [t, i18n] = useTranslation();
  const [activeTab, setActiveTab] = useState(0);

  const changeTab = useCallback((tab) => {
    setActiveTab(tab);
  }, []);

  const maxWidths = useMemo(() => [0, '20%', '40%', '60%'], []);

  const tabs = useMemo(() => {
    const allTabs = [
      {
        name: t('Образование'),
        className: 'Button_TeachersEducation',
        value: educations,
        render: (id) => (
          <Condition when={activeTab} is={id} key={id}>
            {
              educations.map((edu) => (
                <BioItem
                  key={edu.id}
                  icon={<AcademicCap />}
                  title={edu.educationInstitution?.name[i18n.language]}
                  additionalTexts={[
                    joinExisting([edu.fromYear, edu.toYear], ' — '),
                    ' · ',
                    edu.speciality[i18n.language],
                    ' · ',
                    edu.educationLevel.name[i18n.language],
                  ]}
                />
              ))
            }
          </Condition>
        ),
      },
      {
        name: t('Опыт работы'),
        className: 'Button_TeachersExperience',
        value: workExperiences,
        render: (id) => (
          <Condition when={activeTab} is={id} key={id}>
            {
              workExperiences.map((work) => (
                <BioItem
                  key={work.id}
                  icon={<Briefcase />}
                  title={work.position?.[i18n.language]}
                  additionalTexts={[
                    joinExisting([work.fromYear, work.toYear], ' — '),
                    ' · ',
                    work.place?.[i18n.language],
                  ]}
                />
              ))
            }
          </Condition>
        ),
      },
      {
        name: t('Сертификаты'),
        className: 'Button_TeachersCertificate',
        value: certificates,
        render: (id) => (
          <Condition when={activeTab} is={id} key={id}>
            {
              certificates?.map((certificate) => (
                <BioItem
                  key={certificate.id}
                  icon={<Diploma />}
                  title={certificate.name?.[i18n.language]}
                  additionalTexts={[
                    certificate.year,
                    ' · ',
                    certificate.issuer?.[i18n.language],
                    ' · ',
                    certificate.score,
                  ]}
                />
              ))
            }
          </Condition>
        ),
      },
    ];

    return allTabs.reduce((acc, tab, i) => {
      const lastAccumulatorIndex = acc.length;
      if (tab.value && tab.value.length > 0) {
        return [...acc, { name: tab.name, id: lastAccumulatorIndex, render: tab.render }];
      }
      return acc;
    }, []);
  }, [activeTab, certificates, educations, i18n.language, t, workExperiences]);

  if (tabs.length === 0) return null;

  return (
    <div className={styles.bio}>
      <div
        className={styles.bioTabs}
        style={{ maxWidth: maxWidths[tabs.length]  }}
      >
        <Tabs
          activeItem={activeTab}
          items={tabs}
          onChange={changeTab}
        />
      </div>
      <div className={styles.bioContent}>
        {
          tabs.map(({ id, render }) => render(id))
        }
      </div>
    </div>
  );
};

export default TutorBioTabs;
