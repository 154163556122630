import React from 'react';
import Select, { components } from 'react-select';
import styles from './LaguageSelect.module.scss';

const LaguageSelect = ({
  options,
  selected,
  defaultSelected,
  onChange,
}) => {
  return (
    <Select
      instanceId="superSelect"
      styles={{
        control: (styles) => ({
          ...styles,
          border: 'none',
          boxShadow: 'none',
          width: '100%',
          backgroundColor: 'transparent',
          zIndex: 2,
        }),
        indicatorSeparator: () => ({ display: 'none' }),
        menu: (styles) => ({
          ...styles,
          boxShadow: 'none',
          backgroundColor: '#F1F4F6',
          minWidth: 100,
          borderRadius: 2,
          overflow: 'hidden',
          left: -10,
          textAlign: 'center',
          zIndex: 2,
        }),
        option: (styles, state) => ({
          ...styles,
          color: state.isSelected ? '#F43838' : 'none',
          backgroundColor: 'none',
        }),
        dropdownIndicator: (styles) => ({ ...styles, display: 'none' })
      }}
      components={{
        SingleValue: (props) => (
          <components.SingleValue {...props}>
            <div className={styles.select}>
              {props.children}
            </div>
          </components.SingleValue>
        )
      }}
      onChange={onChange}
      isSearchable={false}
      defaultValue={defaultSelected}
      value={selected}
      options={options}
    />
  );
};

export default LaguageSelect;
