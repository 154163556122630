import { mainApi } from './index';
import apiRoutes from '../../apiRoutes';
import { invalidateOn } from '../utils';

mainApi.injectEndpoints({
  endpoints: (build) => ({
    getChatDialogs: build.query({
      query: (params) => ({
        url: apiRoutes.dialogs(),
        params,
      }),
      providesTags: ['DIALOGS'],
    }),
    getChatDetails: build.query({
      query: ({ id, params }) => ({
        url: apiRoutes.dialogs(id),
        params,
      }),
      providesTags: ['DIALOG_DETAILS'],
    }),

    getChatMessages: build.query({
      query: (params) => ({
        url: apiRoutes.chatMessages(),
        params,
      }),
      providesTags: ['CHAT_MESSAGES'],
    }),
    getChatFiles: build.query({
      query: (params) => ({
        url: apiRoutes.chatFiles(),
        params,
      }),
      providesTags: ['CHAT_FILES'],
    }),

    postChatMessage: build.mutation({
      query: (body) => ({
        url: apiRoutes.chatMessages(),
        method: 'POST',
        body,
      }),
    }),

    postFileMessage: build.mutation({
      query: (body) => ({
        url: apiRoutes.chatFiles(),
        method: 'POST',
        body,
      }),
    }),

    readMessage: build.mutation({
      query: (body) => ({
        url: apiRoutes.readMessage(),
        method: 'PUT',
        body,
      }),
      invalidatesTags: invalidateOn({ success: ['DIALOG_DETAILS'] }),
    }),

    readAllMessages: build.mutation({
      query: (chatId) => ({
        url: apiRoutes.readAllMessages(chatId),
        method: 'PUT',
      }),
      invalidatesTags: invalidateOn({ success: ['DIALOG_DETAILS', 'DIALOGS'] }),
    }),
    readAllFiles: build.mutation({
      query: (chatId) => ({
        url: apiRoutes.readAllFiles(chatId),
        method: 'PUT',
      }),
      invalidatesTags: invalidateOn({ success: ['DIALOG_DETAILS', 'DIALOGS'] }),
    }),

    startDialog: build.mutation({
      query: (body) => ({
        url: apiRoutes.dialogs(),
        method: 'POST',
        body,
      }),
      invalidatesTags: invalidateOn({ success: ['DIALOG_DETAILS', 'DIALOGS'] }),
    }),

  }),
});

export const {
  useLazyGetChatDialogsQuery,
  useGetChatDetailsQuery,
  useLazyGetChatDetailsQuery,
  useLazyGetChatMessagesQuery,
  useLazyGetChatFilesQuery,
  usePostChatMessageMutation,
  usePostFileMessageMutation,
  useReadMessageMutation,
  useReadAllMessagesMutation,
  useReadAllFilesMutation,
  useStartDialogMutation,
} = mainApi;
