export const externalRoutes = {
  landingTutors: 'https://beshplus.uz/tutors',
  faq: 'https://beshplus.uz/faq',
  about: 'https://beshplus.uz/contacts',
  root: (lang) => {
    switch (lang) {
      case 'ru': return 'https://beshplus.uz/ru';
      case 'uz': return 'https://beshplus.uz';
      case 'en': return 'https://beshplus.uz/en';
      default: return 'https://beshplus.uz';
    }
  },
}
