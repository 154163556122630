import cn from 'classnames';

import styles from './RoomControls.module.scss';
import { FiMessageSquare, FiX } from 'react-icons/fi';
import React from 'react';
import { setChatVisible, useUIRoomState } from '../../../store/slicers/room';
import useMedia from '../../../rtc/hooks/useMedia';
import { useDispatch } from 'react-redux';

const ChatButton = () => {
  const uiRoomState = useUIRoomState();
  const dispatch = useDispatch();

  const chatVisibilityTogglerClasses = cn({
    [styles.controlsButton]: true,
    [styles.controlsButtonToggler]: true,
  });

  const handleClick = () => {
    dispatch(setChatVisible(!uiRoomState.isChatVisible));
  };

  return (
    <button
      type="button"
      className={chatVisibilityTogglerClasses}
      onClick={handleClick}
    >
      {
        uiRoomState.isChatVisible
          ? <FiX/>
          : <FiMessageSquare/>
      }
    </button>
  );
};

export default ChatButton;
