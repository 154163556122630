import React, { useCallback, useState } from 'react';
import cn from 'classnames';

import styles from './TransferPackage.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import TutorSearchForm from '../../../containers/tutorList/TutorSearchForm';
import useQueryString from '../../../hooks/useQueryString';
import { useGetTutorListQuery } from '../../../store/apis/tutors/tutorListApi';
import queryString from 'query-string';
import Pagination from '../../../components/Pagination';
import Title from '../../../components/Title';
import Container from '../../../components/Container';
import { useTranslation } from 'react-i18next';
import Text from '../../../components/Text';
import ContentLoader from 'react-content-loader';
import TeacherCardSelectable from '../../../components/TeacherCardSelectable';
import TransferPackageMessageModal from '../../../components/TransferPackageMessageModal';
import { useTransferPackageMutation } from '../../../store/apis/tariffs';
import { toast } from 'react-toastify';
import appRoutes from '../../../app/appRoutes';

const TransferPackage = () => {
  const [t] = useTranslation();
  const { state } = useLocation();
  const [selectedTeacher, setSelectedTeacher] = useState(null);

  const navigate = useNavigate();
  const { page = 1, pageSize = 20, ...restQuery } = useQueryString();

  const {
    data: teacherData,
    isFetching: isTeacherFetching,
  } = useGetTutorListQuery({
    page,
    pageSize,
    ...restQuery,
  });

  const [
    transferPackage,
    { isLoading },
  ] = useTransferPackageMutation();


  const handlePageChange = useCallback((page) => {
    navigate({
      pathname: '',
      search: queryString.stringify({
        page,
        pageSize,
        ...restQuery,
      }),
    }, { state });
  }, [navigate, pageSize, restQuery, state]);

  const handleSelect = (teacher) => () => {
    setSelectedTeacher(teacher);
  };

  const handleTransfer = async (message) => {
    try {
      await transferPackage({
        packId: state?.pack?.id,
        body: {
          toTutorId: selectedTeacher.id,
          message,
        }
      }).unwrap();
      toast.success(t('Пакет был перенесен на другого учителя'));
      navigate(appRoutes.balance());
    } catch (e) {
      console.log(JSON.stringify(e.data ?? e, 0, 2));
      throw e;
    }
  };

  return (
    <Container>
      <Title level={3} noMargin>{t('Выберите учителя')}</Title>
      <Text level={3} color="secondary" noPadding>{t('Остаток средств в этом пакете будет перенесен в счет другого учителя')}</Text>
      <div className={styles.wrapper}>
        <div className={styles.filters}>
          <TutorSearchForm />
        </div>
        <div className={styles.results}>
          {
            isTeacherFetching
              ? (
                <ContentLoader
                  backgroundColor={'#c8c8c8'}
                  foregroundColor={'#eceaea'}
                  width="100%"
                  height="460"
                  speed={2}
                >
                  <rect x="0" y="0" rx="20" ry="20" width="100%" height="220" />
                  <rect x="0" y="240" rx="20" ry="20" width="100%" height="220" />
                </ContentLoader>
              ) : (
                teacherData?.results.map((teacher) => (
                  <TeacherCardSelectable teacher={teacher} onSelect={handleSelect(teacher)} />
                ))
              )
          }
        </div>
        <div className={styles.pagination}>
          <Pagination
            count={teacherData?.totalPages}
            currentPage={page}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
      <TransferPackageMessageModal
        teacher={selectedTeacher}
        fromTeacher={state?.tutor}
        pack={state?.pack}
        onClose={() => {
          setSelectedTeacher(null);
        }}
        onSubmit={handleTransfer}
        loading={isLoading}
      />
    </Container>
  );
};

export default TransferPackage;
