import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import cn from 'classnames';
import RangeSlider from 'react-range-slider-input';
import styles from './RangeSelect.module.scss';
import { FaCaretDown } from 'react-icons/fa';
import { formatNumber, joinExisting } from '../../utils';
import useToggle from '../../hooks/useToggle';

const RangeSelect = ({
  disabled,
  name,
  label,
  value,
  onChange,
  placeholder,
  diapasonLabel,
  min,
  max,
  step,
  affix,
}, ref) => {
  const [isOpen, show, hide] = useToggle();
  const containerRef = useRef();
  const selectClasses = useMemo(() => cn({
    [styles.select]: true,
    [styles.selectDisabled]: disabled,
  }), [disabled]);

  useEffect(() => {
    document.addEventListener('mousedown', (e) => {
      if (containerRef.current && isOpen && !containerRef.current.contains(e.target)) {
        hide();
      }
    });
  }, [hide, isOpen]);

  const showPopup = useCallback(() => {
    show();
  }, [show]);

  return (
    <div className={selectClasses} onClick={showPopup}>
      <label htmlFor={name}>{label}</label>
      <div className={styles.rangeInput}>
        <div className={styles.rangeInputContainer}>
          {
            value
              ? joinExisting(value.map((v) => formatNumber(v)), ' — ')
              : <span className={styles.rangeInputPlaceholder}>{placeholder || '-'}</span>
          }
          {' '}
          {value ? affix : null}
        </div>
      </div>

      {
        isOpen && (
          <div className={styles.rangeInputPopup} ref={containerRef}>
            <div className={styles.rangeInputPopupValues}>
              {diapasonLabel || '-'}
            </div>
            <RangeSlider
              min={min}
              max={max}
              step={step}
              onInput={onChange}
              value={value}
              ref={ref}
              defaultValue={[min, max]}
            />
          </div>
        )
      }

      <span className={styles.selectIndicator}>
        <FaCaretDown />
      </span>
    </div>
  );
};

export default React.forwardRef(RangeSelect);
