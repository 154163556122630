import apiRoutes from '../../../apiRoutes';
import { mainApi } from '../index';

mainApi.injectEndpoints({
  endpoints: (build) => ({
    getTutorList: build.query({
      query: (params) => ({
        url: apiRoutes.tutors(),
        params,
      }),
      providesTags: ['TUTORS'],
    }),
    getTutorLiteList: build.query({
      query: (params) => ({
        url: apiRoutes.tutorsLite(),
        params,
      }),
      providesTags: ['TUTORS_LITE'],
    }),
    getTutorDetails: build.query({
      query: (id) => ({
        url: apiRoutes.tutors(id),
      }),
      providesTags: ['TUTOR_DETAILS'],
    }),

    getTutorsSingleSchedules: build.query({
      query: (params) => ({
        url: apiRoutes.tutorSingleSchedules(),
        params,
      }),
      providesTags: ['TUTORS_SINGLE_SCHEDULES'],
    }),
    getTutorsWeeklySchedules: build.query({
      query: (params) => ({
        url: apiRoutes.tutorWeeklySchedules(),
        params,
      }),
      providesTags: ['TUTORS_WEEKLY_SCHEDULES'],
    }),
  })
});

export const {
  useGetTutorListQuery,
  useGetTutorLiteListQuery,
  useGetTutorDetailsQuery,
  useLazyGetTutorsSingleSchedulesQuery,
  useGetTutorsSingleSchedulesQuery,
  useGetTutorsWeeklySchedulesQuery,
} = mainApi;
