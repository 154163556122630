import React, { useMemo } from 'react';
import cn from 'classnames';

import styles from './Graduation.module.scss';
import { FaGraduationCap } from 'react-icons/fa';

const Graduation = ({
  text,
  color = 'primary',
  size = 'medium',
  noMargin = false,
}) => {
  const graduationClasses = useMemo(() => cn({
    [styles.graduation]: true,
    [styles.graduationPrimary]: color === 'primary',
    [styles.graduationSecondary]: color === 'secondary',

    [styles.graduationMedium]: size === 'medium',
    [styles.graduationSmall]: size === 'small',

    m0: noMargin,
  }), [color, noMargin, size]);

  return (
    <p className={graduationClasses}>
      <span className={styles.graduationIcon}>
        <FaGraduationCap />
      </span>
      <span className={styles.graduationTitle}>{text}</span>
    </p>
  );
};

export default Graduation;
