import apiRoutes from '../../../apiRoutes';
import { mainApi } from '../index';

mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getPackageCancellationReasons: builder.query({
      query: (params) => ({
        url: apiRoutes.packageCancellationReasons(),
        params
      })
    })
  })
});

export const {
  useGetPackageCancellationReasonsQuery,
} = mainApi;
