import React, { useCallback, useMemo, useState } from 'react';
import cn from 'classnames';
import { find, times as RTimes } from 'ramda';
import styles from './WeekCalendar.module.scss';
import Title from '../Title';
import IconButton from '../IconButton';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import {
  add,
  eachDayOfInterval,
  endOfWeek,
  format,
  getDate,
  getHours,
  isSameDay,
  isToday, isValid, parseISO,
  startOfWeek,
  sub
} from 'date-fns';
import { useTranslation } from 'react-i18next';
import { dateLocales } from '../../localizations/i18n';
import { PulseLoader } from 'react-spinners';

const WeekCalendar = ({
  records = [],
  loading = false,
  onWeekChange = () => {},
}) => {
  const [t, i18n] = useTranslation();
  const [startDate, setStartDate] = useState(startOfWeek(new Date(), { locale: dateLocales[i18n.language] }));
  const [endDate, setEndDate] = useState(endOfWeek(new Date(), { locale: dateLocales[i18n.language] }));

  const weekDays = useMemo(() => eachDayOfInterval({
    start: startDate,
    end: endDate,
  }).map((date, i) => ({
    id: i,
    date: date,
    day: getDate(date),
    label: format(date, i18n.language === 'en' ? 'E' : 'EEEEEE', { locale: dateLocales[i18n.language] }),
  })), [endDate, i18n.language, startDate]);

  const weekDayClasses = useCallback((isToday) => cn({
    [styles.calendarBodyWeekdaysItem]: true,
    [styles.calendarBodyWeekdaysItemActive]: isToday,
  }), []);

  const timesCellsItemClasses = useCallback((isToday) => cn({
    [styles.calendarBodyTimesCellsItem]: true,
    [styles.calendarBodyTimesCellsItemActive]: isToday,
  }), []);

  const times = useMemo(() => RTimes((i) => ({
    id: i,
    timeLabel: i <= 9 ? `0${i}:00` : `${i}:00`,
  }), 24), []);

  const handleWeekBack = useCallback(() => {
    const backDate = sub(startDate, { days: 7 });
    const newStartDate = startOfWeek(backDate, { locale: dateLocales[i18n.language] });
    const newEndDate = endOfWeek(backDate, { locale: dateLocales[i18n.language] });

    setEndDate(newEndDate);
    setStartDate(newStartDate);
    onWeekChange(newStartDate, newEndDate);
  }, [i18n.language, onWeekChange, startDate]);

  const handleWeekForward = useCallback(() => {
    const forwardDate = add(startDate, { days: 7 });
    const newStartDate = startOfWeek(forwardDate, { locale: dateLocales[i18n.language] });
    const newEndDate = endOfWeek(forwardDate, { locale: dateLocales[i18n.language] });

    setEndDate(newEndDate);
    setStartDate(newStartDate);
    onWeekChange(newStartDate, newEndDate);
  }, [i18n.language, onWeekChange, startDate]);

  const getActualRecord = useCallback((timeId, date) => {
    return find(({ date: recordDate }) => (isSameDay(date, parseISO(recordDate)) && getHours(parseISO(recordDate)) === timeId))(records)

  }, [records]);

  return (
    <div className={styles.calendar}>
      <div className={styles.calendarHeader}>
        <div className={styles.calendarHeaderWeekSwitcher}>
          <IconButton
            icon={<FiChevronLeft />}
            color="transparent"
            onClick={handleWeekBack}
          />
          <Title level={5} bold noMargin>
            {format(startDate, 'dd.MM')} - {format(endDate, 'dd.MM')}
          </Title>
          <IconButton
            icon={<FiChevronRight />}
            color="transparent"
            onClick={handleWeekForward}
          />
        </div>
      </div>

      <div className={styles.calendarWrapper}>
        {
          loading && (
            <div className={styles.calendarLoader}>
              <PulseLoader size={24} color="#536DFE" />
            </div>
          )
        }
        <div className={styles.calendarBody}>
          <div className={styles.calendarBodyWeekdays}>
            {weekDays.map((day) => (
              <div className={weekDayClasses(isToday(day.date))} key={day.id}>
                <div className={styles.calendarBodyWeekdaysName}>{day.label}</div>
                <div className={styles.calendarBodyWeekdaysDate}>{day.day}</div>
              </div>
            ))}
          </div>
          {
            times.map(({ id: timeId, timeLabel }) => (
              <div className={styles.calendarBodyTimes} key={timeId}>
                <div className={styles.calendarBodyTimesName}>{timeLabel}</div>
                <div className={styles.calendarBodyTimesCells}>
                  {
                    weekDays.map(({ id: dayId, date }) => {
                      const record = getActualRecord(timeId, date);
                      return (
                        <div className={timesCellsItemClasses(isToday(date))} key={dayId}>
                          {record && record.render()}
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  );
};

export default WeekCalendar;
