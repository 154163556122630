import React from 'react';
import cn from 'classnames';

import styles from './Welcome.module.scss';
import { useTranslation } from 'react-i18next';
import { useGetQuoteOfTheDayQuery } from '../../../store/apis/staticContents';
import ContentLoader from 'react-content-loader';
import NearestLesson from '../../../containers/welcome/NearestLesson';
import UnreadChatMessages from '../../../containers/welcome/UnreadChatMessages';
import Balance from '../../../containers/welcome/Balance';

const Welcome = () => {
  const [t, i18n] = useTranslation();

  const {
    data: quote,
    isLoading,
    isFetching,
  } = useGetQuoteOfTheDayQuery();

  if (isLoading || isFetching) return (
    <div className={styles.skeleton}>
      <ContentLoader width="100%" height="100%">
        <rect x="0" y="0" rx="20" ry="20" width="100%" height="100%" />
      </ContentLoader>
    </div>
  )

  return (
    <div className={styles.dashboard}>

      <div className={styles.welcome}>
        {
          quote?.imageUrl && (
            <img src={quote.imageUrl[i18n.language]} alt="" className={styles.welcomeImage}/>
          )
        }
        <div className={styles.welcomeCite}>
          {
            quote.header && (
              <h3 className={styles.welcomeHeader}>{quote.header[i18n.language]}</h3>
            )
          }
          {
            quote.text && (
              <h2 className={styles.welcomeTitle}>{quote.text[i18n.language]}</h2>
            )
          }
          <p className={styles.welcomeFooter}>
            {
              quote.author && (
                <span className={styles.welcomeAuthor}>{quote.author[i18n.language]}</span>
              )
            }
            {quote.author && quote.source && (' - ')}
            {
              quote.source && (
                <span className={styles.welcomeSource}>{quote.source[i18n.language]}</span>
              )
            }
          </p>
        </div>
      </div>

      <div className={styles.actions}>
        <div className={styles.actionsItem}>
          <NearestLesson />
        </div>
        <div className={styles.actionsItem}>
          <UnreadChatMessages />
        </div>
        <div className={styles.actionsItem}>
          <Balance />
        </div>
      </div>
    </div>
  );
};

export default Welcome;
