import React, { useMemo } from 'react';

import styles from './TutorDetailCard.module.scss';
import { FaStar } from 'react-icons/fa';
import Title from '../../../components/Title';
import Text from '../../../components/Text';
import { ReactComponent as ChatUser } from '../../../icons/ChatUser.svg';
import { formatNumber, getCorrectYearLabel } from '../../../utils';
import InlineData from '../../../components/InlineData';
import { useTranslation } from 'react-i18next';
import LanguageSkillTag from '../../../components/LanguageSkillTag';
import { head, map, split } from 'ramda';

const TutorDetailCard = ({
  photo,
  price,
  video,
  fullName,
  age,
  subject,
  yearsOfWorkExperience,
  completedLessonCount,
  languageSkills,
}) => {
  const [t, i18n] = useTranslation();
  const userInitials = useMemo(() =>
      fullName ? map((nameItem) => head(nameItem), split(' ', fullName)) : ['🫠', null],
    [fullName]
  );

  return (
    <div className={styles.card}>
      <div className={styles.cardPhoto}>
        {
          photo
            ? <img src={photo} alt="" />
            : <div className={styles.cardPhotoPlaceholder}>{userInitials[0]}{userInitials[1]}</div>
        }
        {/*<Modal*/}
        {/*  title={fullName}*/}
        {/*  trigger={*/}
        {/*    <button type="button" className={styles.cardPlay}>*/}
        {/*      <span className={styles.cardPlayButton}>*/}
        {/*        <FaPlay />*/}
        {/*      </span>*/}
        {/*    </button>*/}
        {/*  }*/}
        {/*>*/}
        {/*  {(close) => (*/}
        {/*    <ReactPlayer*/}
        {/*      controls*/}
        {/*      url={video}*/}
        {/*      width="600px"*/}
        {/*      height="400px"*/}
        {/*    />*/}
        {/*  )}*/}
        {/*</Modal>*/}
      </div>
      <div className={styles.cardInfo}>
        <div className={styles.cardInfoName}>
          <Title level={4} noMargin>
            {fullName}
          </Title>
          <Text level={2} bold color="secondary" noPadding>{t('Возраст')}: {age}</Text>
        </div>

        <div className={styles.cardInfoSubject}>
          <div className={styles.cardInfoSubjectIcon}>
            <ChatUser />
          </div>
          <div className={styles.cardInfoSubjectName}>
            <Title level={5} bold noMargin>
              {subject}
            </Title>
          </div>
        </div>

        <div className={styles.cardInfoItem}>
          <InlineData label={t('Опыт')} value={t('experienceYears', { count: yearsOfWorkExperience })} />
        </div>
        <div className={styles.cardInfoItem}>
          <InlineData label={t('Провел уроков')} value={completedLessonCount} />
        </div>
        <div className={styles.cardInfoItem}>
          <InlineData
            label={t('Владею языками')}
            value={
            <div className={styles.cardInfoLanguages}>
              {languageSkills?.map((lang) => (
                <LanguageSkillTag
                  key={lang.id}
                  language={lang.language?.shortName[i18n.language]}
                  level={lang.languageSkillLevel?.shortName[i18n.language]}
                  description={lang.languageSkillLevel?.description[i18n.language]}
                />
              ))}
            </div>
            }
          />
        </div>
      </div>
      <div className={styles.cardPrice}>
        <div className={styles.cardPriceTitle}>
          <Title level={4} noMargin>{formatNumber(price)} {t('сум')}</Title>
          <Text level={3} color="secondary">{t('за 50 минут')}</Text>
        </div>
        {/*<div className={styles.reviewsCount}>*/}
        {/*  <span className={styles.reviewsCountStar}><FaStar /></span>*/}
        {/*  <span className={styles.reviewsCountPoints}>4.8</span>*/}
        {/*  <span className={styles.reviewsCountDivider} />*/}
        {/*  <span className={styles.reviewsCountCounts}>212</span>*/}
        {/*  <span className={styles.reviewsCountLabel}>Оценок</span>*/}
        {/*</div>*/}
      </div>
    </div>
  );
};

export default TutorDetailCard;
