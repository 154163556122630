import React, { useMemo } from 'react';
import cn from 'classnames';

import styles from './WeekCalendarRecord.module.scss';
import Modal from '../Modal';
import { useTranslation } from 'react-i18next';
import ScheduleCard from '../../containers/schedule/ScheduleCard';
import { formatDate, formatTime } from '../../utils';

const WeekCalendarRecord = ({
  schedule,
  lessonTime,
  lessonId,
  cancelAvailable,
  transferAvailable,
  startAvailable,
  classroomId,
  rescheduleData,
  cancelData,
  color = 'primary',
}) => {
  // TODO: Добавить реальные данные об уроке
  const [t, i18n] = useTranslation();
  const recordClasses = useMemo(() => cn({
    [styles.record]: true,

    [styles.primary]: color === 'primary',
    [styles.danger]: color === 'danger',
    [styles.warning]: color === 'warning',
    [styles.success]: color === 'success',
    [styles.secondary]: color === 'secondary',
  }), [color]);

  return (
    <Modal
      trigger={
        <div className={recordClasses}>
          <div className={styles.recordHeader}>
            {formatTime(lessonTime)}
          </div>
          <div className={styles.recordBody}>
            <div className={styles.recordText}>{schedule.tutor.fullName?.[i18n.language]}</div>
            <div className={styles.recordText}>{schedule.subject.name[i18n.language]}</div>
          </div>
        </div>
      }
      title={schedule.subject.name[i18n.language]}
      children={(close) => (
        <div className={styles.content}>
          <ScheduleCard
            scheduleId={schedule.id}
            lessonTime={lessonTime}
            roomId={lessonId}
            date={formatDate(lessonTime) || '-'}
            time={formatTime(lessonTime) || '-'}
            tutorPhoto={schedule.tutor.photoUrl}
            tutorName={schedule.tutor.fullName?.[i18n.language]}
            tutorId={schedule.tutor.id}
            tutorGraduation={schedule.tutor.shortInfo[i18n.language]}
            cancelAvailable={cancelAvailable}
            transferAvailable={transferAvailable}
            startAvailable={startAvailable}
            classroomId={classroomId}
            rescheduleData={rescheduleData}
            cancelData={cancelData}
            subject={schedule.subject.name[i18n.language]}
            isActive={schedule.isActive}
          />
        </div>
      )}
    />
  );
};

export default WeekCalendarRecord;
