import React, { useMemo } from 'react';
import cn from 'classnames';

import styles from './Button.module.scss';
import { PulseLoader } from 'react-spinners';

const Button = ({
  children,
  type = 'button',
  size = 'medium',
  color = 'primary',
  fullWidth = false,
  icon,
  className,
  iconPosition = 'end',
  onClick,
  loading,
  disabled,
}, ref) => {
  const buttonsClasses = useMemo(() => cn({
    [styles.button]: true,
    //sizes
    [styles.buttonMini]: size === 'mini',
    [styles.buttonSmall]: size === 'small',
    [styles.buttonMedium]: size === 'medium',
    [styles.buttonLarge]: size === 'large',
    //colors
    [styles.buttonPrimary]: color === 'primary',
    [styles.buttonSecondary]: color === 'secondary',
    [styles.buttonLight]: color === 'light',
    [styles.buttonContrast]: color === 'contrast',
    [styles.buttonDanger]: color === 'danger',
    [styles.buttonDangerOutline]: color === 'danger-outline',
    [styles.buttonWarning]: color === 'warning',
    [styles.buttonSuccess]: color === 'success',

    w100: fullWidth,
    [className]: className,
  }), [className, color, fullWidth, size]);

  const loaderSizes = useMemo(() => ({
    small: 6,
    medium: 10,
    large: 12,
  }), []);

  const loaderColors = useMemo(() => ({
    primary: '#fff',
    secondary: '#1D2B4F',
    light: '#1D2B4F',
    contrast: '#536DFE',
    danger: '#fff',
  }), []);

  return (
    <button className={buttonsClasses} type={type} onClick={onClick} disabled={loading || disabled} ref={ref}>
      {
        icon && iconPosition === 'start' && (
          <span className={styles.iconPrepend}>
        {icon}
      </span>
        )
      }
      {
        loading
          ? <PulseLoader size={loaderSizes[size]} color={loaderColors[color]} className={styles.loader} />
          : children
      }
      {
        icon && iconPosition === 'end' && (
          <span className={styles.iconAppend}>
            {icon}
          </span>
        )
      }
    </button>
  );
};

export default React.forwardRef(Button);
