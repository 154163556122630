import React, { useMemo } from 'react';
import cn from 'classnames';

import styles from './Rating.module.scss';
import { FaStar } from 'react-icons/fa';

const Rating = ({
  rating,
  color = 'primary',
  className = '',
}) => {
  const rateClasses = useMemo(() => cn({
    [styles.rate]: true,
    [styles.ratePrimary]: color === 'primary',
    [styles.rateSecondary]: color === 'secondary',
    [className]: className,
  }), [className, color]);

  return (
    <span className={rateClasses}>
      <FaStar color="#FFC107" />
      <span>{rating}</span>
    </span>
  );
};

export default Rating;
