import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';

const initialState = {
  unreadCount: 0,
  list: [],
};

export const notifications = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setInitialNotifications: (state, { payload: notifications }) => {
      state.unreadCount = notifications.reduce(
        (acc, notification) => notification.isRead ? acc : acc + 1,
        0,
      );
      state.list = notifications;
    },
    resetUnreadNotificationCount: (state) => {
      state.unreadCount = 0;
    },
    addNotification: (state, { payload: notification }) => {
      state.unreadCount += 1;
      state.list.unshift(notification);
    },

  },
});

export const useNotifications = () => {
  return useSelector((state) => state.notifications)
};

export const {
  setInitialNotifications,
  resetUnreadNotificationCount,
  addNotification,
} = notifications.actions;
