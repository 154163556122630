import React, { useMemo } from 'react';
import cn from 'classnames';

import styles from './FileInfo.module.scss';

const FileInfo = ({
  name,
  color = 'primary',
  size,
  icon,
  actionText,
  url,
}) => {

  const iconClasses = useMemo(() => cn({
    [styles.fileIcon]: true,
    [styles.fileIconPrimary]: color === 'primary',
    [styles.fileIconSecondary]: color === 'secondary',
  }), [color]);

  const sizeClasses = useMemo(() => cn({
    [styles.fileInfoSize]: true,
    [styles.fileInfoSizePrimary]: color === 'primary',
    [styles.fileInfoSizeSecondary]: color === 'secondary',
  }), [color]);

  const actionClasses = useMemo(() => cn({
    [styles.fileInfoAction]: true,
    [styles.fileInfoActionPrimary]: color === 'primary',
    [styles.fileInfoActionSecondary]: color === 'secondary',
  }), [color]);

  return (
    <div className={styles.file}>
      <div className={iconClasses}>
        {icon}
      </div>
      <div className={styles.fileInfo}>
        <div className={styles.fileInfoName}>{name}</div>
        <div className={styles.fileInfoFooter}>
          <div className={sizeClasses}>{size}</div>
          {' - '}
          <a className={actionClasses} href={url} target="_blank" download rel="noopener noreferrer">
            {actionText}
          </a>
        </div>
      </div>
    </div>
  );
};

export default FileInfo;
