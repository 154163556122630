import { io } from 'socket.io-client';
import { baseUrl } from './apiRoutes';
import { joinExisting } from './utils';
import localforage from 'localforage';

const socketUrl = joinExisting([baseUrl, 'socket']);

export const socket = io(
  socketUrl,
  {
    transports: ['websocket'],
    // autoConnect: false,
    auth: (cb) => {
      localforage.getItem('credentials')
        .then((creds) => {
          if (!creds) {
            cb();
            return;
          }
          cb({
            token: creds.accessToken,
          })
        })
    },
  },
);
