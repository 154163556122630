import apiRoutes from '../../../apiRoutes';
import { mainApi } from '../index';

mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getFaqs: builder.query({
      query: (params) => ({
        url: apiRoutes.faqs(),
        params
      }),
    })
  })
});

export const {
  useGetFaqsQuery,
} = mainApi
