// import cn from 'classnames';

import styles from './Help.module.scss';
import Container from '../../../components/Container';
import Title from '../../../components/Title';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Text from '../../../components/Text';
import HelpCardItem from '../../../components/HelpCardItem';
import { FiPhoneCall } from 'react-icons/fi';
import { LiaTelegram } from 'react-icons/lia';
import FaqItem from '../../../components/FaqItem';
import { useGetFaqsQuery } from '../../../store/apis/dictionaries/faqs';
import ContentLoader from 'react-content-loader';
import ModalControlled from '../../../components/Modal/ModalControlled';
import ReactPlayer from 'react-player';

const Help = () => {
  const [t, i18n] = useTranslation();
  const [videoModal, setVideoModal] = useState({
    open: false,
    title: '',
    videoUrl: '',
  });

  const openVideoModal = (title, videoUrl) => {
    setVideoModal({ open: true, title, videoUrl });
  };

  const closeVideoModal = () => {
    setVideoModal({ open: false, title: '', videoUrl: '' });
  };

  const {
    data: faqs,
    isFetching: isFaqsFetching,
  } = useGetFaqsQuery({
    tagAlias: 'main_student',
    page: -1,
  });

  const faqItems = faqs?.results ? faqs.results.map(({ id, question, answer, explanationVideo }) => ({
    key: id,
    title: question[i18n.language],
    body: answer[i18n.language],
    videoUrl: explanationVideo[i18n.language],
  })) : [];

  return (
    <Container>
      <Title level={3}>{t('Нужна помощь?')}</Title>
      <Text color="secondary" level={2}>
        {t('Свяжитесь с нами любым удобным Вам способом, наша техническая поддержка работает с 09:00 до 20:00')}
      </Text>

      <div className={styles.header}>
        <div className={styles.headerItem}>
          <HelpCardItem
            icon={<FiPhoneCall />}
            link="tel:+998787770360"
            label={t('Телефон')}
            value="+998 78 777 03 60"
          />
        </div>
        <div className={styles.headerItem}>
          <HelpCardItem
            icon={<LiaTelegram />}
            link="https://t.me/Beshplusuz"
            label={t('Телеграм')}
            value="@Beshplusuz"
          />
        </div>
      </div>

      <div className={styles.body}>
        <Title level={4}>{t('Отвечаем на часто задаваемые вопросы')}</Title>
        <div className={styles.bodyFaq}>
          {
            isFaqsFetching
              ? (
                [1, 2, 3, 4, 5, 6].map((key) => (
                  <div className={styles.bodyFaqItem} key={key}>
                    <ContentLoader
                      width="100%"
                      height="100"
                      backgroundColor={'#c8c8c8'}
                      foregroundColor={'#eceaea'}
                      speed={2}
                    >
                      <rect x="0" y="0" rx="12" ry="12" width="100%" height="100" />
                    </ContentLoader>
                  </div>
                ))
              ) : (
                faqItems.map(({ key, title, body, videoUrl }) => (
                  <div className={styles.bodyFaqItem} key={key}>
                    <FaqItem
                      body={body}
                      title={title}
                      videoUrl={videoUrl}
                      onVideoClick={() => openVideoModal(title, videoUrl)}
                    />
                  </div>
                ))
              )
          }
        </div>
      </div>
      <ModalControlled
        open={videoModal.open}
        onClose={closeVideoModal}
        closeOnDocumentClick={false}
        variant="secondary"
      >
        <h4 className={styles.videoContainerTitle}>
          {videoModal.title}
        </h4>
        <div className={styles.videoContainer}>
          <ReactPlayer
            controls
            url={videoModal.videoUrl}
            width="100%"
            height="100%"
          />
        </div>
      </ModalControlled>
    </Container>
  );
};

export default Help;
