import apiRoutes from '../../apiRoutes';
import { mainApi } from './index';

mainApi.injectEndpoints({
  endpoints: (builder) => ({
    upload: builder.mutation({
      query: (body) => ({
        url: apiRoutes.uploadFile(),
        method: 'POST',
        body,
      }),
    })
  })
})


export const {
  useUploadMutation,
} = mainApi;
