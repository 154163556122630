import React, { useCallback, useMemo } from 'react';

import styles from './RescheduleLessons.module.scss';
import { FiRotateCcw } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Button';
import TextareaInputController from '../../../components/TextareaInput/TextareaInputController';
import Modal from '../../../components/Modal';
import { Controller, useForm } from 'react-hook-form';
import LessonDates from '../../../components/LessonDates';
import { formatDate, formatTime } from '../../../utils';
import UserInfoSmall from '../../../components/UserInfoSmall';
import TutorScheduleSingleLesson from '../../tutor/TutorScheduleSingleLesson';
import { addHours, formatISO, parseISO } from 'date-fns';
import { head } from 'ramda';
import { createDataSchema } from '../../../yup';
import { mixed, } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { useRescheduleLessonMutation } from '../../../store/apis/lessons/scheduled';

const rescheduleFormSchema = createDataSchema({
  toLessonTime: mixed().required(),
});

const RescheduleLessons = ({
  scheduleId,
  lessonTime,
  tutorInfo,
  tutorId,
}) => {
  const [t] = useTranslation();
  const today = useMemo(() => addHours(new Date(), 24), []);

  const {
    control,
    handleSubmit: submit,
    register,
    reset,
  } = useForm({
    resolver: yupResolver(rescheduleFormSchema),
    defaultValues: {
      fromLessonTime: lessonTime,
    },
  });

  const [
    rescheduleLesson,
    { isLoading: isRescheduleLoading, isFetching: isRescheduleFetching },
  ] = useRescheduleLessonMutation();

  const closeModal = useCallback((close) => () => {
    reset();
    close();
  }, [reset]);

  const handleSubmit = useCallback((close) => async (values) => {
    try {
      const toLessonTime = formatISO(parseISO(values.toLessonTime.value));

      await rescheduleLesson({ body: { ...values, toLessonTime }, scheduleId }).unwrap();
      toast.success(t('Запрос учителю на перенос урока был отправлен Ожидайте ответа от учителя'))
    } catch (e) {
      console.log(JSON.stringify(e.data ?? e, 0, 2));
    }
  }, [rescheduleLesson, scheduleId, t]);

  const handleValidationError = useCallback((errors) => {
    if (errors.toLessonTime) {
      toast.warning(t('Пожалуйста выберите время на которое Вы хотите перенести урок'));
    }
    if (errors.fromLessonTime) {
      toast.warning(t('Мы не смогли текущую дату и время урока'));
    }
  }, [t]);

  return (
    <Modal
      title={t('Перенос урока')}
      variant="secondary"
      subtitle={t('Для переноса урока необходимо получить подтверждение от учителя Если учитель откажет в переносе, то урок будет отменен')}
      trigger={
        <Button
          type="button"
          size="mini"
          color="warning"
          fullWidth
          icon={<FiRotateCcw />}
          iconPosition="start"
        >
          {t('Перенести урок')}
        </Button>
      }
    >
      {(close) => (
        <form onSubmit={submit(handleSubmit(close), handleValidationError)} className={styles.form}>
          <input type="hidden" {...register('fromLessonTime')}/>
          <div className={styles.formLesson}>
            <div className={styles.formLessonTime}>
              <LessonDates date={formatDate(lessonTime)} time={formatTime(lessonTime)} />
            </div>
            <UserInfoSmall {...tutorInfo} />
          </div>

          <Controller
            control={control}
            name="toLessonTime"
            render={({ field }) => (
              <TutorScheduleSingleLesson
                defaultSelectedDate={today}
                selectedTimeSlots={[field.value]}
                tutorId={tutorId}
                multiple={false}
                onSelectedTimeSlotsChange={(slots) => {
                  field.onChange(head(slots));
                }}
              />
            )}
          />

          <TextareaInputController
            control={control}
            name="comment"
            label={t('Комментарий')}
            rows={2}
            placeholder={t('Напишите короткий комментарий к переносу урока, чтобы уведомить учителя')}
          />
          <div className={styles.formFooter}>
            <Button
              type="button"
              onClick={closeModal(close)}
              color="secondary"
            >
              {t('Закрыть')}
            </Button>
            <Button
              type="submit"
              color="warning"
              loading={isRescheduleLoading || isRescheduleFetching}
            >
              {t('Перенести урок')}
            </Button>
          </div>
        </form>
      )}
    </Modal>
  );
};

export default RescheduleLessons;
