import { useTranslation } from 'react-i18next';

const useTranslatableConstants = () => {
  const [t] = useTranslation();

  const weekDayNames = [
    null,
    t('Понедельник'),
    t('Вторник'),
    t('Среда'),
    t('Четверг'),
    t('Пятница'),
    t('Суббота'),
    t('Воскресение'),
  ];

  const errorMessages = {
    NO_DEVICES: t('Вам нужна веб камера и микрофон, чтобы подключиться к уроку'),
    NO_AUDIO: t('Вам нужен хотя бы микрофон для того, чтобы подключиться к уроку'),
    CANNOT_GET_USER_MEDIA: t('Не удалось получить доступ к веб камере или микрофону'),
  };

  return { weekDayNames, errorMessages };
};

export default useTranslatableConstants;
