import React from 'react';
import { Controller, get as getError, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ComplexTextInput from './ComplexTextInput';
// import cn from 'classnames';

const ComplexTextInputController = ({
  name,
  control,
  ...rest
}) => {
  const [t] = useTranslation();
  const { errors } = useFormState({ control });
  const error = getError(errors, name);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <ComplexTextInput
          {...field}
          {...rest}
          name={name}
          placeholder={rest.placeholder || 'Выберите'}
          selected={field.value}
          error={t(error?.message)}
        />
      )}
    />
  );
};

export default ComplexTextInputController;
