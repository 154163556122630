import React, { useCallback, useMemo } from 'react';
import cn from 'classnames';

import styles from './App.module.scss';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import appRoutes from '../../app/appRoutes';
import Divider from '../../components/Divider';
import { useTranslation } from 'react-i18next';
import { FiHeart } from 'react-icons/fi';
import { useLocation } from 'react-router';
import SimpleSelect from '../../components/SimpleSelect';
import ProfileDropdown from '../../containers/profile/ProfileDropdown';

import { ReactComponent as BookIcon } from '../../icons/Book.svg';
import { ReactComponent as PlaybookIcon } from '../../icons/Playbook.svg';
import { ReactComponent as ChatsIcon } from '../../icons/Chats.svg';
import { ReactComponent as CreditCardIcon } from '../../icons/CreditCard.svg';
import useSockets from '../../hooks/useSockets';
import NotificationBar from '../../containers/layout/NotificationBar';
import queryString from 'query-string';
import { useBookTrialLessonMutation } from '../../store/apis/lessons/scheduled';
import UnpaidInvoiceAlert from '../../containers/layout/UnpaidInvoiceAlert';
import ScrollToTop from '../../components/ScrollToTop';
import CancellingLessonsAlert from '../../containers/layout/CancellingLessonsAlert';

const languages = [
  { id: 'ru', value: 'ru', label: 'Русский', chipValue: 'Рус' },
  { id: 'uz', value: 'uz', label: 'O`zbek tili', chipValue: 'Узб' },
  { id: 'en', value: 'en', label: 'English', chipValue: 'Eng' },
];

const App = () => {
  const [t, i18n] = useTranslation();
  const location = useLocation();
  const currentLanguage = languages.find((l) => l.id === i18n.language);

  const [operate, setOperate] = useBookTrialLessonMutation();

  const navLinkClasses = useCallback((isActive) => cn({
    [styles.appSidebarLink]: true,
    [styles.appSidebarLinkActive]: isActive,
  }), []);

  const contentHeaderClasses = useMemo(() => cn({
    [styles.appContentHeader]: true,
  }), []);

  const handleChangeLanguage = useCallback((language) => {
    i18n.changeLanguage(language.value);
    localStorage.setItem('userLanguage', language.value);
  }, [i18n]);

  const nav = useMemo(() => [
    {
      id: appRoutes.tutors(),
      label: t('Каталог'),
      icon: <BookIcon />,
    },
    {
      id: appRoutes.schedule(),
      label: t('Расписание'),
      icon: <PlaybookIcon />,
    },
    {
      id: appRoutes.chats(),
      label: t('Чаты'),
      icon: <ChatsIcon />,
    },
    {
      id: appRoutes.balance(),
      label: t('Баланс'),
      icon: <CreditCardIcon />,
    },
  ], [t]);

  useSockets();
  const navigate = useNavigate();

  return (
    <div className={styles.app}>
      <ScrollToTop />
      <div className={styles.appSidebar}>
        <div className={styles.appSidebarBrand}>
          <Link to={appRoutes.app()} className={styles.appSidebarBrandLink}>
            <img src="/images/logo-simple-fiveplus.png" alt=""/>
          </Link>
        </div>
        <Divider mb="2em" className={styles.appSidebarDivider} />
        {
          nav.map(({ id, label, icon }) => (
            <Link to={id} key={id} className={navLinkClasses(location.pathname === id)}>
              <span className={styles.appSidebarLinkIcon}>
                {icon}
              </span>
              <span className={styles.appSidebarLinkText}>{label}</span>
            </Link>
          ))
        }
      </div>
      <div className={styles.appContent}>
        <div className={contentHeaderClasses}>
          <div className={styles.appContentAside}>
            {
              process.env.REACT_APP_ENV === 'development' && (
                <div className={styles.appContentAsideItem}>
                  <button
                    type="button"
                    onClick={() => {
                      operate()
                    }}
                  >Err</button>
                </div>
              )
            }
            {
              process.env.REACT_APP_ENV === 'development' && (
                <div className={styles.appContentAsideItem}>
                  <button
                    type="button"
                    onClick={() => {
                      const roomId = prompt('Room id');

                      navigate({
                        pathname: appRoutes.room(),
                        search: queryString.stringify({
                          roomId,
                          tutorId: '32a33960-05c8-11ee-a7a8-9330efc8b5ca',
                        }),
                      });
                    }}
                  >cc</button>
                </div>
              )
            }
            <div className={styles.appContentAsideItem}>
              <SimpleSelect
                selected={currentLanguage}
                options={languages}
                onChange={handleChangeLanguage}
              />
            </div>
            <div className={styles.appContentAsideItem}>
              <Link to={appRoutes.favorites()} className={styles.appContentAsideFavorite}>
                <FiHeart />
              </Link>
            </div>
            <div className={styles.appContentAsideItem}>
              <NotificationBar />
            </div>
            <div className={styles.appContentAsideItem}>
              <ProfileDropdown />
            </div>
          </div>
        </div>
        <div className={styles.appContentBody}>
          <CancellingLessonsAlert />
          <UnpaidInvoiceAlert />
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default App;
