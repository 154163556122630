import React, { useCallback } from 'react';
import cn from 'classnames';

import styles from './SearchInput.module.scss';
import { FiSearch } from 'react-icons/fi';

const SearchInput = ({
  value,
  onChange,
  placeholder,
}) => {

  const handleChange = useCallback((e) => {
    onChange(e.target.value);
  }, [onChange]);

  return (
    <div className={styles.container}>
      <div className={styles.icon}>
        <FiSearch />
      </div>
      <input
        type="text"
        onChange={handleChange}
        value={value}
        className={styles.input}
        placeholder={placeholder}
      />
    </div>
  );
};

export default SearchInput;
