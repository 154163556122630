import React from 'react';
import cn from 'classnames';

import styles from './EmptySimple.module.scss';
import Title from '../Title';
import Text from '../Text';

const EmptySimple = ({ title, text, align }) => {

  return (
    <div className={styles.empty} style={{ textAlign: align }}>
      <Title level={5} bold>{title}</Title>
      <Text level={3} color="secondary">{text}</Text>
    </div>
  );
};

export default EmptySimple;
