import React, { useCallback, useEffect, useMemo, useState } from 'react';
import cn from 'classnames';

import styles from './Chats.module.scss';
import ChatDialogs from '../../../containers/chat/ChatDialogs';
import ChatWindow from '../../../containers/chat/ChatWindow';
import { closeChat } from '../../../store/slicers/chat';
import { useDispatch } from 'react-redux';

const Chats = () => {
  const [openedChat, setOpenedChat] = useState(null);
  const dispatch = useDispatch();

  const windowClasses = useMemo(() => cn({
    [styles.window]: true,
    [styles.windowVisible]: openedChat,
  }), [openedChat]);

  const dialogsClasses = useMemo(() => cn({
    [styles.chatsDialog]: true,
    [styles.chatsDialogClosed]: openedChat,
  }), [openedChat]);

  const handleOpenWindow = useCallback((chatInfo) => {
    setOpenedChat(chatInfo);
  }, []);

  const handleCloseWindow = useCallback(() => {
    setOpenedChat(null);
  }, []);

  useEffect(() => {
    return () => {
      dispatch(closeChat());
    };
  }, [dispatch]);

  return (
    <div className={styles.chats}>
      <div className={dialogsClasses}>
        <ChatDialogs onDialogClick={handleOpenWindow} />
      </div>
      <div className={windowClasses}>
        <ChatWindow onBackClick={handleCloseWindow} />
      </div>
    </div>
  );
};

export default Chats;
