import React, { useCallback } from 'react';
import cn from 'classnames';

import styles from './CancelLessonForm.module.scss';
import Button from '../../../components/Button';
import Modal from '../../../components/Modal';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import TextareaInputController from '../../../components/TextareaInput/TextareaInputController';
import { useCancelLessonMutation } from '../../../store/apis/lessons/scheduled';
import { toast } from 'react-toastify';
import { FiRotateCcw, FiXCircle } from 'react-icons/fi';
import UserInfoSmall from '../../../components/UserInfoSmall';
import { formatDate, formatTime } from '../../../utils';
import LessonDates from '../../../components/LessonDates';

const CancelLessonForm = ({
  lessonTime,
  scheduleId,
  tutorInfo,
}) => {
  const [t] = useTranslation();
  const [cancelLesson, { isLoading, isFetching }] = useCancelLessonMutation();

  const {
    control,
    handleSubmit: submit,
    register,
    reset,
  } = useForm({
    defaultValues: {
      comment: '',
      lessonTime,
    },
  });

  const closeModal = useCallback((close) => () => {
    reset();
    close();
  }, [reset]);

  const handleSubmit = useCallback((close) => async (values) => {
    try {
      await cancelLesson({ scheduleId, body: values }).unwrap();
      toast.success(t('Урок отменен! Мы уведомим учителя об этом'));
      reset();
      close();
    } catch (e) {
      console.log(JSON.stringify(e.data ?? e, 0, 2));
    }
  }, [cancelLesson, reset, scheduleId, t]);

  return (
    <Modal
      title={t('Отмена урока')}
      variant="secondary"
      subtitle={t('Вы можете отменить не более {{n}} уроков в месяц Отменяя урок деньги с баланса не списываются', { n: 3 })}
      trigger={
        <Button
          type="button"
          size="mini"
          color="danger"
          fullWidth
          icon={<FiXCircle />}
          iconPosition="start"
        >
          {t('Отменить урок')}
        </Button>
      }
    >
      {(close) => (
        <form onSubmit={submit(handleSubmit(close))} className={styles.form}>
          <input type="hidden" {...register('lessonTime')}/>
          <div className={styles.formLesson}>
            <div className={styles.formLessonTime}>
              <LessonDates date={formatDate(lessonTime)} time={formatTime(lessonTime)} />
            </div>
            <UserInfoSmall {...tutorInfo} />
          </div>
          <TextareaInputController
            control={control}
            name="comment"
            label={t('Комментарий')}
            rows={2}
            placeholder={t('Напишите короткий комментарий к отмене, чтобы уведомить учителя')}
          />
          <div className={styles.formFooter}>
            <Button
              type="button"
              onClick={closeModal(close)}
              color="secondary"
              size="small"
            >
              {t('Закрыть')}
            </Button>
            <Button
              type="submit"
              color="danger"
              size="small"
              loading={isFetching || isLoading}
            >
              {t('Отменить урок')}
            </Button>
          </div>
        </form>
      )}
    </Modal>
  );
};

export default CancelLessonForm;
