import React from 'react';
import cn from 'classnames';

import styles from './RatingInput.module.scss';
import { Rating, RoundedStar } from '@smastrom/react-rating';

const CustomStar = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M8 0C8.27681 0 8.52962 0.16447 8.65215 0.424263L10.7304 4.8308L15.3779 5.54178C15.6518 5.58367 15.8792 5.78463 15.9645 6.06018C16.0499 6.33574 15.9784 6.63812 15.7801 6.84023L12.4178 10.2678L13.2113 15.1101C13.2581 15.3957 13.146 15.6844 12.922 15.8547C12.698 16.025 12.4011 16.0474 12.156 15.9125L8 13.625L3.84398 15.9125C3.59895 16.0474 3.30201 16.025 3.07802 15.8547C2.85404 15.6844 2.74186 15.3957 2.78866 15.1101L3.58218 10.2678L0.219851 6.84023C0.0215841 6.63812 -0.0498869 6.33574 0.0354785 6.06018C0.120844 5.78463 0.348247 5.58367 0.622108 5.54178L5.26963 4.8308L7.34785 0.424263C7.47038 0.16447 7.72319 0 8 0Z"
  />
);

const ratingStyles = {
  itemShapes: CustomStar,
  activeFillColor: '#FFC107',
  inactiveFillColor: '#888FB2'
}

const RatingInput = (props) => {

  return (
    <Rating {...props} itemStyles={ratingStyles} />
  );
};

export default React.forwardRef(RatingInput);
