import React, { useCallback } from 'react';

import styles from './BalancesByTutor.module.scss';
import BalanceCard from '../BalanceCard';
import { useTranslation } from 'react-i18next';
import { useGetPackageListQuery } from '../../../store/apis/tariffs';
import { head, keys } from 'ramda';
import ContentEmpty from '../../../components/ContentEmpty';
import appRoutes from '../../../app/appRoutes';
import { useNavigate } from 'react-router-dom';
import ContentLoader from 'react-content-loader';

const BalancesByTutor = () => {
  const [t, i18n] = useTranslation();
  const navigate = useNavigate();

  const {
    data: packages,
    isLoading,
    isFetching
  } = useGetPackageListQuery();

  const goToTutorsSearch = useCallback(() => {
    navigate(appRoutes.tutors());
  }, [navigate]);

  if (isLoading || isFetching) return (
    <ContentLoader
      backgroundColor={'#c8c8c8'}
      foregroundColor={'#eceaea'}
      width="100%"
      height="340"
      style={{ padding: '0 1em', boxSizing: 'border-box' }}
      speed={2}
    >
      <rect x="0" y="0" rx="6" ry="6" width="49%" height="340" />
      <rect x="51%" y="0" rx="6" ry="6" width="49%" height="340" />
    </ContentLoader>
  )

  if (packages && packages.results.length === 0) return (
    <ContentEmpty
      imgSrc="/images/empty-sleeping.png"
      title={t('У Вас нет активных купленных пакетов')}
      subtitle={t('Здесь будет информация о Ваших купленных активных пакетах и остатке средств')}
      actionText={t('Хотите найти репетитора?')}
      onAction={goToTutorsSearch}
      btnClasses="Button_button__JBBzO_Find"
    />
  )

  const uniqTutors = packages
    ? packages.results.reduce((acc, pack, i) => {
      const tutorId = pack.tutor.id;
      if (acc[tutorId]) {
        const currentTutorPacks = acc[tutorId].packs;
        const currentTutor = acc[tutorId];

        const newTutorInfo = {
          ...currentTutor,
          packs: [...currentTutorPacks, pack],
        }

        return {
          ...acc,
          [tutorId]: newTutorInfo,
        }
      }

      const newTutorInfo = {
        tutor: pack.tutor,
        subject: head(pack.subjects),
        pricePerLesson: pack.pricePerLesson,
        packs: [pack],
      };

      return { ...acc, [tutorId]: newTutorInfo };
    }, {})
    : {};

  return (
    <div className={styles.balances}>
      {keys(uniqTutors).map((tutorId) => (
        <div className={styles.balancesItem} key={tutorId}>
          <BalanceCard
            subject={uniqTutors[tutorId].subject}
            tutor={uniqTutors[tutorId].tutor}
            packs={uniqTutors[tutorId].packs}
            allTutors={uniqTutors}
          />
        </div>
      ))}
    </div>
  );
};

export default BalancesByTutor;
