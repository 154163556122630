import React, { useCallback } from 'react';
import cn from 'classnames';

import styles from './ProfileForm.module.scss';
import Title from '../../../components/Title';
import TextInputController from '../../../components/TextInput/TextInputController';
import DateInputController from '../../../components/DateInput/DateInputController';
import TextareaInputController from '../../../components/TextareaInput/TextareaInputController';
import Button from '../../../components/Button';
import { FiSave } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { usePatchProfileMutation } from '../../../store/apis/profile';
import { toast } from 'react-toastify';
import { head } from 'ramda';
import { setErrorsToFields } from '../../../utils';
import { createDataSchema } from '../../../yup';
import { string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const schema = createDataSchema({
  firstName: string().required(),
  lastName: string().required(),
  birthdate: string().required(),
});

const ProfileForm = ({
  initialValues,
}) => {
  const [t] = useTranslation();
  const [patchProfile, { isLoading, isFetching }] = usePatchProfileMutation();

  const {
    formState: { isDirty },
    control,
    setError,
    handleSubmit: submit,
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(schema),
  });

  const handleSubmit = useCallback(async (values) => {
    try {
      await patchProfile(values).unwrap();
      toast.success(t('Данные изменены'));
    } catch (e) {
      setErrorsToFields(e, setError);
    }
  }, [patchProfile, setError, t]);

  return (
    <form onSubmit={submit(handleSubmit)}>
      <Title level={6} bold>{t('Основные данные')}</Title>

      <div className={styles.profileFormWrapper}>
        <TextInputController
          name="lastName"
          control={control}
          label={`${t('Фамилия')}*`}
          id="lastName"
          placeholder={t('Введите фамилию')}
        />
        <TextInputController
          name="firstName"
          control={control}
          label={`${t('Имя')}*`}
          id="firstName"
          placeholder={t('Введите имя')}
        />
        <DateInputController
          control={control}
          name="birthdate"
          label={`${t('Дата рождения')}*`}
          id="birthDate"
        />
        <TextInputController
          name="email"
          control={control}
          label={t('Эл почта')}
          id="email"
          placeholder="example@mail.com"
        />
        <TextareaInputController
          name="introductionText"
          control={control}
          label={t('О себе')}
          id="description"
          placeholder={t('Кратко расскажите о себе')}
        />
        <Button
          type="submit"
          icon={<FiSave />}
          iconPosition="start"
          size="medium"
          disabled={!isDirty}
          loading={isLoading || isFetching}
          className="Button_button__JBBzO_SaveData"
        >
          {t('Сохранить')}
        </Button>
      </div>
    </form>
  );
};

export default ProfileForm;
